import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/shared/base-component/base.service';
import { RequestService } from 'src/app/core/request.service';
import { Constants } from 'src/app/app.constants';
import { AlertService } from 'src/app/core/alert.service';
import { DialogService } from 'src/app/core/dialog.service';
import { UpperCasePipe } from '@angular/common';
import { CustomerOrder } from './cart.models';

@Injectable({
    providedIn: 'root'
})

export class PaymentService extends BaseService {
    
    constructor(protected requestService: RequestService) {
        super(requestService)
    }

    public generateUpcBody(orderId: string): Promise<UPCBody> {
        return new Promise((resolve, reject) => {
            this.getById(`${Constants.contextPath}/upc-payments/signature`, orderId, 
                (response: UPCBody) => {
                    resolve(response)
                }, (err)=> {
                    this.requestService.handleError(err);
                    reject(err)
                })
        })
    }

    public sendOpaPayment(phoneNumber: string, orderId: string) {
        return new Promise((resolve, reject) => {
            this.create(`${Constants.contextPath}/opa/pay`, new OPAPayment(orderId, phoneNumber),
                (res: OPAPayment) => {
                    resolve(res)
                }, (err: any) => {
                    this.requestService.handleError(err);
                    reject(err)
                })
        });
    }
}

export class UPCBody {
    PurchaseDesc
    Currency: number;
    MerchantID: number;
    PurchaseTime: string;
    TerminalID: string;
    OrderID: string;
    Signature: string;
    TotalAmount: number;
    Local: string;
    Version: string;

    // helpers  - dlt before send
    id: string;
    dateCreated: string;
    dateModified: string;
    userId: string;
    upcPaymentUrl: string;
}
export class OPAPayment {
    paymentStatus?: OPAPaymentStatus;
    opaId?: number;
    accountNumber?: string;
    message?: string;
    amount?: number;
    status?: number;
    dateTime?: Date;
    qrCode?: string;
    paymentOrderId?: number;
    refunded?: Boolean;

    // model in
    customerOrderId?: string;
    phoneNumber?: string;

    constructor(customerOrderId: string, phoneNumber: string) {
        this.customerOrderId = customerOrderId;
        this.phoneNumber = phoneNumber;
    }
}

export enum OPAPaymentStatus {
    ACCEPTED = 'ACCEPTED', DECLINED = 'DECLINED', IN_PROGRESS = 'IN_PROGRESS'
}