import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerOrder, CustomerOrderIn, PaymentMethod } from './cart.models';
import { BaseService } from 'src/app/shared/base-component/base.service';
import { RequestService } from 'src/app/core/request.service';
import { ShopService } from '../shop/shop.service';
import { PointOfSale } from '../shop/shop.models';
import { Constants, SearchOperations } from 'src/app/app.constants';
import { Municipality, MeanOfDelivery, DistributionCalendar } from 'src/app/shared/shared.models';
import { AuthService } from 'src/app/core/auth.service';
import { UtilityService } from 'src/app/core/utility.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/core/alert.service';
import * as moment from 'moment';
import { RouteParams } from 'src/app/RouteParams';

@Injectable({
    providedIn: 'root'
})
export class CartService extends BaseService {
    private customerOrder: CustomerOrder = new CustomerOrder();
    private selectedPointOfSale: PointOfSale = new PointOfSale();
    public customerOrderSubject = new BehaviorSubject(this.customerOrder);

    private meansOfDelivery: MeanOfDelivery[];
    private municipalities: Municipality[];
    private paymentMethods: PaymentMethod[];

    public lastChange: Date;

    constructor(
        protected requestService: RequestService,
        private shopService: ShopService,
        private authService: AuthService,
        private utilityService: UtilityService,
        private router: Router,
        private alertService: AlertService
    ) {
        super(requestService)
        this.setPointOfSale();
    }

    public updateCustomerOrder(customerOrder: CustomerOrder, onSuccess: Function = null, onFail: Function = null) {
        if ((customerOrder.customerOrderProducts != null || customerOrder.customerOrderProducts != undefined) && this.selectedPointOfSale.id != null && customerOrder.customerOrderProducts.length > 0)
            this.update(`${Constants.contextPath}/customer-orders/calculate`, new CustomerOrderIn({...customerOrder}, this.selectedPointOfSale, this.authService.isPublicUser), (response: CustomerOrder) => {
                if(onSuccess)
                    onSuccess(response);
                if(customerOrder.opaPhoneNumber)
                    response.opaPhoneNumber = customerOrder.opaPhoneNumber;
                this.updateCustomerOrderSubject(response);
            }, (err) => {
              this.updateCustomerOrderSubject({ ...customerOrder, usedPromotionalCode: null })
              this.requestService.handleError(err);
            });
        else {
            if(customerOrder.customerOrderProducts) {
                this.router.navigate(['/'+RouteParams.shop]);
                this.alertService.error('ERRORS.GENERAL')
            } else {
                this.updateCustomerOrderSubject(new CustomerOrder());
                onSuccess(new CustomerOrder());
            }
        }
    }


    public submitCustomerOrder(customerOrder: CustomerOrder, onSuccess: Function) {
        this.create(`${Constants.contextPath}/customer-orders/submit`, new CustomerOrderIn({...customerOrder}, this.selectedPointOfSale, this.authService.isPublicUser), (response: CustomerOrder) => {
            if(customerOrder.opaPhoneNumber)
                response.opaPhoneNumber = customerOrder.opaPhoneNumber;
            onSuccess(response);
        });
    }

    public getOptionsForMeansOfDelivery(onSuccess: Function) {
        if (this.meansOfDelivery)
            onSuccess(this.meansOfDelivery);
        else
        // let search = `&${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}`; // opening search
            this.getAll(`${Constants.contextPath}/means-of-delivery/shop`, (response: MeanOfDelivery[]) => {
                this.meansOfDelivery = response;
                onSuccess(this.meansOfDelivery);
            })
    }

    public getOptionsForPaymentMethods(onSuccess: Function) {
        if (this.paymentMethods)
            onSuccess(this.paymentMethods);
        else
            this.getAll(`${Constants.contextPath}/payment-methods/list?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}active${SearchOperations.EQUALITY}true${SearchOperations.RIGHT_PARENTHESES_READABLE}`, (response: PaymentMethod[]) => {
                this.paymentMethods = response;
                onSuccess(this.paymentMethods);
            })
    }

    public getOptionsForMunicipalities(onSuccess: Function) {
        if (this.municipalities)
            onSuccess(this.municipalities);
        else
            this.getAll(`${Constants.contextPath}/municipalities/list`, (response: Municipality[]) => {
                this.municipalities = response;
                onSuccess(this.municipalities);
            })
    }

    public getDistributionCalendarForOrder(distributionCalendarsForDelivery: DistributionCalendar, isTokenSet: boolean, onSuccess: Function) {
        moment.locale('bs');
        distributionCalendarsForDelivery.pointOfSale = this.selectedPointOfSale.id;
        // TODO! dodati companyId
        this.create(`${Constants.contextPath}/distribution-calendars/shop/available`, distributionCalendarsForDelivery, (res: DistributionCalendar[]) => {
            res.sort((a,b)=> {
                var dateA = moment(a.date);
                var dateB = moment(b.date);
                return dateA > dateB ? 1 : -1;
            });​
            onSuccess(res);
        })
    }

    // Returns data (timeSlot, usedCapacity), example: [{ timeSlot: '09:00 - 11:00', 'Ponedjeljak 21.12.2020': 1, 'Utorak 22.12.2020': 4 }, ...]
    public constructTimeSlotsPreviewData(res: DistributionCalendar[] = [], dates: string[] = [], times: string[] = []) {
        moment.locale('bs');
        let rows = [];
        dates.forEach(date => {
            times.forEach(time => {
                let timeSlotIndex = res.findIndex(e => moment(e.date).format('L') == date.split(' ')[1] &&
                    this.utilityService.formatTime(e.timeFrom.toString()) == time.split(' ')[0] &&
                    this.utilityService.formatTime(e.timeTo.toString()) == time.split(' ')[2]
                );

                let obj = {};
                obj['timeSlot'] = time;
                obj[date] = {
                    capacity: timeSlotIndex > -1 ? res[timeSlotIndex].capacity : 0,
                    id: timeSlotIndex > -1 ? res[timeSlotIndex].id : null
                }

                let index = rows.findIndex(row => row.timeSlot == time);

                if(index > -1) {
                    rows[index][date] = obj[date];
                } else {
                    rows.push(obj);
                }
            })
        });

        return rows;
    }

    public updateCustomerOrderSubject(customerOrder: CustomerOrder): void {        
        customerOrder.address = this.customerOrder.address; // ne dobijam u responsu addres objekat pa ga moram ovdje vracati da bi se spasilo lastUsed polje na adresi
        customerOrder.isPaymentSelected = this.customerOrder.isPaymentSelected;
        customerOrder.isMeansOfDeliverySelected = this.customerOrder.isMeansOfDeliverySelected;
        customerOrder.isDistributionCalendarSelected = this.customerOrder.isDistributionCalendarSelected;
        if(customerOrder.customerOrderProducts)
            customerOrder.customerOrderProducts.sort((x,y) => x.offeredProduct.product.name.length - y.offeredProduct.product.name.length);
        if(customerOrder.costs)
            customerOrder.costs.sort((x,y) => x.value - y.value);            
        this.customerOrder = customerOrder;
        this.customerOrderSubject.next(customerOrder);
        this.lastChange = new Date();
    }

    public updateCustomerOrderProducts(products) {
        this.customerOrder.customerOrderProducts = products;
        this.customerOrderSubject.next(this.customerOrder);
    }

    public setPointOfSale() {
        this.shopService.selectedPointOfSale.subscribe((data: PointOfSale) => {
            this.selectedPointOfSale = data;
            this.customerOrder.pointOfSale = data;
            this.updateCustomerOrderSubject(this.customerOrder);
        });
    }

    // saveCart(cartName: string, customerOrderProducts: CustomerOrderProduct[] ){
    //     this.create(`${Constants.contextPath}/carts`, new Cart(cartName, customerOrderProducts, this.selectedPointOfSale), (res)=>{
    //         console.log(res);
    //     })
    // }
    
    public isTotalOverPosTotal(orderValue: number): boolean {
        return this.selectedPointOfSale.minOrderGoodsValue > orderValue ? true : false;
    }

    public get minOrderValue(): number {
        return this.selectedPointOfSale.minOrderGoodsValue;
    }

    public get getLastChange() {
        return this.lastChange;
    }
}
