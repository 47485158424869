import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, SimpleChanges } from '@angular/core';
import { ShopService } from '../shop.service';
import { Constants } from 'src/app/app.constants';
import { PointOfSale, TypeForLoad } from '../shop.models';
import { SortLabelValue, ValueLabel } from '../../../shared/base-component/base.models';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductMerchandise } from 'src/app/shared/shared.models';
import { DialogService } from 'src/app/core/dialog.service';
import { BasketService } from 'src/app/shared/basket.service';
import { RouteParams } from 'src/app/RouteParams';

@Component({
    selector: 'app-shop-breadcrumb',
    templateUrl: './shop-breadcrumb.component.html',
    styleUrls: ['./shop-breadcrumb.component.scss']
})
export class ShopBreadcrumbComponent implements OnInit, OnDestroy {
    @Input('typeForLoad') typeForLoad: TypeForLoad;
    private posSubscription: Subscription;
    private BCProductMerchandiseSubscription: Subscription;

    @Input('isMobile') isMobile: boolean = false;
    public pointOfSales: PointOfSale[];
    public selectedPointOfSale: PointOfSale = new PointOfSale();
    public breadCrumbProductMerchandise: ProductMerchandise[];

    public filters: SortLabelValue[] = [
        { sortBy: 'regular_price', sortOrder: 'asc', label: 'SHOP.SORT_BY_PRICE_ASC' },
        { sortBy: 'regular_price', sortOrder: 'desc', label: 'SHOP.SORT_BY_PRICE_DESC' },
        { sortBy: 'name', sortOrder: 'asc', label: 'SHOP.PRODUCT_NAME_ASC' },
        { sortBy: 'name', sortOrder: 'desc', label: 'SHOP.PRODUCT_NAME_DESC' }
    ];

    public RouteParams = RouteParams;

    constructor(public shopService: ShopService, public router: Router, private route: ActivatedRoute, private dialogService: DialogService, private basketService: BasketService) {
        this.shopService.getPointOfSales((response: any) => {
            this.pointOfSales = response;
            this.startSubscriptions();
        })     
    }

    ngOnInit(): void {
    }

    ngOnDestroy(){
        if(this.posSubscription)
            this.posSubscription.unsubscribe();
        if(this.BCProductMerchandiseSubscription){
            this.BCProductMerchandiseSubscription.unsubscribe();
            this.breadCrumbProductMerchandise = [];
        }
    }

    private startSubscriptions(){
        this.posSubscription = this.shopService.selectedPointOfSale.subscribe((data: PointOfSale) => {
            this.selectedPointOfSale = data;
        });
        this.BCProductMerchandiseSubscription = this.shopService.breadCrumbProductMerchandise.subscribe((data: ProductMerchandise[]) => {
            this.breadCrumbProductMerchandise = data;
        })
    }

    setSort(selectedSort: SortLabelValue) {
        this.router.navigate(
          [],
          {
            relativeTo: this.route.parent,
            queryParams: { s: selectedSort.sortBy, d: selectedSort.sortOrder },  // s= sortBy d = direction
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          });
    }

    refresh(){
        this.router.navigate(
            [this.typeForLoad ? `/${RouteParams.shop}/${this.typeForLoad}` : './'],
          {
            relativeTo: this.route
          });
    }

    getRouterLink(categoryName?: string) {
        return `./${RouteParams.mainMerch}/${categoryName.replace(/ /g, '-').toLowerCase()}`;
    }

    getQueryParams(value: string) {
        let query = { ...this.route.snapshot.queryParams };
        query[RouteParams.merch] = value;
        query.p = null;
        return query
    }

    public setPOS(pointOfSale: PointOfSale){
        if(this.selectedPointOfSale.id && this.basketService.getItemsLength() > 0){
            this.dialogService.OpenModal('SHOP.DELETE_CART',
            ()=> {
                this.shopService.setPointOfSale(pointOfSale);
                this.basketService.clearItems();
            }, 
            ()=> {
                setTimeout(() => {
                    this.selectedPointOfSale = this.selectedPointOfSale;
                }, 500);  
            })
        }else{
            this.shopService.setPointOfSale(pointOfSale);
        }
    }
    
}
