<div class="overflow-hidden defaultDialog">
  <div class="content">
    <div class="pt-2 pb-3 px-2 px-md-4 dialog-message" mat-dialog-content>
      {{ 'REGISTRATIONS.ENTER_HERE_NEW_PASSWORD' | translate }}</div>
    <div mat-dialog-actions align="end">
      <span>
        <div class="col-12 px-2 px-md-4">
          <mat-form-field class="max-width" appearance="fill">
            <mat-label>{{ 'PASSWORD.NEW_PASSWORD' | translate }}</mat-label>
            <input matInput autofocus [type]="'password'" [formControl]="formData.controls['password']">
            <mat-error>{{ 'PASSWORDS.PATTERN' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 px-2 px-md-4 mt-3">
          <mat-form-field class="max-width" appearance="fill">
            <mat-label>{{ 'PASSWORD.REPEAT_PASSWORD' | translate }}</mat-label>
            <input matInput [type]="'password'" [(ngModel)]="repeatPassword" (change)="passwordCheck()">
          </mat-form-field>
        </div>
        <mat-error *ngIf="!passMatch" class="mb-5">{{ 'REGISTRATIONS.NO_MATCH_PASS' | translate }}</mat-error>
        <button type="button" mat-stroked-button class="smaller-mobile outline-none mr-3" (click)="closeDialog()">
          {{ 'MODAL.CLOSE' | translate}}
        </button>
        <button type="button" [disabled]="!passMatch" mat-stroked-button
          class="bg-main text-white smaller-mobile outline-none mb-1 mr-2 mr-md-4" (click)="confirmDialog()">
          {{ 'MODAL.CONFIRM' | translate}}
        </button>
      </span>
    </div>
  </div>
</div>
