import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { AlertService } from 'src/app/core/alert.service';
import { DialogService } from 'src/app/core/dialog.service';
import { SeoService } from 'src/app/core/seo.service';
import { RouteParams } from 'src/app/RouteParams';
import { inOutAnimation } from 'src/app/shared/animations';
import { ForgotPasswordComponent } from '../../shared/forgot-password/forgot-password.component';
import { Banner, Banners, BannerType } from './home.models';
import { HomeService } from './home.service';
// import { inOutAnimation } from "../../../../../";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [inOutAnimation]
})
export class HomeComponent implements OnInit {
    public popupInfo: Banner;
    public loading: boolean = true;
    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (document.documentElement.scrollTop < 150) {
            this.translateWithScroll = Math.round(document.documentElement.scrollTop / 1.5);
        }
    }
    public translateWithScroll: number;
    
    public RouteParams = RouteParams;

    public banners: Banners[] = [
        {
            type: BannerType.HOME_CAROUSEL,
            banners: [
                {
                    image: '../../../assets/img/initialImgSlider.jpg',
                    active: true
                }
            ]
        },
        {
            type: BannerType.HOME_FIXED,
            banners: [
                {
                    image: '../../../assets/img/initialImgSliderStatic.jpg',
                    active: true
                }
            ]
        },
        {
            type: BannerType.POPUP,
            banners: []
        }
    ];

    public productCategories = [
        {
            name: "CATEGORIES.FRUITS",
            imageUrl: "fruitesAndVegetables",
            categoryUrl: "06."
        },
        {
            name: "CATEGORIES.PASTRIES",
            imageUrl: "pastriesAndCakes",
            categoryUrl: "07."
        },
        {
            name: "CATEGORIES.DAIRY",
            imageUrl: "dairyAndEggs",
            categoryUrl: "04."
        },
        {
            name: "CATEGORIES.MEAT",
            imageUrl: "meatAndMeatProducts",
            categoryUrl: "05."
        },
        {
            name: "CATEGORIES.GROCERIES",
            imageUrl: "grocieriesForFoodMaking",
            categoryUrl: "03."
        },
        {
            name: "CATEGORIES.DRINKS",
            imageUrl: "drinks",
            categoryUrl: "01."
        },
        {
            name: "CATEGORIES.SWEETS",
            imageUrl: "sweetsAndSnacks",
            categoryUrl: "02."
        },
        {
            name: "CATEGORIES.BABY_PROGRAM",
            imageUrl: "babyProgram",
            categoryUrl: "08."
        },
        {
            name: "CATEGORIES.BODY_AND_FACE_CARE",
            imageUrl: "bodyAndFaceCare",
            categoryUrl: "09."
        },
        {
            name: "CATEGORIES.CLEANING_AGENTS",
            imageUrl: "cleaningAgents",
            categoryUrl: "10."
        },
        {
            name: "CATEGORIES.HIGENE",
            imageUrl: "higeneAndPaperProducts",
            categoryUrl: "11."
        },
        {
            name: "CATEGORIES.HOUSE_NECESSITIES",
            imageUrl: "houseNecessities",
            categoryUrl: "12."
        }
    ];

    constructor(
        private homeService: HomeService,
        private alertService: AlertService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private seo: SeoService,
        private $gaService: GoogleAnalyticsService,
        private matDialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.getAllBanners();
        this.checkResetPwToken();
        this.seo.setTitle('Amko Web shop home');
    }

    private getAllBanners() {
        this.homeService.getAllBanners((res: Banners[]) => {
            this.banners = res;
            if (res[2]) {
                setTimeout(() => {
                    this.popupInfo = null;
                    res[2].banners.forEach(popup => {
                        if (!this.homeService.checkIfPopupIsViewed(popup.id)) {
                            if (popup.title == 'm' && window.innerWidth < 767)
                                this.popupInfo = popup;
                            else if (window.innerWidth > 767 && popup.title != 'm')
                                this.popupInfo = popup;
                        }
                    });
                }, 3000);
            }
            this.loading = false;
        });
    }

    private checkResetPwToken() {
        if (this.activatedRoute.snapshot.params.token) {
            let matDialogRef: MatDialogRef<ForgotPasswordComponent> = this.matDialog.open(ForgotPasswordComponent, {});
    
            matDialogRef.afterClosed().subscribe(res => {
                this.homeService.resetPw(this.activatedRoute.snapshot.params.token, res);
            });
        }
    }

    closeModalAndCheckForUnviewedPopups() {
        this.popupInfo = null;
        setTimeout(() => {
            this.getAllBanners();
        }, 500);
    }

    subscribeToNewsletter() {
        this.$gaService.event('newsLetterToAmko', 'openedModal');
        this.dialogService.openModalWithInput('SHARED.ENTER-HERE-EMAIL', 'SHARED.EMAIL', (res) => {
            this.homeService.subscribeToNewsletter(res);
        })
    }
}
