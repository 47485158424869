<app-shop-breadcrumb *ngIf="isMobile" [isMobile]="isMobile"></app-shop-breadcrumb>
<div *ngIf="shopService.selectedPOS == null && !loadingService.unfinishedRequests" class="col-12 text-center text-white-hoverable d-block d-lg-none fs-13 lead text-white">
    <p>{{ 'SHOP.CHOOSE_POS' | translate }}</p>
</div>
<div [ngClass]="[filterForSummary.showSummary && !router.url.includes('/'+RouteParams.product) ? 'height-auto' : 'height-min overflow-hidden']" class="shortAnim">
    <div [ngClass]="filterForSummary.showSummary && !router.url.includes('/'+RouteParams.product) ? 'animate__fadeInDown' : 'animate__fadeOutUp'" class="animate__animated animate__faster filter-summary shortAnim overflow-hidden">
        <p class="title shortAnim">
            {{ 'SHOP.SUMMARY_HEADING' | translate }}
        </p>
        <ul class="list-unstyled">
            <li *ngIf="filterForSummary.brandName">
                <i title="{{ 'SHOP.CANCEL_SEARCH' | translate }} {{ 'SHOP.BRAND' | translate }}: {{ filterForSummary.brandName }}" (click)="cancelFilterFor('product_Brands')" class="ti-close clickable"></i>
                <span class="classification-title">
                    {{ 'SHOP.BRAND' | translate }}: 
                </span>
                <span class="classification-value">
                    {{filterForSummary.brandName}}
                </span>
            </li>
            <li *ngIf="filterForSummary.merchName">
                <i [title]='"Poništi pretragu za robnu grupu "+filterForSummary.merchName' (click)="cancelFilterFor('product_Merchandise_groups')" class="ti-close clickable"></i>
                <span class="classification-title">
                    {{ 'SHOP.MERCH' | translate }}: 
                </span>
                <span class="classification-value">
                    {{ filterForSummary.merchName }}
                </span>
            </li>
            <li *ngIf="filterForSummary.search">
                <i [title]='"Poništi pretragu za pretragu "+filterForSummary.search' (click)="cancelFilterFor('search')" class="ti-close clickable"></i>
                <span class="classification-title">
                    {{ 'SHOP.SEARCH' | translate }}:  
                </span>
                <span class="classification-value">
                    {{ filterForSummary.search }}
                </span>
            </li>
            <li *ngIf="filterForSummary.badges?.length > 0">
                <i [title]="'Poništi pretragu za oznake: '+filterForSummary.badges.join(' ,').replace('-', ' ')" (click)="cancelFilterFor('badge')" class="ti-close clickable"></i>
                <span class="classification-title">
                    {{ 'SHOP.BADGES' | translate }}:  
                </span>
                <span class="classification-value">
                    {{ filterForSummary.badges.join(' ,').replace('-', ' ') }}
                </span>
            </li>
        </ul>
    </div>
</div>
<ul *ngFor="let mainItem of productClassification; let index = index;" class="list-unstyled multi-level item noselect animate__fadeIn animate__animated animate__faster">
    <li>
        <input type="checkbox" [checked]="index < 2" id="{{index}}-{{isMobile}}">
        <label class="clickable category-link main text-white text-white-hoverable shortAnim text-bolder m-0" for="{{index}}-{{isMobile}}">
            {{ mainItem.heading | translate }}
        </label>
        <ul class="list-unstyled multi-level ml-4 item noselect animate__fadeIn animate__animated animate__faster">
            <li *ngFor="let item of mainItem.classification; let i = index;" class="my-2">
                <a [routerLink]="getRouterLink(mainItem.queryParam, item.name)" [queryParams]="getQueryParams(mainItem.queryParam, item.code)" queryParamsHandling="merge" [ngClass]="{'active': selectedMerchCode == item.code || selectedBrandCode == item.code }" class="first text-bold text-white text-white-hoverable clickable category-link shortAnim">
                    {{item.name}}
                </a>
                <input *ngIf="item.children?.length > 0"  type="checkbox" (click)="leaveOnlyThisItemOpen(item.code)" [checked]="openItemCodeForView == item.code" id="{{item.code}}-{{isMobile}}">
                <label *ngIf="item.children?.length > 0" class="clickable m-0" for="{{item.code}}-{{isMobile}}"><img src="../../../../assets/icons/angle-down.svg" alt="otvoriti kategoriju ikonica" class="ml-2 firstImg shortAnim height-15p"></label>
                <ul *ngIf="item.children?.length > 0 && mainItem.queryParam != 'brand'" class="list-unstyled pb-4 ml-4 mt-2 text-white text-white-hoverable animate__fadeIn animate__animated animate__faster">
                    <span *ngFor="let subItem of item.children; let i = index">
                        <li class="py-1">
                            <a [routerLink]="getRouterLink(mainItem.queryParam, subItem.name)" [queryParams]="getQueryParams(mainItem.queryParam, subItem.code)" queryParamsHandling="merge" [ngClass]="{'active': selectedMerchCode == subItem.code || selectedBrandCode == subItem.code }" class="second text-bold text-white text-white-hoverable clickable category-link shortAnim">
                                {{subItem.name}}
                            </a>
                                <input *ngIf="subItem.children?.length > 0"  [checked] = "parentCodes[1] == subItem.code" type="checkbox" id="{{subItem.code}}-{{isMobile}}">
                                <label *ngIf="subItem.children?.length > 0" class="clickable m-0" for="{{subItem.code}}-{{isMobile}}"><img src="../../../../assets/icons/angle-down.svg" alt="otvoriti kategoriju ikonica" class="secondImg ml-2 shortAnim height-15p"></label>
                            <ul *ngIf="subItem.children?.length > 0" class="list-unstyled ml-4 mt-2 text-white text-white-hoverable animate__fadeIn animate__animated animate__faster">
                                <span *ngFor="let child of subItem.children; let i = index">
                                    <li class="py-0">
                                        <a [routerLink]="getRouterLink(mainItem.queryParam, child.name)" [queryParams]="getQueryParams(mainItem.queryParam, child.code)" queryParamsHandling="merge" [ngClass]="{'active': selectedMerchCode == child.code}" class="third text-bold text-white text-white-hoverable clickable category-link shortAnim">
                                            {{child.name}}
                                        </a>
                                            <input *ngIf="child.children?.length > 0" type="checkbox"  id="{{child.code}}-{{isMobile}}">
                                            <label *ngIf="child.children?.length > 0" class="clickable m-0" for="{{child.code}}-{{isMobile}}"><img src="../../../../assets/icons/angle-down.svg" alt="otvoriti kategoriju ikonica" class="thirdImg ml-2 shortAnim height-15p"></label>
                                            <ul *ngIf="child.children?.length > 0" class="list-unstyled ml-4 mt-2 text-white text-white-hoverable animate__fadeIn animate__animated animate__faster">
                                                <span *ngFor="let childChild of child.children; let i = index">
                                                    <li class="py-0">
                                                        <a [routerLink]="getRouterLink(mainItem.queryParam, childChild.name)" [queryParams]="getQueryParams(mainItem.queryParam, childChild.code)" queryParamsHandling="merge" [ngClass]="{'active': selectedMerchCode == childChild.code}" class="fourth text-bold text-white text-white-hoverable clickable category-link shortAnim">
                                                            {{childChild.name}}
                                                        </a>
                                                            <input type="checkbox" id="{{childChild.code}}-{{isMobile}}">
                                                            <label class="clickable m-0" for="{{childChild.code}}-{{isMobile}}"><img src="../../../../assets/icons/angle-down.svg" alt="otvoriti kategoriju ikonica" class="fourthImg ml-2 shortAnim height-15p"></label>
                                                    </li>
                                                </span>
                                            </ul>
                                    </li>
                                </span>
                            </ul>
                        </li>
                    </span>
                </ul>
            </li>
        </ul>
    </li>
</ul>
<ul *ngIf="badges?.length > 0 && productClassification?.length > 0" class="list-unstyled multi-level item noselect animate__fadeIn animate__animated animate__faster">
    <li>
        <input type="checkbox" [checked]="true" id="oznake-{{isMobile}}">
        <label class="clickable category-link main text-white text-white-hoverable shortAnim text-bolder m-0" for="oznake-{{isMobile}}">
            {{ 'SHOP.BADGES' | translate }}
        </label>
        <ul class="list-unstyled multi-level ml-4 item noselect animate__fadeIn animate__animated animate__faster">
            <li *ngFor="let badge of badges; let i = index;" class="my-2">
                <a [routerLink]="getBadgeRouterLink()" [queryParams]="generateQueryParamsBadge(badge)" [ngClass]="{'active': selectedBadgesIds?.includes(badge.id) }" class="first text-bold text-white text-white-hoverable clickable category-link shortAnim">
                    <img src="{{ bucketBaseUrl }}{{ badge.image }}" class="height-30p mr-2" alt="Amko shop - bedž {{ badge.image }}">
                    {{badge.name}}
                </a>
            </li>
        </ul>
    </li>
</ul>
