import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { Constants } from 'src/app/app.constants';
import { AlertService } from 'src/app/core/alert.service';
import { AuthService } from 'src/app/core/auth.service';
import { RequestService } from 'src/app/core/request.service';
import { BaseService } from 'src/app/shared/base-component/base.service';
import { Banner, Banners, BannerType } from './home.models';

@Injectable({
    providedIn: 'root'
})
export class HomeService extends BaseService {
  private banners: Banners[] = [
    {
      type: BannerType.HOME_CAROUSEL,
      banners: []
    },
    {
      type: BannerType.HOME_FIXED,
      banners: []
    },
    {
      type: BannerType.POPUP,
      banners: []
    }
  ];

  private viewedPopups: string = 'amko_popups';

  constructor(protected requestService: RequestService, private http: HttpClient, private formBuilder: FormBuilder, private alertService: AlertService, private authService: AuthService, private $gaService: GoogleAnalyticsService) {
    super(requestService)
  }

  public getAllBanners(onSuccess: Function) {
    if (this.banners[0].banners.length > 0 || this.banners[1].banners.length > 0 || this.banners[2].banners.length > 0)
      onSuccess(this.banners);
    else
      this.getAll(`${Constants.contextPath}/banners/shop`, (res: Banner[]) => {
        res.map(banner => {
          banner.image = Constants.amazonS3Bucket + banner.image;
          if (banner.type == BannerType.HOME_FIXED)
            this.banners[1].banners.unshift(banner);
          else if (banner.type == BannerType.HOME_CAROUSEL)
            this.banners[0].banners.unshift(banner);
          else
            this.banners[2].banners.unshift(banner);
        })
        onSuccess(this.banners);
      }, err=> this.requestService.handleError(err))
  }

  public checkIfPopupIsViewed(id: string): boolean {
    let localViewedPopups = JSON.parse(localStorage.getItem(this.viewedPopups));
    if (localViewedPopups) {
      let i = localViewedPopups.findIndex(localId => localId.id === id);
      return i != -1 ? this.checkIsPopupClosedMoreThan12HoursAgo(localViewedPopups[i].timeClosed) : false;
    } else
      return false
  }

  checkIsPopupClosedMoreThan12HoursAgo(time: Date): boolean {
    const twelveHoursAgo = Date.now() - 12000 * 60 * 60;
    return new Date(twelveHoursAgo) < new Date(time);
  }

  public addPopupToViewedInStorage(id: string) {
    let localViewedPopups = JSON.parse(localStorage.getItem(this.viewedPopups));
    if (localViewedPopups) {
      let i = localViewedPopups.findIndex(localId => localId.id === id);
      if (i != -1)
        localViewedPopups[i].timeClosed = new Date();
      else
        localViewedPopups.push({id: id, timeClosed: new Date()});
    } else
      localViewedPopups = [{id: id, timeClosed: new Date()}];
    localStorage.setItem(this.viewedPopups, JSON.stringify(localViewedPopups));
  }

  public resetPw(token: string, newPw: string) {
    this.patch(`${Constants.contextPath}/users/forgot-password/${token}/confirm`, {"newPassword": newPw}, (res) => {
      this.alertService.success('PASSWORD.SUCCESS_RESET_PASSWORD_LOGOUT');
      setTimeout(() => {
        this.authService.toogleLogin(true);
      }, 1500);
    });
  }

  public subscribeToNewsletter(email) {
    this.create(`${Constants.contextPath}/newsletters/subscribe/${email}`, null, (res)=> {
      this.alertService.success('DASHBOARD.NEWSLETTER_SUCCESS', 10000);
      this.$gaService.event('newsLetterToAmko', 'succesfully_sended_newsletter');
    }, ()=> {
      this.alertService.error('ERRORS.GENERAL', false, 5000);
      this.$gaService.event('newsLetterToAmko', 'error_sending_newsletter');
    });
  }}
