import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BasketService } from 'src/app/shared/basket.service';
import { CartProduct } from 'src/app/routes/cart/cart.models';
import { Constants } from 'src/app/app.constants';
import { Product } from 'src/app/routes/shop/shop.models';
import { RouteParams } from 'src/app/RouteParams';

@Component({
    selector: 'app-header-cart-items',
    templateUrl: './header-cart-items.component.html',
    styleUrls: ['./header-cart-items.component.scss']
})
export class HeaderCartItemsComponent implements OnInit {
    @Input('cartProducts') cartProducts: CartProduct[];
    @Input('orderTotal') orderTotal: number;
    @Output() closeHeaderCartItemsEvent = new EventEmitter();

    public bucketBaseUrl = Constants.amazonS3Bucket;
    public RouteParams = RouteParams;

    constructor(public basketService: BasketService) { }

    ngOnInit(): void {
        this.cartProducts.forEach(e => {
            if((<Product>e.product).images != null)
                (<Product>e.product).images = (<Product>e.product).images.filter(x=>x.isMain);
        });
     }

    updateQuantity(cardProduct: CartProduct, quantity: number){
        this.basketService.updateProductWithId(cardProduct.id, cardProduct.quantity + quantity);
    }
}
