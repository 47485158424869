import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { SeoService } from 'src/app/core/seo.service';
import { UtilityService } from 'src/app/core/utility.service';
import { BasketService } from 'src/app/shared/basket.service';
import { News } from '../news.models';
import { NewsService } from '../news.service';

@Component({
    selector: 'app-news-single',
    templateUrl: './news-single.component.html',
    styleUrls: ['./news-single.component.scss']
})
export class NewsSingleComponent implements OnInit, OnDestroy {
    @ViewChild('newsElement') newsElementRef : ElementRef;
    @Input('newsType') newsType: string;
    public bucketBaseUrl = Constants.amazonS3Bucket;

    private routeParamsSubscription: Subscription
    public news: News = new News();
    
    constructor(private route: ActivatedRoute, private newsService: NewsService, public utilityService: UtilityService, private seo: SeoService, private router: Router, private basketService: BasketService) { }

    ngOnInit(): void {
        if(!this.newsType)
            this.routeParamsSubscription = this.route.params.subscribe(routeParams => {
                if(routeParams.type){
                    this.getNews(routeParams.type);
                    this.seo.setTitle('Amko ' + routeParams.type);
                    if(routeParams.type == 'thank-you')
                        this.basketService.clearItems();
                }
            })
    }

    ngOnDestroy(){
        if(this.routeParamsSubscription)
            this.routeParamsSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.newsType.currentValue != undefined){
            this.getNews(changes.newsType.currentValue)
        }
    }

    private getNews(type: string) {
        if(this.news?.link == type) return
        this.newsService.getNewsByType(type, ((response: News) => {
            this.news = response;
            this.seo.setTitleAndMeta(`Amko novosti - ${response.title}`, `Amko shop info, Amko shop novosti, web shop, online kupovina, ${response.link}, ${response.newsParagraphs[0]?.paragraph}, ${response.title}`, response.title, response.newsParagraphs[0]?.paragraph, this.bucketBaseUrl + response.newsParagraphs[0]?.imagePath)
            if(!this.newsType)
                setTimeout(() => {
                    window.scrollTo(0, 0);
                }, 100);
        }), () => {
            this.router.navigate(['/error']) 
        })
    }
}
