<div class="product-wrapper outline-none noselect">
    <a class="outline-none" [routerLink]="[routePath, offeredProduct.product.name.replaceAll(' ', '-'), offeredProduct.id]" (click)="goToProduct()">
        <div class="product-img-wrap">
            <img *ngIf="offeredProduct?.product?.images?.length > 0; else noImgTemplate"
                [defaultImage]="defaultImg" 
                lazyLoad="{{ bucketBaseUrl }}{{ displayImage.path }}"
                [alt]="offeredProduct.product.name" 
                class="product-card-img"
                (mouseover)="displayAnotherImages()" 
                (mouseout)="disaplayMainImage()">
            <ng-template #noImgTemplate>
                <img [defaultImage]="defaultImg" lazyLoad="../../../../assets/img/no_photo.PNG" class="product-card-img" alt="">
            </ng-template>
        </div>
        <img *ngIf="offeredProduct && offeredProduct.saleDetails && offeredProduct.saleDetails.length > 0 && offeredProduct.saleDetails[0].discountPercentage > 0" class="badge-discount" src="../../../../assets/img/akcija.png">
        <div [ngClass]="{'with-discount-badge': offeredProduct.saleDetails[0].discountPercentage > 0}" *ngIf="offeredProduct.badges" class="product-card-icons-wrap">
            <div *ngFor="let badge of offeredProduct.badges" matTooltip="{{badge.name}}" matTooltipPosition="left" class="icon-circle mb-3">
                <img src="{{ bucketBaseUrl }}{{ badge.image }}">
            </div>
        </div>
        <span class="product-card-name two-text-line">{{offeredProduct.product.name}}</span>
        <div class="description-position">
            <div class="green-gray-line"></div>
            <span *ngIf="offeredProduct.product.description != offeredProduct.product.name && offeredProduct.product.description.length > 1" [title]='offeredProduct.product.description' class="product-card-description m-auto">
                {{offeredProduct.product.description}}
            </span>
            <hr class="my-1 d-none d-sm-block">
        </div>
    </a>
    <div class="product-card-info">
        <!-- TODO valuta -->
        <div *ngIf="offeredProduct && offeredProduct.saleDetails && offeredProduct.saleDetails.length > 0 && offeredProduct.saleDetails[0].discountPercentage > 0; else regularPriceTemplate">
            <span class="text-gray product-card-oldPrice">{{offeredProduct.regularPrice | number:'1.2-2'}}KM</span>
            <span *ngIf="offeredProduct.saleDetailsTooltip" [matTooltip]="offeredProduct.saleDetailsTooltip" matTooltipPosition="above" matTooltipClass="break-line-tooltip" class="product-card-discount-from d-none d-sm-block">
                <i class="fas fa-info"></i>
            </span>
            <span class="product-card-price product-card-price-sm">
                {{offeredProduct.saleDetails[0].salePrice | number:'1.2-2'}}KM
            </span>
        </div>
        <ng-template #regularPriceTemplate>
            <span class="product-card-price">{{offeredProduct.regularPrice | number:'1.2-2'}}KM</span>
        </ng-template>
        <div class="product-card-quantity text-uppercase">
            <div class="d-inline">
                <input class="quantity shortAnim" [step]="offeredProduct?.product?.unitOfMeasurement?.increment" type="number" (ngModelChange)="onKey()" (keyup)="onKey()" [min]="offeredProduct?.product?.unitOfMeasurement?.increment" [(ngModel)]="quantity">
            </div>
            <span [title]="offeredProduct.product.unitOfMeasurement.name" class="pc d-inline">
                {{ offeredProduct.product.unitOfMeasurement.code }}
            </span>
        </div>
    </div>
    <span *ngIf="offeredProduct && offeredProduct.saleDetails && offeredProduct.saleDetails.length > 0 && offeredProduct.saleDetails[0].discountPercentage > 0 && offeredProduct.saleDetails[0].validTo" class="valid-to-info">
        {{ 'SHOP.PRICE-VALID-TO' | translate: { date: utilityService.formatDate(offeredProduct.saleDetails[0].validTo) } }}
    </span>
    <button [disabled]="quantity == null || quantity < offeredProduct.product.unitOfMeasurement.increment" mat-raised-button class="product-card-button border-radius-0 animate__fadeIn animate__animated" (click)="addCart()">
        <span class="cart-span">{{ 'CART.ADD_SHORT' | translate }}</span>
        <span class="plus animate__fadeIn animate__animated animate__faster cart-img ml-1">+</span>
        <img class="animate__fadeIn animate__animated animate__faster cart-img" src="assets/icons/cart-card.svg">
    </button>
</div>