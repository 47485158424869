export class BaseModel {
    id?: string;
    dateCreated?: Date;
    dateModified?: Date;
    version?: number = 0;
    userCreated?: string;
    userModified?: string;
    breadcrumbsId?: string;
    userId?: string;
    companyId?: string;
}

export class BaseModelActive extends BaseModel {
    active?: boolean = true;
}

export enum DayOfWeek {
    MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY 
}

export class ValueLabel {
    value: any;
    label: any;
}
export class SortLabelValue {
    sortBy: string;
    sortOrder: string;
    label: string;
}

export class Image extends BaseModel {
    isMain: boolean;
    path: string;
}