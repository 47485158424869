<div class="row mt-2 text-white">
    <div class="col-12 my-2 my-lg-0 pr-lg-2">
        <div *ngIf="!isMobile" class="pull-left d-inline">
            <i (click)="refresh()" class="fas fa-home mr-3 clickable"></i>
            <span *ngFor="let breadCrumb of breadCrumbProductMerchandise; let i = index">
                <i *ngIf="i > 0" class="fas fa-chevron-right fa-xs px-2 clickable breadCrumbIcon"></i>
                <a [routerLink]="getRouterLink(breadCrumb.name)" [queryParams]="getQueryParams(breadCrumb.code)" class="breadCrumbName noselect clickable">{{ breadCrumb?.name }}</a>
            </span>
        </div>
        <div [ngClass]="isMobile ? '' : 'd-none d-lg-block'" class="pull-right fs-14 mt-minus15">
            <ng-container *ngIf="pointOfSales?.length > 1">
                <mat-form-field [ngClass]="{'width-90': isMobile }" *ngIf="shopService.showChoosePointOfSale" appearance="outline" color="warn" class="fs-12 text-bolder no-margins">
                    <mat-label >{{ 'SHOP.POINT_OF_SALE' | translate }}</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let pointOfSale of pointOfSales" [value]="pointOfSale.id" (click)="setPOS(pointOfSale)">
                            {{ pointOfSale.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <mat-form-field [ngClass]="[isMobile ? 'width-90' : 'width130', router.url.includes('/'+RouteParams.product+'/') ? 'opacity0' : '']" appearance="outline" color="warn" class="fs-12 text-bolder no-margins shortAnim">
                <mat-label>{{ 'SHOP.SORT_BY' | translate }}</mat-label>
                <mat-select>
                    <mat-option *ngFor="let filter of filters" [value]="filter.label" (click)="setSort(filter)">
                        {{filter.label | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>