import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

import { BaseService } from 'src/app/shared/base-component/base.service';
import { Constants, SearchOperations } from 'src/app/app.constants';
import { Filter, OfferedProduct, PointOfSale, TypeForLoad, FilterForSummary, ShopParams, SearchMap, CompanyConfiguration, Badge } from './shop.models';
import { HttpRequestType, RequestService } from 'src/app/core/request.service';
import { ProductBrand, ProductCategory, ProductAssortment, ProductMerchandise, ProductClassification, ClassificationType } from 'src/app/shared/shared.models';
import { UtilityService } from 'src/app/core/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})

export class ShopService extends BaseService{

    private product_Categories: ProductCategory[]; //  "_" ovo zbog identicnog stringa u pozivu za url
    private product_Brands: ProductBrand[]; //  "_" ovo zbog identicnog stringa u pozivu za url
    private product_Assortments: ProductAssortment[]; //  "_" ovo zbog identicnog stringa u pozivu za url
    private product_Merchandise_Groups: ProductClassification[]; //  "_" ovo zbog identicnog stringa u pozivu za url
    // private productCategories: ProductCategory[];
    // private productBrands: ProductBrand[];
    // private productAssortments: ProductAssortment[];
    // private productMerchandise: ProductClassification[];

    private offeredProducts: OfferedProduct[];
    public pointOfSales: PointOfSale[] = [];
    public pointOfSaleLocal: string = 'selected_point_of_sale';

    public selectedPointOfSale = new BehaviorSubject(new PointOfSale());
     // prikaz za breadcrumbs komponentu
    public breadCrumbProductMerchandise = new BehaviorSubject(new Array<ProductMerchandise>());
    // generisanje array-a za dobivanje kodova iz array-a, treba mi Subject, jer tek na njegovu promjenu se poziva getAll u shop-gridu
    public productMerchandiseForBreadCrumb = new BehaviorSubject(new Array<ProductMerchandise>());
    public productBrandsForBreadCrumb = new BehaviorSubject(new Array<ProductMerchandise>());

    public filterNamesForSummary = new Subject();

    public selectedPOS: PointOfSale;
    public showChoosePointOfSale: Boolean = true;
    public filter : Filter = new Filter(null, this.pageSize);

    constructor(protected requestService: RequestService, protected activatedRoute: ActivatedRoute, private utilityService: UtilityService, private translateService: TranslateService){
        super(requestService);

        if (this.isPointOfSaleInStorage) {
            this.setPointOfSale(JSON.parse(localStorage.getItem(this.pointOfSaleLocal)));
        }
    }
    getClassification(classificationType: ClassificationType, onSuccess: Function){
        if(this[`${classificationType}`])
            onSuccess(this[`${classificationType}`]);
        else
            this.getAll(`${ Constants.contextPath }/${classificationType.replace(/_/g, '-').toLowerCase()}/list`, (data: ProductCategory[])=> {
                this[`${classificationType}`] = this.prepareClassificationData(data);
                onSuccess(this[`${classificationType}`]);
                if(classificationType == ClassificationType.product_Merchandise)
                    this.productMerchandiseForBreadCrumb.next(data);
                // if(classificationType == ClassificationType.product_Brands)
                //     this.productBrandsForBreadCrumb.next(data);
            })
    }

    getPointOfSales(onSuccess: Function){
        if(this.pointOfSales.length > 0)
            onSuccess(this.pointOfSales);
        else {
            this.getAll(`${Constants.contextPath}/point-of-sales/shop`, (response: PointOfSale[]) => {
                this.pointOfSales = response;
                if(this.pointOfSales.length == 1) {
                    this.setPointOfSale(this.pointOfSales[0]);
                    this.showChoosePointOfSale = false;
                }
                onSuccess(this.pointOfSales);
            })
        }
    }

    getProducts(filter: Filter, _typeForLoad: TypeForLoad, onSuccess: Function){
        this.filter = filter;
        this.requestService.createRequest(HttpRequestType.Post, `${ Constants.contextPath }/offered-products/shop`, this.generateBody(_typeForLoad), null, (response: any) => {
            this.offeredProducts = this.modifyOfferedProducts(response['content']);
            this.generateBreadcrumbPaths();
            this.generateFilterForSummary({...this.filter});
            onSuccess(response);
        });
    }

    private modifyOfferedProducts(offeredProducts: OfferedProduct[]): OfferedProduct[]{
        for(let p of offeredProducts){
            if(p.saleDetails.length > 0){
                // sort for displaying lowest price
                p.saleDetails.sort((x,y) => y.salePrice - x.salePrice);
                // generate tooltip for product
                for (let i = 0; i < p.saleDetails.length; i++){
                    if(p.saleDetails[i].amountFrom > 1)
                        p.saleDetailsTooltip = `${i > 0 && p.saleDetailsTooltip ? p.saleDetailsTooltip+',\n': ' '}${this.translateService.instant('DISCOUNT.INFO', {quantity: p.saleDetails[i].amountFrom, price: p.saleDetails[i].salePrice.toFixed(2), currency: 'KM'})}`;
                    // TODO izbrisati kad naprave na backu
                    // p.saleDetails[i].salePrice = p.regularPrice - (p.regularPrice * p.saleDetails[i].discountPercentage/100);
                }
            }
        }
        return offeredProducts
    }

    private generateBody(_typeForLoad: TypeForLoad): ShopParams {
        let f = {...this.filter};
        let params: ShopParams = {
            pointOfSale: this.selectedPOS.id,
            saleType: _typeForLoad == 'discount' ? 'DISCOUNT' : 'ALL',
            search: f.search,
            sortType: f.sortOrder != null && f.sortOrder.toLowerCase() == 'desc' ? 'DESC' : 'ASC',
            sortParam: f.sortBy ? f.sortBy.toString() : null,
            page: f.page,
            pageSize: f.pageSize,
            searchMap: new SearchMap(),
            badges: f.selectedBadgeIds
        }
        if(f.selectedMerchCode)
            params.searchMap = {productMerchandiseGroupCode: f.selectedMerchCode }

        return this.removeFields(params);
    }

    private generateFilterForSummary(f: Filter){
        let merch = this.findParents(f.selectedMerchCode, this.productMerchandiseForBreadCrumb);
        let brand = this.findParents(f.selectedBrandCode, this.productBrandsForBreadCrumb);
        let showSummary: boolean = merch || brand || f.search || f.selectedBadgeNames?.length > 0 ? true : false;
        this.filterNamesForSummary.next(
            new FilterForSummary( showSummary, merch ? merch.name : null, brand ? brand.name : null, f.search, f.selectedBadgeNames)
        );
    }

    private generateBreadcrumbPaths(onSuccess: Function = null){
        if (this.filter.selectedMerchCode == null) {
            this.breadCrumbProductMerchandise.next(new Array<ProductMerchandise>());
            if(onSuccess)
                onSuccess();
            return
        }else{
            for (let merch of this.productMerchandiseForBreadCrumb.getValue()) {
                if (merch.code == this.filter.selectedMerchCode) {
                    this.setBreadCrumbForProductMerchandise(merch);
                    this.filter.selectedMerchCodes = [];
                    this.getAllChildren(merch);
                    if(onSuccess)
                        onSuccess();
                    break;
                }
                else {
                    var parentMerch = merch;
                    while (parentMerch != null) {
                        if (parentMerch.code == this.filter.selectedMerchCode) {
                            this.setBreadCrumbForProductMerchandise(parentMerch);
                            this.filter.selectedMerchCodes = [];
                            this.getAllChildren(parentMerch);
                            break;
                        }
                        parentMerch = parentMerch.parent;
                    }
                }
            }
        }
    }

    getProduct(id: any, _typeForLoad: TypeForLoad, onSuccess: Function){
        let typeForLoad = _typeForLoad ? `${_typeForLoad}/` : '';

        if(this.offeredProducts && !_typeForLoad) {
            let index = this.offeredProducts.findIndex(el => el.id == id);
            if (index != -1) { // if exist in service
                onSuccess(this.offeredProducts[index]);
                return
            }
        }

        let params: ShopParams = {
            saleType: _typeForLoad == 'discount' ? 'DISCOUNT' : 'ALL',
            offeredProduct: id,
            pointOfSale: this.selectedPOS.id
        }
        this.requestService.createRequest(HttpRequestType.Post, `${Constants.contextPath}/offered-products/shop/by-id`, params, null, (product: OfferedProduct) => {
            product.saleDetails.sort((y,x) => x.salePrice - y.salePrice);
            // TODO valuta
            for (let i = 0; i < product.saleDetails.length; i++){
                if(product.saleDetails[i].amountFrom > 1)
                    product.saleDetailsTooltip = `${i > 0 && product.saleDetailsTooltip ? product.saleDetailsTooltip+',\n': ' '}${this.translateService.instant('DISCOUNT.INFO', {quantity: product.saleDetails[i].amountFrom, price: product.saleDetails[i].salePrice.toFixed(2), currency: 'KM'})}`;
            }
            onSuccess(product)
        })
    }

    setPointOfSale(pointOfSale: PointOfSale) {
        if(pointOfSale){
            localStorage.setItem(this.pointOfSaleLocal, JSON.stringify(pointOfSale));
            this.selectedPOS = pointOfSale;
            this.selectedPointOfSale.next(pointOfSale);
        }
    }

    private arrayForRecur: Array<ProductMerchandise> = [];
    setBreadCrumbForProductMerchandise(productClassification: ProductMerchandise) {
        this.arrayForRecur = [];
        this.recur(productClassification);
        this.breadCrumbProductMerchandise.next(this.arrayForRecur);
    }

    private recur(data: ProductMerchandise){
        if(data != null){
            this.arrayForRecur.unshift(data);
            this.recur(data.parent);
        }
    }

    prepareClassificationData(data: ProductClassification[]): ProductClassification[] {
        for (let p of data)
            p.parentId = p.parent ? p.parent.id : '-1';
        return this.generateChildren(data.sort((x, y) => x.priority - y.priority));
    }

    generateChildren(classifications: ProductClassification[] = []) {
        let newClassifications: ProductClassification[] = [];
        for(let classification of classifications)
            if(classification.parentId == '-1')
                newClassifications.push(this.findChildren(classification, classifications));
        return newClassifications
    }

    findChildren(classification: ProductClassification, classifications: ProductClassification[]) {
        if(classification.children == null)
            classification.children = [];
        for(let item of classifications) {
            if(item.parentId == classification.id) {
                if(classification.children.findIndex(c => c.id == item.id) == -1)
                    classification.children.push(this.findChildren(item, classifications));
            }
        }
        return classification;
    }

    public get isPointOfSaleSet(): boolean {
        return this.selectedPointOfSale != null;
    }

    public get isPointOfSaleInStorage(): boolean {
        return !!localStorage.getItem(this.pointOfSaleLocal);
    }

    findParents(merchCode: string, source = this.productMerchandiseForBreadCrumb){
        if(source != undefined){
            for(let merch of source.getValue()){
                if(merch.code == merchCode)
                    return merch;
                else {
                    var parentMerch = merch;
                    while(parentMerch != null){
                        if(parentMerch.code == merchCode)
                           return parentMerch;
                        parentMerch = parentMerch.parent;
                    }
                }
            }
        }
    }

    getAllChildren(merch: ProductMerchandise) {
        if (this.filter.selectedMerchCodes.length == 0)
            this.filter.selectedMerchCodes.push(merch.code);
        if (merch.children)
            for (let i of merch.children) {
                this.filter.selectedMerchCodes.push(i.code);
                if (i.children) {
                    this.getAllChildren(i);
                }
            }
    }

    public getByCompanyConfigurationKey(key: string): Promise<CompanyConfiguration> {
        return new Promise((resolve) => {
            this.getAll(`${Constants.contextPath}/companies/get-by-key/${key}`, res => resolve(res), err => this.requestService.handleError(err));
        });
    }

    public getSearchableBadges(): Promise<Badge[]> {
        return new Promise((resolve) => {
            this.getAll(`${Constants.contextPath}/badges/list?${SearchOperations.KEY_SEARCH}=${SearchOperations.LEFT_PARENTHESES_READABLE}searchable${SearchOperations.EQUALITY}true${SearchOperations.RIGHT_PARENTHESES_READABLE}`, (res: Badge[]) => {
                resolve(res),
                err => this.requestService.handleError(err);
            });
        });
    }

    removeFields(params) {
      for (let [key, value] of Object.entries(params))
        if (value == null || value == [])
          delete params[key];
        else
          for (let [k, v] of Object.entries(value))
            if (v == null || v == [])
              delete value[k];
      return params;
    }
}
