<nav [ngClass]="{'scrolled animate__fadeIn z-index-first z-index-100': scrolledContent}" class="noselect second-nav shortAnim text-white animate__animated">
    <div [ngClass]="{'scrolled animate__slideInDown animate__animated': scrolledContent}" class="d-inline d-lg-none pull-left cart-wrap-hamburger">
        <a class="d-inline" (click)="showMobileSidebar = true">
            <img class="icon m-0" src="../../../assets/icons/hamburger.svg">
        </a>
    </div>
    <div class="nav-brand clickable pull-left outline-none" [routerLink]="['']">
        <img alt="Amko Logo" class="img-fluid my-lg-2" src="../../../assets/img/amko_logo.png">
    </div>
    <div [ngClass]="{'show': showInputForMobile}" class="position-input shortAnim">
        <div [ngClass]="{'show-mobile-input': showInputForMobile}" class="input-wrap shortAnim">
            <input autofocus class="search outline-none" type="text" [(ngModel)]="searchInput" (keydown.enter)="search()" [placeholder]="'SHARED.SEARCH' | translate">
            <div (click)="search()" class="search-icon-wrap shortAnim clickable">
                <img alt="pretraga proizvoda ikona" src="../../../assets/icons/search.svg">
            </div>
            <i (click)="showInputForMobile = false; searchInput = ''" class="ti-close d-lg-none d-inline"></i>
        </div>
    </div>
    <div class="d-inline-block pull-right">
        <div class="d-none d-lg-inline mr-5">
            <span class="text-uppercase clickable fs-12 opacity50">{{ 'SHARED.CALL_US' | translate }}</span> <br>
            <a href="tel:+38733465687" class="text-white">
                <span>
                    <span class="text-uppercase clickable text-bold">+387 33 465 687</span>
                </span>
            </a>
        </div>
        <div [ngClass]="{'scrolled animate__slideInDown animate__animated': scrolledContent}" class="d-inline pull-right cart-wrap">
            <div *ngIf="!showInputForMobile" [ngClass]="{'visible': cartProducts?.length > 0}" class="d-lg-inline-block d-none text-center opacity0 cart-items header-items">{{cartProducts?.length}}</div>
            <span *ngIf="!router.url.includes(RouteParams.cart)">
                <app-header-cart-items [ngClass]="{'height-auto': showCartItems > 0}" class="header-cart-items" [cartProducts]="cartProducts" [orderTotal]="orderTotal" (closeHeaderCartItemsEvent)="showCartItems = 0"></app-header-cart-items>
            </span>
            <a (click)="showInputForMobile = true" *ngIf="!showInputForMobile" class="d-inline d-lg-none">
                <img class="icon" alt="pretraga proizvoda ikona" src="../../../assets/icons/search_white.svg">                
            </a>
            <a class="d-inline clickable" (click)="showCartItems = 555">
                <!--Iste ikone, jedna za mobile druga za lg-->
                <div class="cart-icon d-lg-block d-none"></div>
            </a>
        </div>
        
    </div>

</nav>
<nav [ngClass]="{'scrolled-nav animate__slideInDown': scrolledContent, 'show shortAnim': showMobileSidebar}" class="noselect red-nav animate__animated">
    <i (click)="showMobileSidebar = false" class="ti-close d-inline d-lg-none text-white pull-right p-2"></i>
    <div class="nav-content mx-auto">
        <ul class="text-left p-0 m-0">
            <div>
                <li class="d-block d-lg-inline">
                    <a class="text-uppercase clickable shortAnim" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" routerLink="/">{{ 'MENU.HOMEPAGE' | translate }}</a>
                </li>
                <li class="d-block d-lg-inline">
                    <a class="text-uppercase clickable shortAnim" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="'/'+RouteParams.shop">{{ 'MENU.PRODUCTS' | translate }}</a>
                </li>
                <li class="d-block d-lg-inline">
                    <a class="text-uppercase clickable shortAnim" routerLinkActive="active" [routerLink]="'/'+RouteParams.shop+'/'+RouteParams.discount">{{ 'MENU.DISCOUNTS' | translate }}</a>
                </li>
                <li class="d-block d-lg-inline">
                    <a class="text-uppercase clickable shortAnim" href="https://www.amko.ba/prodavnice" target="_blank">{{ 'MENU.SHOPS' | translate }}</a>
                </li>
            </div>
        </ul>
        <div class="red-nav-second" *ngIf="this.authService.isPublicUser; else logoutTemplate">
            <li class="d-lg-inline d-block mr-4">
                <a class="text-uppercase clickable" routerLinkActive="active" [routerLink]="'/'+RouteParams.register">{{ 'SHARED.REGISTRATION' | translate }}</a>
            </li>
            <li class="d-lg-inline d-block margin-for-cart">
                <a class="text-uppercase clickable m-0 login-btn" routerLinkActive="active" data-cy="login" [routerLink]="'/'+RouteParams.login">{{ 'REGISTRATIONS.LOGIN' | translate }}</a>
            </li>
        </div>
        <ng-template #logoutTemplate>
            <div class="red-nav-second">
                <li class="d-block d-lg-inline mr-0 mr-lg-3">
                    <a data-cy="my-profile" [routerLink]="'/'+RouteParams.profile" class="text-uppercase clickable">{{ 'MENU.MY_PROFILE' | translate }}</a>
                </li>
                <li class="d-block d-lg-inline">
                    <a data-cy="logout" (click)="logout()" class="text-uppercase m-0 clickable">{{ 'REGISTRATIONS.LOGOUT' | translate }}</a>
                </li>
            </div>
        </ng-template>
    </div>
    <div class="d-lg-none text-white mt-5">
        <span class="text-uppercase clickable fs-12 opacity50">{{ 'SHARED.CALL_US' | translate }}</span> <br>
        <span class="text-uppercase clickable text-bold opacity80">+387 33 678 888</span>
        <!-- TODO staviti broj tel -->
    </div>
</nav>
<div [ngClass]="{'show': showMobileSidebar}" (click)="showMobileSidebar = false;" class="black-overlay shortAnim"></div>
<div [ngClass]="{'scrolled': scrolledContent}" (click)="scrollToTop()" class="scroll-to-top text-center shortAnim clickable animate__fadeIn animate__animated">
    <i class="ti-angle-up"></i>
    <span class="d-none d-md-block"> {{ 'SHARED.TO_TOP' | translate }} </span>
</div>