import { Injectable } from '@angular/core';
import { RequestService, HttpRequestType } from 'src/app/core/request.service';
@Injectable({
    providedIn: 'root'
})

export class BaseService {
    protected readonly pageSize: number = 15;

    constructor(protected requestService: RequestService) { }

    public getAll(url: string, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Get, url, null, null, (response: any) => {
            onSuccess(response);
        }, (error: any) => {
            if (onFail != null)
                onFail(error);
        });
    }

    public getById(url: string, id: number | string, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Get, `${url}/${id}`, null, null, (response: any) => {
            onSuccess(response);
        }, (error: any) => {
            if (onFail != null)
                onFail();
            else 
                this.requestService.handleError(error);
        });
    }

    public create(url: string, formData: any, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Post, url, formData, null, (response?: any) => {
            response != null ? onSuccess(response) : onSuccess();
        }, (error: any) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        });
    }

    public update(url: string, formData: any, onSuccess: Function, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Put, url, formData, null, (response?: any) => {
            response != null ? onSuccess(response) : onSuccess();
        }, onFail != null ? (error: any) => { onFail(error) } : (error) => { this.requestService.handleError(error); });
    }

    public delete(url: string, id: number | string, onSuccess: Function = null, onFail: Function = null): void {
        this.requestService.createRequest(HttpRequestType.Delete, `${url}/${id}`, null, null, () => {
            if(onSuccess != null)
                onSuccess();
        }, (error) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        });
    }
    // ovu sam ja doradio za svoj Paginator iz order modela
    public getAllAsPromise(url: string, page: number, sortOrder: string, sort: string, pageSize: number = null, onSuccess: Function, onFail: Function = null): any {
        return this.requestService.createRequestAsPromise(HttpRequestType.Get, `${url}?size=${pageSize}&page=${page}${sort ? '&'+sort : ''}${sortOrder ? '&'+sortOrder : ''}`, null, null, onSuccess, onFail);
    }

    
    public patch(url: string, body: any = null, onSuccess: Function, onFail: Function = null){
        this.requestService.createRequest(HttpRequestType.Patch, url, body, null, (response: any) => {
            onSuccess(response);
        }, (error) => {
            if(onFail != null)
                onFail(error);
            else 
                this.requestService.handleError(error);
        });
    }
}
