
<!-- mobile products/categories -->
<div *ngIf="showNav" class="footer-bar d-lg-none d-flex text-gray">
    <div class="footer-item">
        <a class="text-decoration-none" routerLink="/" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active" mat-icon-button>
            <img src="../../../assets/icons/home.svg" alt="Amko shop pocetna stranica ikonica">
            <span class="footer-text d-block">{{ 'SHARED.HOME' | translate }}</span>
        </a>
    </div>
    <div (click)="filterOrNavigate()" class="footer-item">
        <a mat-icon-button class="text-decoration-none">
            <ng-container *ngIf="router.url.includes(RouteParams.shop); else noShopTemplate">
                <i class="fas fa-filter"></i>
                <span class="footer-text d-block">{{ 'MENU.FILTER' | translate }}</span>
            </ng-container>
            <ng-template #noShopTemplate>
                <img src="../../../assets/icons/categories.svg" alt="Amko shop kategorije stranica ikonica">
                <span class="footer-text d-block">{{ 'MENU.PRODUCTS' | translate }}</span>
            </ng-template>
        </a>
    </div>
    <div class="footer-item">
        <a routerLinkActive="active" class="text-decoration-none" [routerLink]="['/'+RouteParams.cart ]" mat-icon-button>
            <img src="../../../assets/icons/cart.svg" alt="Amko shop korpica stranica ikonica">
            <span class="footer-text d-block">{{ 'CART.OPEN' | translate }}</span>
        </a>
        <div [ngClass]="{'visible': cartProducts?.length > 0}" class="d-inline-block text-center opacity0 cart-items mobile-footer-items">{{cartProducts?.length}}</div>
    </div>
</div>

<div *ngIf="showNav" [ngClass]="{'show-mobile-categories': showMobileCategories}">
    <div class="mobile-products bg-main-gray shortAnim p-3">
        <i (click)="hideCategories()" class="ti-close pull-right"></i>
        <app-category-list [isMobile]="true" [typeForLoad]='typeForLoad' (closeCategoryListEvent)="hideCategories()"></app-category-list>
    </div>
    <div (click)="hideCategories()" class="close-category shortAnim"></div>
</div>
