import { BaseModel, BaseModelActive, DayOfWeek } from 'src/app/shared/base-component/base.models';
import { OfferedProduct, Product } from '../shop/shop.models';
import { GiftPackage, DistributionCalendar, User, Municipality } from 'src/app/shared/shared.models';
import { Address } from '../profile/profile.models';

export class Cart {
    cartName?: string;
    cartProducts?: CartProduct[] = [];
    pointOfSale: PointOfSale | string;

    // cartIn from customerOrderProducts
    constructor(cartName: string, customerOrderProducts: CustomerOrderProduct[], pointOfSale: PointOfSale){
        this.cartName = cartName;
        this.cartProducts = customerOrderProducts
        .map(p => ({
            product: p.offeredProduct.product.id,
            quantity: p.orderedQuantity
        }))
        this.pointOfSale = pointOfSale.id;
    }
}
export class CartProduct extends BaseModel {
    product?: Product | string;
    quantity?: number;
    price?: number;
    totalPrice?: number;
    affectsMinimumOrderValue?: boolean;
    maxOrderQuantity?: number;
    unitOfMeasurementCode?: string;
    increment?: number;
    offeredProduct?: OfferedProduct | string;
    specialTreatment?: boolean;
}

export class CartProductIn {
    product: string;
    quantity: number;
    constructor(productId: string, quantity: number){
        this.product = productId;
        this.quantity = quantity;
    }
}

export class MeanOfDelivery extends BaseModelActive {
    description?: string;
    pointOfSale?: PointOfSale | string;
    type?: Type | string;
    validFrom?: string;
    validTo?: string;
}

export enum Type { 
    PERSONAL_PICKUP, SELLER_DISTRIBUTION, EXPRESS_DELIVERY
}

export class PaymentMethod extends BaseModel {
    code?: string;
    type?: PaymentType | string;
    description?: string;
    image?: string;
}

export enum PaymentType {
    CARD ="CARD", CASH ="CASH", ON_LINE ="ON_LINE", ON_LINE_OPA ="ON_LINE_OPA"
}

export class CartLocal {
    orderId: number;
    items: CartProduct[];
    time: Date;
}

export class PointOfSale extends BaseModelActive {
    code?: string;
    name?: string;
    type?: PointOfSaleType | string;
    priceType?: PointOfSaleType | string;
    geoX?: number;
    geoY?: number;
    workingHoursFrom?: string;
    workingHoursTo?: string;
    quantityControl?: boolean = false;
    workingDays: PointOfSaleWorkingDay[] | string = [];
}

export enum PointOfSaleType { 
    WHOLESALE, RETAIL, WEB
}

export enum PriceType {
    VAT_INCLUDED, VAT_EXCLUDED
}

export class PointOfSaleWorkingDay extends BaseModel {
    day: DayOfWeek | string;
}

export class CustomerOrder extends BaseModel {
    status?: CustomerOrderStatus;
    pointOfSale?: PointOfSale;
    buyer?: User;
    LocalDate?: Date;
    shippingZipCode?: string;
    shippingAddress?: string;
    shippingPhoneNumber?: string;
    shippingFullName?: string;
    usedPromotionalCode?: string;
    receivedPromotionalCode?: string;
    internalNote?: string;
    note?: string;
    regularValue?: number;
    saleValue?: number;
    rebateValue?: number;
    goodsValue?: number;
    expensesValue?: number;
    invoiceValue?: number;
    totalValue?: number;
    expectedShipmentDate?: Date;
    expectedShipmentTimeFrom?: string;
    expectedShipmentTimeTo?: string;    
    shippingMunicipality?: Municipality; // ovo mora zbog public usera i spasavanja u local 
    paymentMethod?: PaymentMethod;
    meansOfDelivery?: MeanOfDelivery;
    distributionCalendar?: DistributionCalendar;
    customerOrderProducts?: CustomerOrderProduct[];
    customerOrderGiftPackage?: GiftPackage;
    rebatePolicies?: RebatePolicy[];
    costs?: CustomerOrderCost[];
    address?: Address;
    documentNumber?: string;
    giftPackages?: GiftPackage[];
    shippingEmail?: string;

    // for displaying errors
    isPaymentSelected?: boolean = true;
    isMeansOfDeliverySelected?: boolean = true;
    isDistributionCalendarSelected?: boolean = true;
    upcPayments?: UpcPayment[];
    payed?: boolean; // only for displaying online payments

    // helper
    opaPhoneNumber?: string;

    constructor(){
        super()
    }
}

export class CustomerOrderIn {
    shippingMunicipality?: string;
    pointOfSale?: string;
    shippingZipCode?: string;
    shippingAddress?: string;
    shippingPhoneNumber?: string;
    shippingFullName?: string;
    paymentMethod?: string;
    address?: string;
    meansOfDelivery?: string;
    distributionCalendar?: string;
    expectedShipmentDate?: string;
    expectedShipmentTimeFrom?: string;
    expectedShipmentTimeTo?: string;
    usedPromotionalCode?: string;
    receivedPromotionalCode?: string;
    internalNote?: string;
    note?: string;
    customerOrderProducts?: CustomerOrderProductIn[] = [];
    customerOrderGiftPackage?: string;
    customerOrderRebatePolicies?: string[];
    costs?: string[];
    shippingEmail?: string;

    constructor(customerOrder: CustomerOrder, selectedPointOfSale: PointOfSale, isPublicUser: boolean){

        if(customerOrder.customerOrderProducts)
            customerOrder.customerOrderProducts.forEach((p) => {
                this.customerOrderProducts.push(new CustomerOrderProductIn(p))
            });

        this.meansOfDelivery = customerOrder.meansOfDelivery ? (<MeanOfDelivery>customerOrder.meansOfDelivery).id : null;

        this.distributionCalendar = customerOrder.distributionCalendar ? (<DistributionCalendar>customerOrder.distributionCalendar).id : null;

        this.customerOrderGiftPackage = customerOrder.customerOrderGiftPackage ? (<CustomerOrderGiftPackageProduct>customerOrder.customerOrderGiftPackage).id : null;
        this.shippingMunicipality = customerOrder.shippingMunicipality ? (<Municipality>customerOrder.shippingMunicipality).id : null; // for public users
        
        this.paymentMethod = customerOrder.paymentMethod ? (<PaymentMethod>customerOrder.paymentMethod).id : null;

        this.pointOfSale = selectedPointOfSale ? selectedPointOfSale.id : (customerOrder.pointOfSale ? (<PointOfSale>customerOrder.pointOfSale).id : null);

        this.usedPromotionalCode = customerOrder.usedPromotionalCode ? customerOrder.usedPromotionalCode : null;
        this.receivedPromotionalCode = customerOrder.receivedPromotionalCode ? customerOrder.receivedPromotionalCode : null;
        this.note = customerOrder.note ? customerOrder.note : null;

        if(isPublicUser) {
            this.shippingZipCode = customerOrder.shippingZipCode ? customerOrder.shippingZipCode : null; 
            this.shippingAddress = customerOrder.shippingAddress ? customerOrder.shippingAddress : null; 
            this.shippingPhoneNumber = customerOrder.shippingPhoneNumber ? customerOrder.shippingPhoneNumber : null; 
            this.shippingFullName = customerOrder.shippingFullName ? customerOrder.shippingFullName : null;
            this.shippingEmail = customerOrder.shippingEmail ? customerOrder.shippingEmail : null;
        }else{
            this.address = customerOrder.address ? (<Address>customerOrder.address).id : null;
        }
    }
}
export class CustomerOrderProductIn {
    product?: string;
    orderedQuantity?: number;
    offeredProduct?: string;
    constructor(customerOrderProduct: CustomerOrderProduct){
        this.orderedQuantity = customerOrderProduct.orderedQuantity;

        this.product = (<OfferedProduct>customerOrderProduct.offeredProduct).product.id;
        this.offeredProduct = customerOrderProduct.offeredProduct.id;
    }
}

export class CustomerOrderCost extends BaseModel {
    costPolicy?: string | CostPolicy[];
    value?: number;
}

export class CustomerOrderProduct extends BaseModel {
    offeredProduct?: OfferedProduct;
    product?: string; // ovo je samo za outModel
    orderedQuantity?: number;
    approvedQuantity?: number;
    deliveredQuantity?: number;
    regularPrice?: number;       //kataloska cijena - maloprodajna cijena - ide ispod imena na korpici ( na korpici samo cijena ispod imena )
    discountPercentage?: number; //procenat iz cjenovnika za POS i kupca
    salePrice?: number;          // akcijska cijena regularPrice - discountPercentage -- ide ispod imena na korpici ispod regular price
    rebatePercentage?: number;   // dodatni procenat - rabat, iz rabatnih politika -- ovo pisem na korpici
    invoicePrice?: number;       // konacna, fakturna cijena
    affectsMinimumOrderValue?: boolean;
}

export class CustomerOrderGiftPackageProduct extends BaseModel {
    giftPackageItem?: Product | string;
    quantity? : number;
}

export class RebatePolicy extends BaseModelActive {
    documentNumber?: string;
    validFrom?: Date;
    validTo?: Date;
    businessPartner?: BusinessPartner;
    pointOfSale?: string | PointOfSale;
    type?: RebatePolicyType | string;
    option?: RebatePolicyOption | string;
    userType?: PolicyUserType | string;
    minimalOrderValue?: number;
    orderSequentialNumber?: number;
    referencePeriodFrom?: Date;
    referencePeriodTo?: Date;
    paymentMethod?: string | PaymentMethod;
    promotionalCodeDocument?: string | PromotionalCodeDocument;
    ordersEligible?: number;
    ordersApplied?: number;
    defaultOn?: boolean;
    products?: string[] | Product[] = [];
}
export class UpcPayment {
    ApprovalCode: number;
    Currency: number;
    Email: string;
    OrderID: number;
    ProxyPan: string;
    PurchaseDesc: string;
    PurchaseTime: string;
    SD: string;
    TotalAmount: number;
    TranCode: number;
    XID: string;
}

export enum RebatePolicyType {
    PERCENT, PROMO_CODE, GIFT
}

export enum CustomerOrderStatus {
    SUBMITTED = 'SUBMITTED', APPROVED = 'APPROVED', PARTIALY_DELIVERED = 'PARTIALY_DELIVERED', DELIVERED = 'DELIVERED', CANCELED = 'CANCELED', PRE_SUBMITTED = 'PRE_SUBMITTED', NONE = 'NONE'
}

export enum RebatePolicyOption {
    ORDER_VALUE, TOTAL_ORDERS_PERIOD, SEQUENTIAL_ORDER, PROMO_CODE, PAYMENT_METHOD
}

export enum PolicyUserType {
    ALL, PUBLIC_MEMBER, REGISTERED_MEMBER, VIP_MEMBER
}

export class PromotionalCodeDocument extends BaseModel {
    documentNumber?: string;
    promotionalCodes?: PromotionalCode[] = [];
}

export class PromotionalCode extends BaseModel {
    code?: string;
    maxUseNumber?: number;
    timesUsed?: number;
    user?: string | User;
    userNotified?: boolean = false;
}

export class BusinessPartner extends BaseModelActive {
    name?: string;
}

export class CostPolicy extends BaseModelActive {
    documentNumber?: string;
    validFrom?: Date;
    validTo?: Date;
    pointOfSale?: PointOfSale | string;
    businessPartner?: BusinessPartner | string;
    description?: string;
    type?: CostPolicyType | string;
    userType?: PolicyUserType | string;
    orderValueFrom?: number;
    orderValueTo?: number;
    meansOfDelivery?: MeanOfDelivery | string;
    paymentMethod?: PaymentMethod | string;
    value?: number;
}

// export class DistributionCalendarsForDelivery extends BaseModelActive{
//     address?: string | Address;
//     municipality?: Municipality | string;
//     pointOfSale?: PointOfSale | string;
//     capacity?: number;
//     date?: Date | string;
//     meansOfDelivery?: MeanOfDelivery | string;
//     timeFrom?: Date;
//     timeTo?: Date;
//     usedCapacity?: number;
//     userId?: string;
//     version?: number;
// }

export enum CostPolicyType {
    PAYMENT_METHOD, MEANS_OF_DELIVERY, ADMINISTRATIVE_COST
}
