import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/app.constants';
import { RequestService } from 'src/app/core/request.service';
import { UtilityService } from 'src/app/core/utility.service';
import { BaseService } from 'src/app/shared/base-component/base.service';
import { News, NewsParagraph } from './news.models';

@Injectable({
    providedIn: 'root'
})
export class NewsService extends BaseService{
    constructor(protected requestService: RequestService, protected activatedRoute: ActivatedRoute, private utilityService: UtilityService, private translateService: TranslateService){
        super(requestService);
    }

    public getNewsByType(type: string, onSuccess: Function, onFail: Function): void {
        this.getById(`${Constants.contextPath}/news/get-by-link`, type, (response: News)=>{
            if(response){
                <NewsParagraph>response.newsParagraphs.sort((x,y) => x.priority - y.priority);
                onSuccess(response);
            } else {
                onFail();
            }
            
        })
    }

}
