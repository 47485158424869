import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    name: string;
    message: string;
    redirectUrl: string;
    inputValue: string;
    inputLabel: string;
    modalType: string;
    showConfirmBtn: boolean;
    inputType: string = 'text';

    constructor(private _mdr: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
        this.message = data.message;
        this.redirectUrl = data.redirectUrl;
        this.inputLabel = data.inputLabel;
        this.showConfirmBtn = data.showConfirmBtn;
        this.modalType = data.modalType;
        
        if(this.inputLabel == 'SHARED.PASSWORD')
            this.inputType = 'password';
        else if (this.inputLabel == 'SHARED.EMAIL')
            this.inputType = 'email';
    }

    closeDialog() {
        this._mdr.close(null);
    }

    confirmDialog() {
        if (!this.inputLabel) // ako je dialog bez inputa
            this._mdr.close(true);
        else
            this._mdr.close(this.inputValue);
    }

}
