import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-shop-banners',
    template: `<div class="banner-img clickable"></div>` 
})
export class ShopBannersComponent implements OnInit {

    constructor() { }
    ngOnInit(): void {
    }

}
