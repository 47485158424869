import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class SeoService {
    constructor(private titleService: Title, private metaService: Meta) { }
    private defaultKeywords = 'kupuj online, kupi online, web shop, online kupovina, web prodaja, web market, namirnice online, kupi namirnice online, naruči online, naruči namirnice online, dostava na vrata';
    private defaultMainTitle = 'Amko Web shop home';
    private defaultTitle = 'Amko Web shop Najbliži Vama';
    private defaultDescription = 'Dostava odabranih artikala brzo i efikasno na Vaša vrata !';
    private defaultImg = "https://eurobit-wsh.s3.eu-west-1.amazonaws.com/testing/email/amko_logo.png";
    private defaultUrl = "www.amkoshop.ba";
    
    setTitleAndMeta(name: string = this.defaultMainTitle, keywords: string = this.defaultKeywords, title: string = this.defaultTitle, description: string = this.defaultDescription, image: string = this.defaultImg){       
        // this.setMetaTags(keywords, title, description, image, url);
        this.metaService.updateTag({ name: 'name', content: name});
        this.metaService.updateTag({ name: 'description', content: description });
        this.metaService.updateTag({ name: 'image', content: image} );
        this.metaService.updateTag({ name: 'keywords', content: keywords} );
        this.setTitle(name);
    }

    setTitle(title: string){
        this.titleService.setTitle(title);
    }

    setMetaTags(keywords: string, title: string, description: string, image: string, url: string, siteName: string = 'Amko Web Shop'){
        this.metaService.addTags([
            {name: 'image', content: image},
            {name: 'keywords', content: keywords},
            {name: 'description', content: description},
            {name: 'url', content: url},
            {name: 'robots', content: 'index, follow'},
            {name: 'title', content: title},
            {name: 'site_name', content: siteName}
          ]);
    }
}