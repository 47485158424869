export class RouteParams {
    public static readonly login = 'prijava';
    public static readonly register = 'registracija';

    public static readonly profile = 'moj-profil';
    public static readonly settings = 'postavke';
    public static readonly orders = 'moje-narudzbe';
    public static readonly addresses = 'moje-adrese';

    public static readonly shop = 'shop';
    public static readonly discount = 'akcije';
    public static readonly cart = 'korpica';
    public static readonly product = 'proizvod';
    public static readonly footer = 'footer';
    // public static readonly discount = 'discount';

    public static readonly news = 'novosti';

    public static readonly mainMerch = 'kategorija'; // koristi se za params navigation - obicni parametri i samo je za pregled
    public static readonly badgeNames = 'oznake'; // koristi se samo za pregled - view

    // QueryParams
    public static readonly merch = 'co'; // koristi se za merchCode i u queryParametrima
    public static readonly search = 'pretraga';
    public static readonly brand = 'brend';
    public static readonly badgesId = 'bdgId';

    // NEWS
    public static readonly aboutUs = 'about-us';
    public static readonly buyOnline = 'buy-online';
    public static readonly contacts = "contacts";
    public static readonly payment = "payment";
    public static readonly paymentMethod = "payment-method";
    public static readonly delivery = "delivery";
    public static readonly registration = "registration";
    public static readonly instructions = "instructions";
    public static readonly TOS = "uslovi";
    public static readonly forYou = "for-you";
    public static readonly thankYou = "thank-you";
}