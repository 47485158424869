import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { BasketService } from 'src/app/shared/basket.service';
import { Router, ActivatedRoute, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CartLocal, CartProduct } from 'src/app/routes/cart/cart.models';
import { AuthService } from 'src/app/core/auth.service';
import { AlertService } from 'src/app/core/alert.service';
import { ValueLabel } from '../../shared/base-component/base.models';
import { TranslatorService } from 'src/app/core';
import { LoadingService } from 'src/app/core/loading.service';
import { RouteParams } from 'src/app/RouteParams';
import { inOutAnimation } from 'src/app/shared/animations';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    animations: [inOutAnimation]
})
export class NavbarComponent implements OnInit {
    public showSearchInput: boolean = false;
    public scrolledContent: boolean = false;
    public showCartItems: number = 0;
    public showLoginForm: boolean = false;
    public showMobileSidebar: boolean = false;
    public showInputForMobile: boolean = false;
    public selectedLanguage;
    public languages: ValueLabel[] = [
        {
            value: 'bs-BA',
            label: 'Bosanski'
        },
        {
            value: 'hr-HR',
            label: 'Hrvatski'
        },
        {
            value: 'en-US',
            label: 'English'
        },
        {
            value: 'sr-RS',
            label: 'Српски'
        }
    ]

    cartProducts: CartProduct[];
    orderTotal: number;
    searchInput: string = '';
    public RouteParams = RouteParams;
    
    @ViewChild('search') searchElement: ElementRef;

    @HostListener('window:scroll', [])
    onWindowScroll() {
        if (document.body.scrollTop > 170 || document.documentElement.scrollTop > 170)
            this.scrolledContent = true;
        else
            this.scrolledContent = false;
    }

    constructor(private basketService: BasketService, 
                public router: Router, 
                private eRef: ElementRef, 
                public authService: AuthService, 
                private alertService: AlertService, 
                private activatedRoute: ActivatedRoute,
                public translatorService: TranslatorService,
                public loadingService: LoadingService,
                public $gaService: GoogleAnalyticsService) { 
                    router.events.subscribe(
                        (event: RouterEvent): void => { // close navigation on mobile
                            if (event instanceof NavigationEnd && this.showMobileSidebar)
                                this.showMobileSidebar = false;                            
                        }
                    )}

    ngOnInit(): void {
        this.handleCartNotifications();
        this.authService.showLoginPopupSubject.subscribe((show: boolean) => {
            this.showLoginForm = show;
        })
        if(window.innerWidth < 992 && this.searchInput != '')
            this.router.events.subscribe((val) => {
                setTimeout(() => {
                    if(this.showInputForMobile)
                        this.showInputForMobile = false;
                    }, 3000);
            });
            
    }

    private handleCartNotifications(){
        this.basketService.cartSubject.subscribe((data: CartLocal) => {
            this.cartProducts = data.items;
            this.orderTotal = 0;
            data.items.forEach(cartProducts => {
                this.orderTotal += cartProducts.price * cartProducts.quantity;
            });
            this.$gaService.event('Add')
            
            if(data.items.length == 0){
                this.showCartItems = 0;
                return
            }
            //  Don't end animation if new product is added
            this.showCartItems ++;
            let initialNumber = this.showCartItems;
            setTimeout(() => {
                if(initialNumber == this.showCartItems)
                    this.showCartItems = 0;
            }, 5000);
        });
    }

    scrollToTop(){
        window.scrollTo(0, 0);
    }

    showInput(){
        this.showSearchInput = true;
        setTimeout(()=>{
            this.searchElement.nativeElement.focus();
        },99);
    }

    // setLanguage(language){
    //     this.selectedLanguage = language;
    //     this.translatorService.useLanguage(language.value);
    //     window.location.reload();
    // }

    search(){
        let urlWithoutQueryParams = this.router.parseUrl(this.router.url).root.children['primary']?.segments.map(it => it.path);
        urlWithoutQueryParams?.includes(RouteParams.product) ? urlWithoutQueryParams.splice(urlWithoutQueryParams.length - 3, 3) : null;

        if(this.router.url.includes(RouteParams.shop))
            this.router.navigate(
                [`/${urlWithoutQueryParams.join('/')}`], 
                {
                    queryParams: { [RouteParams.search]: this.searchInput },
                    queryParamsHandling: 'merge', // remove to replace all query params by provided
                }
            );
        else
            this.router.navigate(['/'+RouteParams.shop], { queryParams: { search: this.searchInput } });
        
        this.$gaService.event('searchArticle', 'searchArticle', 'pretraga - '+this.searchInput);
    }

    logout(){
        this.authService.removeAuth();
        this.alertService.success('REGISTRATIONS.SUCCESS_LOGOUT');
    }

}
