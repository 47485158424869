import { NgModule, ModuleWithProviders } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CategoryListComponent } from '../routes/shop/category-list/category-list.component';


import { CoreModule } from '../core/core.module';
import { RouterModule } from '@angular/router';
import { CarouselComponent } from './carousel/carousel.component';
import { ProductCardComponent } from '../routes/shop/product-card/product-card.component';
import { ShopBannersComponent } from '../routes/shop/shop-banners/shop-banners.component';
import { MatDesignModule } from './mat-desing.module';
import { NewAddressModalComponent } from './new-address-modal/new-address-modal.component';
import { DialogComponent } from './dialog/dialog.component';
import { ShopBreadcrumbComponent } from '../routes/shop/shop-breadcrumb/shop-breadcrumb.component';
import { OrderPreviewComponent } from './order-preview/order-preview.component';
import { SimpleTableComponent } from './simple-table/simple-table.component';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NewsSingleComponent } from '../routes/news/news-single/news-single.component';
import { TermsPolicyCookiesComponent } from './terms-policy-cookies/terms-policy-cookies.component';
import { SafeHtmlPipe } from '../routes/news/safe-html.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const SharedComponents = [
    CategoryListComponent,
    CarouselComponent,
    ProductCardComponent,
    ShopBreadcrumbComponent,
    ShopBannersComponent,
    NewAddressModalComponent,
    DialogComponent,
    OrderPreviewComponent,
    SimpleTableComponent,
    PopupModalComponent,
    NewsSingleComponent,
    TermsPolicyCookiesComponent,
    ForgotPasswordComponent
];  

@NgModule({
    declarations: [SharedComponents, SafeHtmlPipe],
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide:    TranslateLoader,
                useFactory: (createTranslateLoader),
                deps:       [HttpClient]
            }
        }),
        HttpClientModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatDesignModule,
        LazyLoadImageModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        SharedComponents,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        LazyLoadImageModule,
        SafeHtmlPipe
    ],
    entryComponents: [DialogComponent, ForgotPasswordComponent],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    } }
