import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AlertService } from 'src/app/core/alert.service';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog.service';
import { BaseService } from 'src/app/shared/base-component/base.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public formData = {
        username: '',
        password: ''
    };
    passVisibility: boolean = true;

    constructor(private router: Router, private authService: AuthService, private alertService: AlertService, private dialogService: DialogService, private baseService: BaseService, private eRef: ElementRef) { }

    ngOnInit(): void {
    }

    public login(): void {
        this.authService.login(this.formData.username.toLowerCase().trim(), this.formData.password).subscribe(_ => {
            if (this.authService.hasRole('CLIENT_CUSTOMER')) {
                this.router.navigate([''])
                this.alertService.success('REGISTRATIONS.SUCCESS_LOGIN');
            } else {
                this.authService.removeAuth();
                this.alertService.error('ERRORS.LOGIN_FAILED');
            }
        }, (error) => {
            console.log('LOGIN ERROR', error)
            if (error.error.error === 'unauthorized')
                this.alertService.error('ERRORS.LOGIN_FAILED');
            else if (error.error.error_description === 'User account is locked.')
                this.forgotPassword(true);
            else
                this.alertService.error(error, true);
        }
        );
    }

    forgotPassword(lockedAccount: boolean = false) {
        let inputTitle: string = lockedAccount ? 'ERRORS.LOCKED_ACCOUNT' : 'SHARED.ENTER-HERE-EMAIL';
        this.dialogService.openModalWithInput(inputTitle, 'SHARED.EMAIL', (res) => {
            this.baseService.patch(`${Constants.contextPath}/users/forgot-password/${res}/WEB_SHOP`, null, (res) => {
                this.alertService.success('REGISTRATIONS.RESET_PW', 10000);
            }, (error) => {
                if (error.status === 404) {
                    this.alertService.error('REGISTRATIONS.EMAIL_NOT_FOUND')
                } else {
                    this.alertService.error('ERRORS.GENERAL', false, 5000);
                }
            });
        })
    }

}
