import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { LoadingService } from 'src/app/core/loading.service';
import { RouteParams } from 'src/app/RouteParams';
import { CartLocal, CartProduct } from 'src/app/routes/cart/cart.models';
import { PointOfSale, TypeForLoad } from 'src/app/routes/shop/shop.models';
import { ShopService } from 'src/app/routes/shop/shop.service';
import { BasketService } from '../basket.service';

@Component({
    selector: 'app-mobile-footer-nav',
    templateUrl: './mobile-footer-nav.component.html',
    styleUrls: ['./mobile-footer-nav.component.scss']
})
export class MobileFooterNavComponent implements OnInit {
    public showMobileCategories: boolean = false;
    public showNav: boolean = false;
    public selectedPointOfSale: PointOfSale = new PointOfSale();
    public cartProducts: CartProduct[];
    public RouteParams = RouteParams;
	public typeForLoad: TypeForLoad;

    constructor(private shopService: ShopService, public router: Router, private basketService: BasketService, private authService: AuthService) { }

    ngOnInit(): void {        
        this.handleCartNotifications();
        if(window.innerWidth < 992)
            this.subscribeIfMobile();
        this.router.events.subscribe(() => {
            this.hideCategories();
            this.typeForLoad = this.router.url.split('/')[2] == RouteParams.discount ? TypeForLoad.DISCOUNT : TypeForLoad.ALL;
        });
    }

    private subscribeIfMobile(){
        this.authService.isTokenSet$.subscribe((isTokenSet: boolean) =>{
            if(isTokenSet){
                this.showNav = true;
                this.shopService.selectedPointOfSale.subscribe((posData: PointOfSale) => {
                    if(this.selectedPointOfSale != posData && posData.id) {
                        this.selectedPointOfSale = posData;
                        this.hideCategories()
                    }
                });
            }
        })
    }

    private handleCartNotifications(){
        this.basketService.cartSubject.subscribe((data: CartLocal) => {
            this.cartProducts = data.items;
        })
    }

    public filterOrNavigate(){
        if(this.router.url.includes(RouteParams.shop)) {
            this.showMobileCategories = true
            document.body.style.overflowY = 'hidden';
        }
        else
            this.router.navigate([RouteParams.shop]);
    }

    public hideCategories() {
        this.showMobileCategories = false;
        document.body.style.overflowY = 'auto';
    }

}
