import { Component, OnInit } from '@angular/core';
import { RouteParams } from 'src/app/RouteParams';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public PDFUrl: string = 'https://eurobit-wsh.s3-eu-west-1.amazonaws.com/testing/terms-of-service/Uslovi_kupovine_Amkoshop.pdf';
  public RouteParams = RouteParams;

  constructor() { }

  ngOnInit(): void {
  }

}