import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PixelService } from 'ngx-pixel';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/app.constants';
import { AlertService } from 'src/app/core/alert.service';
import { DialogService } from 'src/app/core/dialog.service';
import { LoadingService } from 'src/app/core/loading.service';
import { UtilityService } from 'src/app/core/utility.service';
import { RouteParams } from 'src/app/RouteParams';
import {
    CustomerOrder,
    CustomerOrderProduct,
    CustomerOrderStatus,
    PaymentType
} from 'src/app/routes/cart/cart.models';
import { CartService } from 'src/app/routes/cart/cart.service';
import { OPAPayment, OPAPaymentStatus, PaymentService, UPCBody } from 'src/app/routes/cart/payment.service';
import { BasketService } from '../basket.service';

@Component({
    selector: 'app-order-preview',
    templateUrl: './order-preview.component.html',
    styleUrls: ['./order-preview.component.scss']
})
export class OrderPreviewComponent implements OnInit, OnDestroy {
    @ViewChild('upcForm') upcForm: ElementRef;
    @Output() closeOrderModalEvent = new EventEmitter();
    @Input('customerOrder') customerOrder: CustomerOrder;

    private paramsSubscription: Subscription;
    private orderId: string;
    public formUrl: string;
    public upcBody: UPCBody;
    public PaymentType = PaymentType;
    public CustomerOrderStatus = CustomerOrderStatus;
    public showOPAModal: boolean = false;
    public showOPALoader: boolean = false;
    public OPAmodalTranslateKey: string = 'OPA.CONFIRM_ON_MOBILE';

    constructor(
        private pixelService: PixelService,
        public utilityService: UtilityService,
        public loadingService: LoadingService,
        private router: Router,
        private cartService: CartService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private paymentService: PaymentService
    ) { }

    ngOnInit(): void {
        this.paramsSubscription = this.route.params.subscribe(routeParams => {
            if (routeParams.orderId)
                this.cartService.getById(`${Constants.contextPath}/customer-orders`, routeParams.orderId, (res) => {
                    this.customerOrder = res;
                    this.orderId = routeParams.orderId;
                })
        });
    }

    ngOnDestroy() {
        if (this.paramsSubscription)
            this.paramsSubscription.unsubscribe();
    }

    public closeModal() {
        if (this.showOPAModal) return;
        if (this.orderId)
            this.router.navigate([`${RouteParams.profile}/${RouteParams.orders}`])
        else
            this.closeOrderModalEvent.emit('close');
    }

    public submitCustomerOrder() {
        this.cartService.submitCustomerOrder({ ...this.customerOrder }, (response: CustomerOrder) => {
            this.afterSubmitting(response);
        })
    }

    public afterSubmitting(customerOrder: CustomerOrder) {
        console.log('Pixel Purchase');
        this.pixelService.track('Purchase', {
            content_ids: customerOrder.customerOrderProducts.map((cop: CustomerOrderProduct) => cop.id),   // Item SKUs
            value: customerOrder.totalValue,                         // Value of all items
            currency: 'EUR'
        });

        if (customerOrder.paymentMethod.type == PaymentType.ON_LINE) {
            this.paymentService.generateUpcBody(customerOrder.id)
                .then((res: UPCBody) => {
                    this.upcBody = res;
                    this.formUrl = res.upcPaymentUrl;
                    this.createFormForUPC(res);
                })
                .catch(() => this.alertService.error('ERRORS.GENERAL'))
        } else if (customerOrder.paymentMethod.type == PaymentType.ON_LINE_OPA) {
            this.showOPAModal = true;
            this.showOPALoader = true;
            this.paymentService.sendOpaPayment(customerOrder.opaPhoneNumber, customerOrder.id)
                .then((res: OPAPayment) => {
                    this.showOPALoader = true;
                    if (res.paymentStatus == OPAPaymentStatus.ACCEPTED) {
                        this.OPAmodalTranslateKey = 'OPA.SUCCESSFUL';
                        setTimeout(() => {
                            this.showOPAModal = false;
                            this.closeModal();
                            this.cartService.updateCustomerOrderSubject(new CustomerOrder());
                            this.router.navigate([`/i/${RouteParams.thankYou}`]);
                            window.scrollTo(0, 0);
                        }, 2000);
                    } else {
                        this.showOPAModal = false;
                        this.showOPALoader = false;
                        this.closeOrderModalEvent.emit();
                        this.router.navigate([`/${RouteParams.cart}`], { queryParams: { payment: 'error_opa_declined' } }); // TODO ako nije public stavt ga na narudzbu
                    }
                })
                .catch(() => {
                    this.showOPAModal = false;
                    this.showOPALoader = false;
                    this.closeOrderModalEvent.emit();
                    this.router.navigate([`/${RouteParams.cart}`], { queryParams: { payment: 'error' } });
                })
        }
        else {
            this.closeModal();
            this.cartService.updateCustomerOrderSubject(new CustomerOrder());
            this.router.navigate([`/i/${RouteParams.thankYou}`]);
            window.scrollTo(0, 0);
        }
    }

    private createFormForUPC(values: UPCBody) {
        var form = document.createElement("form");
        delete values.id;
        delete values.dateCreated;
        delete values.dateModified;
        delete values.userId;
        delete values.upcPaymentUrl;
        form.method = "POST";
        form.action = this.formUrl;

        for (let [key, value] of Object.entries(values))
            form.appendChild(this.generateInput(key, value));

        document.body.appendChild(form);

        form.submit();
    }

    private generateInput(key, value) {
        var inputElement = document.createElement("input");
        inputElement.value = value.toString();
        inputElement.name = key.charAt(0).toUpperCase() + key.slice(1);
        inputElement.type = "hidden";
        return inputElement;
    }

    public goBack() {
        if (this.orderId)
            this.router.navigate([`/${RouteParams.profile}/${RouteParams.orders}`]);
        else
            window.history.back();
    }
}
