import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from '../routes/login/login.component';
import { MatDesignModule } from '../shared/mat-desing.module';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderCartItemsComponent } from './navbar/header-cart-items/header-cart-items.component';

const Components = [
  FooterComponent,
  HeaderCartItemsComponent,
  LoginComponent,
  NavbarComponent 
] 
@NgModule({
  declarations: [
    Components    
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    MatDesignModule
  ],
  exports: [
    Components,
    RouterModule
  ]
})
export class LayoutModule { }
