import { NgModule } from '@angular/core';

import {
    TranslatorService,
} from '.';

@NgModule({
    providers: [
        TranslatorService
    ]
})
export class CoreModule { 
    constructor(private translatorService: TranslatorService) { }
}
