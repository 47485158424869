<div class="footer shortAnim text-white py-4 mb-5 mb-md-1">
    <div class="container-md">
        <!-- !TODO! prevesti sta treba -->
        <div class="row mb-5 mb-sm-2">
            <div class="col-sm-3 mx-auto text-center text-sm-left">
                <img class="footer-logo" alt="Amko logo" src="../../../assets/img/amko_logo.png">
                <h2 class="my-3">{{ 'SHARED.CONTACT_US' | translate }}</h2>
                <a href="https://www.instagram.com/amko_marketi/" target="_blank">
                    <img class="whiteRoundedIcon mx-1 clickable" src="../../../assets/icons/instagram_a.svg" alt="Amko shop instagram ikona">
                </a>
                <a href="https://www.facebook.com/AmkoKomerc" target="_blank">
                    <img class="whiteRoundedIcon mx-1 clickable" src="../../../assets/icons/facebook_a.svg" alt="Amko shop facebook ikona">
                </a>
                <a href="https://www.youtube.com/channel/UCMlrl10EfmwTEjWLzziTl3w/featured" target="_blank">
                    <img class="whiteRoundedIcon mx-1 clickable" src="../../../assets/icons/youtube_a.svg" alt="Amko shop youtube ikona">
                </a>
            </div>
            <div class="row col-sm-9 mx-auto text-center text-lg-left">
                <div class="col-lg-3 col-sm-6 mt-3 mt-lg-0 order-3 order-lg-1">
                    <h2 [routerLink]="'/'+RouteParams.footer" class="pt-4 pt-sm-0 outline-none clickable">{{ 'SHARED.MEET_US' | translate }}</h2>
                    <hr class="d-none d-sm-block">
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.aboutUs }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'SHARED.ABOUT_US' | translate }}</p>
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.buyOnline }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'FOOTER.WHY_BUY' | translate }}</p>
                    <a class="clickable text-white-hoverable text-white shortAnim outline-none clickable" [routerLink]="['/news/'+RouteParams.TOS]">
                        {{ 'FOOTER.TOB' | translate }}
                    </a>
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.contacts }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'SHARED.CONTACT' | translate }}</p>
                </div>
                <div class="col-lg-3 col-sm-6 order-1 order-lg-2">
                    <h2 [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.payment }" queryParamsHandling="merge" class="pt-4 pt-sm-0 outline-none clickable">{{ 'FOOTER.PAD' | translate }}</h2>
                    <hr class="d-none d-sm-block">
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.paymentMethod }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'COST_POLICY_TYPES.PAYMENT_METHOD' | translate }}</p>
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.delivery }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'FOOTER.RELIABLE_DELIVERY' | translate }}</p>
                    <!-- <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: 'personal-pickup' }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'DELIVERY_TYPES.PERSONAL_PICKUP' | translate }}</p> -->
                </div>
                <div class="col-lg-3 col-sm-6 mt-3 mt-lg-0 order-4 order-lg-3 mx-auto">
                    <h2 queryParamsHandling="merge" class="pt-4 pt-sm-0 outline-none clickable">{{ 'FOOTER.USER_SUPPORT' | translate }}</h2>
                    <hr class="d-none d-sm-block">
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.registration }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'FOOTER.REGISTER' | translate }}</p>
                    <p [routerLink]="'/'+RouteParams.footer" [queryParams]="{ l: RouteParams.instructions }" class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'FOOTER.INSTRUCTIONS' | translate }}</p>
                    <!-- <p class="clickable text-white-hoverable shortAnim outline-none clickable">{{ 'FOOTER.FAQ' | translate }}</p> -->
                </div>
                <div class="col-lg-3 col-sm-6 order-2 order-lg-4 mx-auto">
                    <h2 [routerLink]="'/'+RouteParams.footer" class="pt-4 pt-sm-0 outline-none clickable">{{ 'SHARED.CALL_US' | translate }}</h2>
                    <hr class="d-none d-sm-block">
                    <a href="mailto:webshop@amko.ba" class="text-white mt-2 mb-3 d-block d-lg-flex">
                        <img class="height-36p clickable" src="../../../assets/icons/email-a.svg" alt="Amko shop email ikona">
                        <span class="align-self-center ml-1">
                            webshop@amko.ba
                        </span>
                    </a>
                    <h2 class="text-white mt-2">
                        <a href="tel:+38733465687" class="text-white">
                            <img class="height-36p clickable" src="../../../assets/icons/phone-a.svg" alt="Amko shop telefon ikona">
                            <span>
                                +387 33 465 687
                            </span>
                        </a>
                    </h2>

                </div>
            </div>
        </div>
    </div>
</div>
