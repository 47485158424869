import { BaseModel, BaseModelActive } from './base-component/base.models';
import { Product, PointOfSale } from '../routes/shop/shop.models';

export class Category extends BaseModel{
    id: string;
    name: string;
    group: object[];
}
export class Country extends BaseModel {
    name?: string;
    officialName?: string;
    alpha2Code?: string;
    alpha3Code?: string;
    numericCode?: string;
    topLevelDomain?: string;
    currency?: string | Currency;
}
export class Currency extends BaseModel {
    currencyName?: string;
    isoCurrencyCode?: string;
    numericCurrencyCode?: string;
    localCurrencyCode?: string;
}
export class Municipality extends BaseModel {
    name?: string;
    description?: string;
    country?: Country | string;
}

export class ProductClassification extends BaseModel {
    code?: string;
    name?: string;
    parent?: ProductClassification;
    parentId?: string;
    priority?: number;
    children?: ProductClassification[] = [];
}

export class ProductBrand extends ProductClassification {
}

export class ProductCategory extends ProductClassification {
}

export class ProductAssortment extends ProductClassification {
}

export class ProductMerchandise extends ProductClassification {
}

/**
 * od ovog enuma se generise url za poziv iz shop servisa za dobivanje klasifikacije
 */
export enum ClassificationType {
    // product_Categories = 'product_Categories',
    // product_Brands = 'product_Brands',
    // product_Assortments = 'product_Assortments',
    product_Merchandise = 'product_Merchandise_groups'
}

export class GiftPackage extends BaseModel {
    code?: string;
    name?: string;
    description?: string;
    giftPackageItems?: GiftPackageItem[];
    image?: string;
}

export class GiftPackageItem extends BaseModel {
    giftPackageCode?: string;
    product?: Product | string;
    quantity?: number;
}

export class MeanOfDelivery extends BaseModelActive {
    description?: string;
    pointOfSale?: PointOfSale | string;
    type?: Type | string;
    validFrom?: string;
    validTo?: string;
}

export enum Type { 
    PERSONAL_PICKUP, SELLER_DISTRIBUTION, EXPRESS_DELIVERY
}

export class DistributionCalendar extends BaseModelActive {
    pointOfSale?: PointOfSale | string;
    meansOfDelivery?: MeanOfDelivery | string;
    municipality?: Municipality | string;
    address?: string;
    date?: Date;
    timeFrom?: Date;
    timeTo?: Date;
    capacity?: number;
    usedCapacity?: number;
}
export class User extends BaseModelActive {
    name?: string;
    login?: string;
    password?: string;
    email?: string;
    userType?: UserType | string;
    role?: Role | string;
    roles?: Role[] | string[];
}

export enum UserType { 
    SYSTEM, CLIENT_USER, CLIENT_CUSTOMER
}

export class Role {
    value?: string;
    description?: string;
    groups?: string;
    privileges?: Privilege[] | string[] = [];
    parent?: Role | string;
    systemRole?: boolean = false;
}

export class Privilege extends BaseModel {
    value?: string;
    description?: string;
    groups?: string;
    system?: boolean = false;
}

export class Column {
    translationKey: string;
    dataField: string;
    cssClass?: string;
    dataType?: string;
}
