<div [ngClass]="{'public': authService.isPublicUser }" class="address-modal mat-white-theme">
    <i (click)="closeAddressModalEvent.emit($event)" class="ti-close pull-right p-2 clickable"></i>
    <div>
        <button [disabled]="!formData.valid" mat-flat-button class="width-40 bg-main text-white save-btn" (click)="submit()">
            <i class="ti-save-alt mr-2"></i> {{ 'MODAL.CREATE' | translate }}
        </button>
    </div>
    <form *ngIf="initialiseForm" [formGroup]="formData" autocomplete="off" class="row pull-right mt-2">
        <div [ngClass]="[authService.isPublicUser ? 'col-md-6' : 'col-md-12']">
            <mat-form-field class="max-width">
                <mat-label>
                    {{ 'SHARED.FIRST_NAME' | translate }}
                    <ng-container *ngIf="authService.isPublicUser">
                        {{ 'SHARED.AND' | translate }} {{ 'SHARED.LAST-NAME' | translate }}
                    </ng-container>
                </mat-label>
                <input matInput [formControl]="formData.controls['name']">
                <mat-error *ngIf="formData.get('name').errors && formData.get('name').hasError('pattern')">{{ 'REGISTRATIONS.NAME-INVALID-ERROR' | translate }}</mat-error>
                <mat-error *ngIf="formData.get('name').errors && formData.get('name').hasError('required')">{{ 'REGISTRATIONS.NAME-REQUIRED' | translate }}</mat-error>
                <mat-error *ngIf="formData.get('name').errors && formData.get('name').hasError('maxLength')">{{ 'REGISTRATIONS.NAME-LENGTH' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="authService.isPublicUser" class="col-md-6">
            <mat-form-field class="max-width">
                <mat-label>
                    {{ 'SHARED.EMAIL' | translate }}
                </mat-label>
                <input matInput [formControl]="formData.controls['shippingEmail']">
                <mat-error>{{ 'VALIDATION.PATTERN' | translate }}</mat-error>
                <!-- <mat-error>{{ 'VALIDATION.REQUIRED' | translate }}</mat-error> -->
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="max-width">
                <mat-label>{{ 'SHARED.STREET-NAME' | translate }}</mat-label>
                <input matInput [formControl]="formData.controls['street']">
                <mat-error>{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="max-width">
                <mat-label>{{ 'SHARED.PHONE' | translate }}</mat-label>
                <input matInput type="text" [formControl]="formData.controls['phoneNumber']">
                <mat-error *ngIf="formData.get('phoneNumber').errors && formData.get('phoneNumber').hasError('required')">{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
                <!-- <mat-error *ngIf="formData.get('phoneNumber').errors && formData.get('phoneNumber').hasError('pattern')">{{ 'REGISTRATIONS.PHONE-INVALID-ERROR' | translate }}</mat-error> -->
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field (click)='resetFilter()' class="max-width">
                <mat-label>{{ 'SHARED.MUNICIPALITY' | translate }}</mat-label>
                <mat-select [formControl]="formData.controls['municipality']" #singleSelect>
                    <input placeholder="{{'SHARED.SEARCH' | translate }}..." class="search" (keyup)="search($event.target.value)">
                    <mat-option *ngFor="let municipality of filteredMunicipalities" [value]="municipality.id">
                        {{ municipality.name }}
                    </mat-option>
                </mat-select>
                <mat-error>{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field class="max-width">
                <mat-label>{{ 'SHARED.POSTAL-CODE' | translate }}</mat-label>
                <input matInput type="text" maxlength="5" [pattern]="numberPattern" [formControl]="formData.controls['zipCode']">
                <mat-error>{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <!-- <div *ngIf="!authService.isPublicUser" class="col-12">
            <mat-form-field class="max-width">
                <mat-label>{{ 'PROFILE.DAYS_FOR_DELIVERY' | translate }}</mat-label>
                <mat-select [formControl]="formData.controls['daysOfWeek']" multiple>
                  <mat-option class="no-checkbox" (click)="selectAll()">{{ 'SHARED.SELECT_ALL' | translate }}</mat-option>
                  <mat-option *ngFor="let day of daysOfWeek" [value]="day.value">{{ day.label | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <div *ngIf="!authService.isPublicUser" class="col-12">
            <mat-form-field class="max-width">
                <mat-label>{{ 'PROFILE.ADDRESS_DESCRIPTION' | translate }}</mat-label>
                <input matInput [formControl]="formData.controls['description']">
            </mat-form-field>
        </div>
    </form>
</div>
<div (click)="closeAddressModalEvent.emit($event)" class="black-overlay shortAnim"></div>
