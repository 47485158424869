import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { Banner } from 'src/app/routes/home/home.models';
import { HomeService } from 'src/app/routes/home/home.service';

@Component({
    selector: 'app-popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnInit {
    @Output() closePopupModalEvent = new EventEmitter();
    @Input('popupInfo') popupInfo: Banner;
    public bucketBaseUrl = Constants.amazonS3Bucket;
    imagePathForScss: string;

    constructor(private homeService: HomeService, private router: Router, @Inject(DOCUMENT) private document: Document,) { }

    ngOnInit(): void {
        this.imagePathForScss = this.popupInfo.image ? `url(${this.popupInfo.image})` : '../../../assets/img/gray-popup-default.jpg';
    }

    closeModalAndSetAsViewed(){
        this.homeService.addPopupToViewedInStorage(this.popupInfo.id);
        this.closePopupModalEvent.emit('close');
    }

    public openLink(link: string){
        if(!link) return;
        if(link.includes('eurobit') || link.includes('amkoshop.ba'))
            this.router.navigateByUrl(link); // if route is in this project
        else if(link.includes('www') || link.includes('http'))
            this.document.location.href = link; // if external link
        else 
            this.router.navigateByUrl(link); // if route is in this project
    }

}
