<div [ngClass]="[modalType == 'GIFT_PACKAGE' ? 'giftPackageBg': 'defaultDialog' ]" class="overflow-hidden" style="width: min(500px, 75vw)">
    <img *ngIf="modalType == 'GIFT_PACKAGE'" lazyLoad="../../../assets/img/giftPackageDefault.jpg" [defaultImage]="'../../../assets/img/spinner.gif'" class="img-fluid">
    <div class="content">
        <div class="pt-2 pb-3 px-2 px-md-4 dialog-message" mat-dialog-content>{{ message | translate }}</div>
        <div mat-dialog-actions align="end">
            <span>
                <div *ngIf="inputLabel" class="col-12 px-2 px-md-4">
                    <mat-form-field class="max-width" appearance="fill">
                        <mat-label>{{ inputLabel | translate }}</mat-label>
                        <input matInput autofocus [type]="inputLabel == 'SHARED.PASSWORD' ? 'password' : 'text'" [(ngModel)]="inputValue">
                    </mat-form-field>
                </div>
                <button type="button" mat-stroked-button class="smaller-mobile outline-none mr-3" (click)="closeDialog()">
                    {{ 'MODAL.CLOSE' | translate}}
                </button>
                <button *ngIf="showConfirmBtn" type="button" mat-stroked-button class="bg-main text-white smaller-mobile outline-none mb-1 mr-2 mr-md-4" (click)="confirmDialog()">
                    {{ 'MODAL.CONFIRM' | translate}}
                </button>
            </span>
        </div>
    </div>
</div>