<ng-container *ngIf="loading; else loadedTemplate">
    <div class="circle-loader general-loader"></div>
</ng-container>
<ng-template #loadedTemplate>
    <ngx-loading-bar height="3px" color="#df1f26"></ngx-loading-bar>
    <div class="animate__fadeIn animate__animated">
        <app-navbar></app-navbar>
        <div class="container-fluid app-content">
            <router-outlet>
            </router-outlet>
            <app-mobile-footer-nav></app-mobile-footer-nav>
        </div>
        <app-footer></app-footer>
    </div>
    <app-terms-policy-cookies></app-terms-policy-cookies>
</ng-template>