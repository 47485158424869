import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { ShopService } from '../shop.service';
import { ProductCategory, ProductBrand, ProductMerchandise, ClassificationType, ProductClassification } from 'src/app/shared/shared.models'
import { UtilityService } from 'src/app/core/utility.service';
import { Badge, FilterForSummary, TypeForLoad } from '../shop.models';
import { LoadingService } from 'src/app/core/loading.service';
import { RouteParams } from 'src/app/RouteParams';
import { Constants } from 'src/app/app.constants';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnDestroy {
    @Output() closeCategoryListEvent = new EventEmitter();
    @Input('isMobile') isMobile: boolean;
    @Input('typeForLoad') typeForLoad: TypeForLoad;
    
    selectedBrandCode: number;
    selectedMerchCode: string;
    selectedBadgesIds: string[] = [];
    selectedProductMerchandise: ProductMerchandise;

    productClassification;

    parentCodes: string[] = ['', '', '', ''];
    
    categories: ProductCategory[] = [];
    brands: ProductBrand[] = [];
    assortments: ProductBrand[] = [];
    productMerchandise: ProductMerchandise[] = [];

    badges: Badge[] = [];

    filterForSummary: FilterForSummary = new FilterForSummary(false);

    routeParamsSubscription: Subscription;
    filterSummarySubscription: Subscription;

    openItemCodeForView: string;
    public RouteParams = RouteParams;
    public bucketBaseUrl = Constants.amazonS3Bucket;

    constructor(private route: ActivatedRoute, public shopService: ShopService, public router: Router, private utilityService: UtilityService, public loadingService: LoadingService) {
        this.getClassification();
    }

    public productCategories: ProductCategory[];

    async ngOnInit(): Promise<void> {
        this.routeParamsSubscription = this.route.queryParams.subscribe((queryParams) => {
            this.selectedMerchCode = queryParams[RouteParams.merch];
            this.selectedBrandCode = queryParams[RouteParams.brand];
            this.selectedBadgesIds = queryParams[RouteParams.badgesId];
            if (this.closeCategoryListEvent)
                this.closeCategoryListEvent.emit();
        });
        this.filterSummarySubscription = this.shopService.filterNamesForSummary.subscribe((data: FilterForSummary) => {
            this.filterForSummary = data;
        });
        this.badges = await this.shopService.getSearchableBadges();
    }

    getClassification(){
        this.productClassification = [];
        this.utilityService.enumToArray(ClassificationType).forEach(classification => {
                this.shopService.getClassification(classification, (response: ProductClassification[]) => {
                    if(response.length > 0)
                        this.productClassification.push(
                            {
                                heading: `SHOP.${classification.toUpperCase()}`,
                                queryParam: this.setQuerryParamForClassification(classification),
                                classification: response
                            }
                        );
                    this.getAllParentCategoriesForMerch();
                }
            )})
    }

    private setQuerryParamForClassification(classification: ClassificationType) {
        // if(classification == ClassificationType.product_Assortments)
        //     return 'assortment';
        // if (classification == ClassificationType.product_Brands)
        //     return 'brand';
        // else if (classification == ClassificationType.product_Categories)
        //     return 'category';
        if (classification == ClassificationType.product_Merchandise)
            return RouteParams.merch;
        else if (classification == 'search')
            return RouteParams.search
        else if (classification == 'badge')
            return [RouteParams.badgesId, RouteParams.badgeNames]
    }

    ngOnDestroy(){
        if(this.routeParamsSubscription)
            this.routeParamsSubscription.unsubscribe();
        if(this.filterSummarySubscription){
            this.filterSummarySubscription.unsubscribe();
            this.filterForSummary = new FilterForSummary(false);
        }
    }

    navigateTo(queryParams: any, value: string, categoryName?: string) {
        let query = { ...this.route.snapshot.queryParams };
        if (Array.isArray(queryParams)) {
            queryParams.forEach(qp => {
                query[qp] = value
            })
        } else {
            query[queryParams] = value;
            query.p = null;
        }

        this.router.navigate([], { queryParams: query });
        if (this.closeCategoryListEvent)
            this.closeCategoryListEvent.emit();
    }

    getRouterLink(queryParams: any, categoryName?: string) {
        let urlWithoutQueryParams = this.router.parseUrl(this.router.url).root.children['primary']?.segments.map(it => it.path);
        if(!urlWithoutQueryParams) return;
        urlWithoutQueryParams.includes(RouteParams.mainMerch) ? urlWithoutQueryParams.splice(urlWithoutQueryParams.length - 2, 2) : null;
        urlWithoutQueryParams.includes(RouteParams.product) ? urlWithoutQueryParams.splice(urlWithoutQueryParams.length - 3, 3) : null;
        return `/${urlWithoutQueryParams.join('/')}${queryParams == RouteParams.merch ? `/${RouteParams.mainMerch}/${categoryName.replace(/ /g, '-').toLowerCase()}` : ''}`;
    }

    getBadgeRouterLink() {
        return '/'+this.router.parseUrl(this.router.url).root.children['primary']?.segments.map(it => it.path).join('/');
    }

    getQueryParams(queryParams: any, value: string) {
        let query = { ...this.route.snapshot.queryParams };
        query[queryParams] = value;
        query.p = null;
        return query
    }

    generateQueryParamsBadge(badge: Badge) {
        let query = { ...this.route.snapshot.queryParams };

        if(query[RouteParams.badgesId]) {
            query[RouteParams.badgeNames] = query[RouteParams.badgeNames]+','+badge.name.replace(/ /g, '-').toLowerCase();
            query[RouteParams.badgesId] = query[RouteParams.badgesId]+','+badge.id;
        }
        else {
            query[RouteParams.badgeNames] = badge.name.replace(/ /g, '-').toLowerCase();
            query[RouteParams.badgesId] = badge.id;
        }

        return query;
    }

    getAllParentCategoriesForMerch(){
        this.selectedProductMerchandise = this.shopService.findParents(this.selectedMerchCode);
        if(this.selectedProductMerchandise != undefined && this.selectedProductMerchandise.parentId != "-1"){
            var parentMerch = this.selectedProductMerchandise;
            var codes: string[] = [];
            while(parentMerch != null){
               codes.unshift(parentMerch.code);
               parentMerch = parentMerch.parent;
               
            }
            this.parentCodes = codes;
        }
    }

    cancelFilterFor(classificationType){
        this.navigateTo(this.setQuerryParamForClassification(classificationType), null);
    }

    leaveOnlyThisItemOpen(itemCode: string){
        this.openItemCodeForView = itemCode;
    }
}