
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './layout/layout.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './routes/home/home.component';
import { ProductCategoryComponent } from './shared/product-category/product-category.component';
import { MobileFooterNavComponent } from './shared/mobile-footer-nav/mobile-footer-nav.component';
import { SharedModule } from './shared/shared.module';
import { RegistrationConfirmationComponent } from './routes/registration-confirmation/registration-confirmation.component';
import { NotFoundComponent } from './routes/not-found/not-found.component';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { SitemapComponent } from './routes/sitemap/sitemap.component';
import { PixelModule } from 'ngx-pixel';

@NgModule({
  declarations: [
    HomeComponent,
    ProductCategoryComponent,
    AppComponent,
    MobileFooterNavComponent,
    RegistrationConfirmationComponent,
    NotFoundComponent,
    SitemapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    LayoutModule,
    SharedModule.forRoot(),
    ToastrModule.forRoot({
        maxOpened: 5,
        positionClass: 'toast-bottom-right',
        preventDuplicates: true
    }),
    LoadingBarHttpClientModule,
    NgxGoogleAnalyticsModule.forRoot(environment.trackingGoogleCode),
    NgxGoogleAnalyticsRouterModule,
    PixelModule.forRoot({ enabled: true, pixelId: environment.fbPixelId }) // Facebook Pixel
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }) po defaultu je za prod samo
    // ServiceWorkerModule.register('ngsw-worker.js')
  ],
  providers: [{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } }],
  bootstrap: [AppComponent]
})
export class AppModule { }
