<div #newsElement class="container-fluid p-0 px-sm-3">
    <div *ngIf="news; else noNewsTemplate" class="news-wrap p-0 p-sm-4">
        <!-- <i *ngIf="!newsType" [title]="'SHARED.BACK' | translate" (click)="utilityService.goBack()" class="fas fa-chevron-left p-1 clickable"></i>-->
        <div class="row p-md-4 bg-white">
            <h2 class="text-center my-4 text-main col-12" [innerHTML]="news?.title"></h2>
            <ng-container *ngFor="let item of news?.newsParagraphs">
                <div class="col-12">
                    <div class="row">
                        <p [ngClass]="item.imagePath ? 'col-lg-9' : 'col-12'" *ngIf="item.paragraph" class="my-auto" [innerHTML]="item.paragraph | safeHtml"></p>                    
                        <div [ngClass]="item.paragraph ? 'col-8 col-lg-3' : 'col-12'" *ngIf="item.imagePath" class="d-flex m-auto">
                            <img class="img-fluid d-block m-auto" [alt]="'Amko shop' + news?.title" src="{{bucketBaseUrl}}{{item.imagePath}}" alt="">
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <ng-template #noNewsTemplate>
        <div class="row">
            <div class="col-12 text-center">
                No news
            </div>
        </div>
    </ng-template>
</div>