import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';

// import { loadMessages, locale } from 'devextreme/localization';
// import { AuthService } from './auth.service';

@Injectable()
export class TranslatorService {
    public calendarLocale: any;

    public selectedLanguage: string;
    private readonly defaultLanguage: string = 'bs-BA';
    private readonly selectedLanguageKey: string = 'selected_language'; 

    constructor(
        private translateService: TranslateService) {

        this.useLanguage(this.defaultLanguage);
        // if (!translateService.getDefaultLang())
        //     translateService.setDefaultLang(this.defaultLanguage);
        // else
    }

    public static parseLocale(tokenLanguage: string): string {
        switch (tokenLanguage) {
            case 'CROATIAN':
                return 'hr-HR';
            case 'SERBIAN':
                return 'sr-RS';
            case 'ENGLISH':
                return 'en-US';
            case 'BOSNIAN':
            default:
                return 'bs-BA';
        }
    };

    // public useTokenLanguage(tokenLanguage: string) {
    //     this.useLanguage(TranslatorService.parseLocale(tokenLanguage));
    // }

    public useLanguage(language: string): void {
        this.selectedLanguage = language;
        this.calendarLocale = calendarLocales[this.selectedLanguage];
        localStorage.setItem(this.selectedLanguageKey, this.selectedLanguage);
        this.translateService.use(this.selectedLanguage);
    }
}

export type CalendarLocale = {
    firstDayOfWeek: number;
    dayNames: string[];
    dayNamesShort: string[];
    dayNamesMin: string[];
    monthNames: string[];
    monthNamesShort: string[];
    today: string;
    clear: string;
};

const paginatorRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 od ${length}`; }
    
    length = Math.max(length, 0);
  
    const startIndex = page * pageSize;
  
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
  
    return `${startIndex + 1} - ${endIndex} od ${length}`;
  }

export function getPaginatorTranslation(){
    const paginatorIntl = new MatPaginatorIntl();
    
    paginatorIntl.itemsPerPageLabel = 'Stavki na stranici:';
    paginatorIntl.nextPageLabel = 'Sljedeća stranica';
    paginatorIntl.previousPageLabel = 'Prethodna stranica';
    paginatorIntl.getRangeLabel = paginatorRangeLabel;
    
    return paginatorIntl;
    
}

const calendarLocales: { 'bs-BA': CalendarLocale, 'hr-HR': CalendarLocale, 'sr-RS': CalendarLocale, 'en-US': CalendarLocale } = {
    'bs-BA': {
        firstDayOfWeek:  1,
        dayNames:        ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
        dayNamesShort:   ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
        dayNamesMin:     ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
        monthNames:      ['Januar', 'Feburar', 'Mart', 'April', 'Maj', 'Juni', 'Juli', 'August', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        today:           'Danas',
        clear:           'Očisti'
    },
    'hr-HR': {
        firstDayOfWeek:  1,
        dayNames:        ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
        dayNamesShort:   ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub'],
        dayNamesMin:     ['Ne', 'Po', 'Ut', 'Sr', 'Če', 'Pe', 'Su'],
        monthNames:      ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj', 'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'],
        monthNamesShort: ['Sij', 'Velj', 'Ožu', 'Tra', 'Svi', 'Lip', 'Srp', 'Kol', 'Ruj', 'Lis', 'Stu', 'Pro'],
        today:           'Danas',
        clear:           'Očisti'
    },
    'sr-RS': {
        firstDayOfWeek:  1,
        dayNames:        ['Недеља', 'Понедељак', 'Уторак', 'Среда', 'Четвртак', 'Петак', 'Субота'],
        dayNamesShort:   ['Нед', 'Пон', 'Уто', 'Сре', 'Чет', 'Пет', 'Суб'],
        dayNamesMin:     ['Не', 'По', 'Ут', 'Ср', 'Че', 'Пе', 'Су'],
        monthNames:      ['Јануар', 'Фебруар', 'Март', 'Април', 'Мај', 'Јуни', 'Јули', 'Аугуст', 'Септембар', 'Октобар', 'Новембар', 'Децембар'],
        monthNamesShort: ['Јан', 'Феб', 'Мар', 'Апр', 'Мај', 'Јун', 'Јул', 'Ауг', 'Сеп', 'Окт', 'Нов', 'Дец'],
        today:           'Данас',
        clear:           'Очисти'
    },
    'en-US': {
        firstDayOfWeek:  1,
        dayNames:        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort:   ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin:     ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames:      ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today:           'Today',
        clear:           'Clear'
    }
};
