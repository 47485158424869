import { BaseModelActive } from 'src/app/shared/base-component/base.models';

export class Banner extends BaseModelActive {
    title?: string;
    description?: string;
    buttonText?: string;
    url?: string;
    type?: BannerType | string;
    image?: string;
    icon?: string;
    dateFrom?: Date;
    dateTo?: Date;
    scope?: BannerScope;
    scopeId?: string;
    subtitle?: string;

    // helpers
    scopeUser?: string;
    scopeUserType?: string;
}

export class Banners {
    type?: BannerType;
    banners: Banner[];
}

export enum BannerType { 
    HOME_CAROUSEL = 'HOME_CAROUSEL',
    HOME_FIXED = 'HOME_FIXED',
    POPUP = 'POPUP'
}

export enum BannerScope {
    USER_TYPE, SINGLE_USER
}