import { BaseModel, DayOfWeek } from 'src/app/shared/base-component/base.models';
import { Municipality } from 'src/app/shared/shared.models';

export class Address extends BaseModel {
    municipality?: Municipality | string;
    name?: string;
    zipCode?: string;
    street?: string;
    phoneNumber?: string;
    daysOfWeek? : DayOfWeek;
    description?: string;
    lastUsed?: Date;

    // for customerOrder
    shippingEmail?: string;
}
