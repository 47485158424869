<div data-interval="5000" data-pause="hover" id="slider" [ngClass]="type == 'HOME_SLIDER' ? 'width-50vw' : 'max-width'" class="carousel slide caption-animate overflow-hidden showControls{{showControls}}" data-ride="carousel">
    <div class="shortAnim">
        <div class="carousel-inner" [ngClass]="type == 'PRODUCTS_SLIDER' ? 'fix-width-for-products-slider' : ' '">
            <div *ngFor="let carouselSlide of carouselSlides; let j = index;" class="carousel-item" [ngClass]="{ 'active': j == 0 }">
                <ng-container *ngIf="type == 'HOME_SLIDER'">
                    <img (click)="openLink(carouselSlide.url)"
                        [src]="carouselSlide.image"
                        class="d-block w-100 clickable"
                        [alt]="'Amko shop slider ' + carouselSlide?.title"
                    >
                    <div [ngClass]="!showControls ? 'second ' + animationType : animationType + ' carousel-caption'" class="carousel-custom text-left d-block animate__animated">
                        <div (click)="openLink(carouselSlide.url)" class="text-carousel mt-4 clickable">
                            <div>
                                <h1 class="title" [innerHTML]='carouselSlide.title'></h1>
                                <span class="description">{{carouselSlide.description}}</span>
                                <button *ngIf="carouselSlide?.buttonText" [innerHTML]='carouselSlide.buttonText' (click)="openLink(carouselSlide.url)" class="red-black-btn responsive px-5 no-wrap mt-2 mt-sm-4 d-block mb-2 mb-sm-0"></button>
                                <img (click)="openLink(carouselSlide.url)" class="carousel-icon clickable shortAnim" *ngIf="carouselSlide.icon && !showControls" src="{{ bucketBaseUrl }}{{ carouselSlide.icon }}" [alt]="'Amko shop slider ' + carouselSlide?.title">
                                <span *ngIf="carouselSlide.subtitle && showControls" class="sub-title" [innerHTML]='carouselSlide.subtitle'></span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="type == 'PRODUCTS_SLIDER'">
                    <div *ngFor="let offeredProduct of offeredProducts; let i = index;">
                        <div class="product-wrap">
                            <app-product-card *ngIf="i >= productsInSlide * j  && i < productsInSlide * ( j+1 ) && j == 0" [offeredProduct]="offeredProduct" [routePath]="'../../'"></app-product-card>
                            <app-product-card *ngIf="i >= productsInSlide * j  && i < productsInSlide * ( j+1 ) && j > 0" [offeredProduct]="offeredProduct" [routePath]="'../../'"></app-product-card>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="showControls" 
        [ngClass]="{
            'product-position-controls': type == 'PRODUCTS_SLIDER',
            'd-none' : type == 'HOME_SLIDER' && carouselSlides?.length <= 1
        }" 
        class="controls">
        <a class="carousel-control-prev" (click)='prev()' role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" (click)='next()' role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</div>