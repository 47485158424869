
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { Image } from 'src/app/shared/base-component/base.models';
import { BasketService } from 'src/app/shared/basket.service';
import { OfferedProduct } from '../shop.models';
import { UtilityService } from 'src/app/core/utility.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
    })
export class ProductCardComponent implements OnInit {
    @Input('offeredProduct') offeredProduct: OfferedProduct;
    @Input('routePath') routePath: String;

    public bucketBaseUrl = Constants.amazonS3Bucket;
    public quantity: number = 1;
    public defaultImg: string = '../../../../assets/img/spinner.gif';
    public displayImage: Image = new Image();

    constructor(public basketService: BasketService, public route: ActivatedRoute, private router: Router, public utilityService: UtilityService, private $gaService: GoogleAnalyticsService) { }

    ngOnInit(): void {
        if(this.offeredProduct.product != null && this.offeredProduct.product.images != null && this.offeredProduct.product.images.length > 1){
            this.displayImage = this.offeredProduct.product.images.filter(x=>x.isMain)[0];
        }else if(this.offeredProduct.product != null && this.offeredProduct.product.images != null && this.offeredProduct.product.images.findIndex(x=>x.isMain) > -1){
            this.displayImage = this.offeredProduct.product.images.filter(x=>x.isMain)[0];
        }else if(this.offeredProduct.product != null && this.offeredProduct.product.images != null && this.offeredProduct.product.images.length > 0){
            this.displayImage = this.offeredProduct.product.images[0];
        }
    }

    addCart(){
        this.basketService.addOrUpdateProduct(this.offeredProduct, null, this.quantity);
        this.quantity = 1;
    }

    onKey(){
        this.quantity = this.basketService.checkQuantityBasedOnIncrement(this.quantity, this.offeredProduct.product.unitOfMeasurement.increment);
    }

    goToProduct(){
        this.$gaService.event('openArticleDetails', 'openArticleDetailsOnGrid', this.offeredProduct.product.name);
    }

    displayAnotherImages(){
        if(this.offeredProduct.product != null && this.offeredProduct.product.images != null && this.offeredProduct.product.images.length > 1){
            //choose random picture
            let index = Math.floor(Math.random() * this.offeredProduct.product.images.filter(x=>!x.isMain).length);
            this.displayImage = this.offeredProduct.product.images.filter(x=>!x.isMain)[index];
        }

        if(this.offeredProduct.product != null && this.offeredProduct.product.images != null && this.offeredProduct.product.images.length > 0 && this.offeredProduct.product.images.filter(x=>x.isMain)[0].isMain){
            this.defaultImg = this.bucketBaseUrl + this.offeredProduct.product.images.filter(x=>x.isMain)[0].path;
        }
    }

    disaplayMainImage(){
        if(this.offeredProduct.product != null && this.offeredProduct.product.images != null && this.offeredProduct.product.images.length > 1){
            this.displayImage = this.offeredProduct.product.images.filter(x=>x.isMain)[0];
        }        
    }

}
