import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoadingService } from './core/loading.service';
import { SeoService } from './core/seo.service';
import { PixelService } from 'ngx-pixel';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = event.target.innerWidth;
    }

    innerWidth: number = window.innerWidth;
    public loading: boolean = true;

    constructor(public loadingService: LoadingService, private seo: SeoService, private pixelService: PixelService) {
        // updates.available.subscribe(event => { // if update, it will refresh and grab new service worker and instance of app
        //     console.log(event);
        //     alert('version is updating' + event);
        //     updates.activateUpdate().then(()=> document.location.reload());
        // }); ovo je za PWA

        // Facebook Pixel track pageview
        pixelService.track('PageView');
        
        let currentVersion = localStorage.getItem('wsh_appVersion');
        if (currentVersion != environment.appVersion) {
            localStorage.setItem('wsh_appVersion', environment.appVersion);
            this.forceReload();
        }
        window.onload = (event) => {
            this.loading = false;
        };
    }

    forceReload() {
        window.location.reload();
    }
}