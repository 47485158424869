<ng-container *ngIf="loading; else loadedTemplate">
    <div class="circle-loader general-loader"></div>
</ng-container>
<ng-template #loadedTemplate>
    <div *ngIf="!loading" [@inOutAnimation]>
        <div class="anim">
            <div class="position-fixed">
                <div [ngStyle]="{'transform': 'translate3d(0px, -'+translateWithScroll+'px, 0px'}" class="row carousel-wrap animate__fadeIn animate__animated">
                    <div class="col-md-6 col-12 p-0">
                        <app-carousel [carouselSlides]="banners[0].banners" [showControls]="true" [animationType]="'animate__fadeInDown'" [type]="'HOME_SLIDER'"></app-carousel>
                    </div>
                    <div class="col-md-6 col-12 p-0 mt-2 mt-md-0">
                        <app-carousel [carouselSlides]="banners[1].banners" [animationType]="'animate__fadeInLeft'" [type]="'HOME_SLIDER'"></app-carousel>
                    </div>
                </div>
            </div>
            <div class="hidden">
                <!-- Ovaj mora biti tu da zauzme isto mjesta kao onaj gore koji je isti samo sto je position fixed -->
                <div class="row carousel-wrap animate__fadeIn animate__animated">
                    <div class="col-md-6 col-12 p-0">
                        <app-carousel [carouselSlides]="banners[0].banners" [animationType]="'animate__fadeInDown'" [showControls]="true" [type]="'HOME_SLIDER'"></app-carousel>
                    </div>
                    <div class="col-md-6 col-12 p-0 mt-2 mt-md-0">
                        <app-carousel [carouselSlides]="banners[1].banners" [animationType]="'animate__fadeInLeft'" [type]="'HOME_SLIDER'"></app-carousel>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="dark-bg-position mt-5 mt-lg-2">
            <div class="fix-position">
                <div class="row mb-5 mx-auto">
                    <div class="col-lg-3 col-6 mt-3 p-0">
                        <div [routerLink]="['/'+RouteParams.news+'/'+RouteParams.delivery ]" class="dashboard-icon-wrap ml-lg-0 clickable outline-none">
                            <img class="img-fluid shortAnim clickable d-block m-auto pt-2" alt="Amko shop besplatna dostava slika" src="../../../assets/img/dashboard-icons/01-slika.png">
                            <p>{{ 'DASHBOARD.ICONS.FREE_SHIPPING' | translate }}</p>
                            <hr>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 mt-3 p-0">
                        <div [routerLink]="['/'+RouteParams.news+'/'+RouteParams.buyOnline ]" class="dashboard-icon-wrap clickable outline-none">
                            <img class="img-fluid shortAnim clickable d-block m-auto pt-2" alt="Amko shop prednosti internet kupovine slika" src="../../../assets/img/dashboard-icons/02-slika.png">
                            <p>{{ 'DASHBOARD.ICONS.ADVANTAGE' | translate }}</p>
                            <hr>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 mt-3 p-0">
                        <div [routerLink]="['/'+RouteParams.news+'/'+RouteParams.forYou ]" class="dashboard-icon-wrap clickable outline-none">
                            <img class="img-fluid shortAnim clickable d-block m-auto pt-2" alt="Amko shop uvijek tu za Vas slika" src="../../../assets/img/dashboard-icons/03-slika.png">
                            <p>{{ 'DASHBOARD.ICONS.LOYAL' | translate }}</p>
                            <hr>
                        </div>
                    </div>
                    <div class="col-lg-3 col-6 mt-3 mx-auto p-0">
                        <div (click)="subscribeToNewsletter()" class="dashboard-icon-wrap clickable outline-none mr-lg-0">
                            <img class="img-fluid shortAnim clickable d-block m-auto pt-2" alt="Amko shop prijava na email listu slika" src="../../../assets/img/dashboard-icons/04-slika.png">
                            <p>{{ 'DASHBOARD.ICONS.NEWSLETTER' | translate }}</p>
                            <hr>
                        </div>
                    </div>
                </div>
                <hr class="bg-white opacity50 my-0 my-sm-3">
                <div class="row m-auto">
                    <div class="col-12 my-4 text-center text-white text-uppercase">
                        <h2 class="text-xl mobile-xl-font">{{ 'SHOP.PRODUCT_CATEGORIES' | translate }}</h2>
                    </div>
                    <div *ngFor="let category of productCategories" class="col-lg-3 col-md-3 col-sm-4 col-6 px-0 px-sm-3">
                        <product-category [name]="category.name" [imageUrl]="category.imageUrl" [categoryUrl]="category.categoryUrl"></product-category>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<app-popup-modal (closePopupModalEvent)="closeModalAndCheckForUnviewedPopups()" *ngIf="popupInfo" [popupInfo]='popupInfo'></app-popup-modal>
<!-- <app-forgot-password></app-forgot-password> -->