import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './auth.service';
import { AlertService } from './alert.service';
import { LoadingService } from './loading.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root'
})

export class RequestService {
    
    private lastErrorTimeStamp: Date = new Date();
    private lastErrorMessage: string = '';

    public constructor(
        private authService: AuthService,
        private http: HttpClient,
        private loadingService: LoadingService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private router: Router,
        private $gaService: GoogleAnalyticsService,
        private dialogService: DialogService
    ) { }

    public createRequest(httpRequestType: HttpRequestType, url: string, body: any, headers: HttpHeaders = null, onSuccess: Function = null, onFail: Function = null): void {
        let requestType = this.determineRequestType(httpRequestType);
            
        setTimeout(() => {
            this.loadingService.showLoader();
        });

        this.authService.refreshTokenIfNeeded()
            .pipe(finalize(() =>
                    requestType(url, body, { headers: headers || this.authService.authHeaders, body: body })
                    .pipe(finalize(() =>
                        setTimeout(() => {
                            this.loadingService.hideLoader();
                        })
                    ))
                    .subscribe(
                        (response: any) => {
                            if (onSuccess != null) {
                                if (response == null)
                                    onSuccess();
                                else {
                                    onSuccess(response);
                                }
                            }
                        },
                        (error: any) => {
                            // if request has invalid token or refreshToken is expired, repeat same request after getting token for public user
                            if(error.status == 401 && error.error && (error.error.error == 'invalid_token' || error.error.error == "unauthorized"))
                                this.authService.login(null, null, true).subscribe(() => {
                                    this.createRequest(httpRequestType, url, body, headers, onSuccess, onFail)
                                });
                            else
                                onFail != null ? onFail(error) : this.handleError(error) 
                        }
                    )
                )
            ).subscribe();
    }

    public createRequestAsPromise(httpRequestType: HttpRequestType, url: string, body: any, headers: HttpHeaders = null, onSuccess: Function = null, onFail: Function = null): Promise<Response> {
        let requestType = this.determineRequestType(httpRequestType);

        setTimeout(() => {
            this.loadingService.showLoader();
        });

        return this.authService.refreshTokenIfNeeded().toPromise()
            .then(() => this.executeAfterTokenRefresh(requestType, url, body, headers, onSuccess, onFail))
            .catch(() => this.executeAfterTokenRefresh(requestType, url, body, headers, onSuccess, onFail));
    }

    private executeAfterTokenRefresh(requestType: Function, url: string, body: any, headers: HttpHeaders = null, onSuccess: Function = null, onFail: Function = null): Promise<Response> {
        return requestType(url, body, { headers: headers || this.authService.authHeaders }).toPromise()
            .then((response: any) => {
                setTimeout(() => {
                    this.loadingService.hideLoader();
                });

                return onSuccess != null ? response._body == '' ? onSuccess() : onSuccess(response) : response;
            })
            .catch((error: any) => {
                setTimeout(() => {
                    this.loadingService.hideLoader();
                });

                return onFail != null ? onFail(error) : this.handleError(error);
            });
    }

    private determineRequestType(httpRequestType: HttpRequestType): Function {
        switch (httpRequestType) {
            case HttpRequestType.Get:
                return (url: string, _: any, options: any) => this.http.get(url, options);
            case HttpRequestType.Post:
                return (url: string, body: any, options: any) => this.http.post(url, body, options);
            case HttpRequestType.Put:
                return (url: string, body: any, options: any) => this.http.put(url, body, options);
            case HttpRequestType.Delete:
                return (url: string, body: any, options: any) => this.http.delete(url, options);
            case HttpRequestType.Patch:
                return (url: string, body: any, options: any) => this.http.patch(url, body, options);
        }
    }

    public handleError(error: any): void {
        let message: string = '';
        let fieldErrors: Array<any> = [];
        let now: Date = new Date();
        
        if (error.error.error == 'ShowInDialogException') {
            this.dialogService.OpenModal(error.error.error_description);
            return;
        }
        
        switch(error.status) {
            case 502:
            case 503:
            case 504:
            case 403:
                this.router.navigateByUrl('oops');
                return;
            case 404:
                if(!error.error.error_description)
                    this.router.navigateByUrl('oops');
                    return;
        }

        try {
            message = error.error.error_description || error.error.message;
            fieldErrors = error.error.field_errors || [];
        } catch (exception) {
            message = this.translateService.instant('ERRORS.GENERAL');
        }

        if (fieldErrors.length > 0)
            fieldErrors.forEach(error => { 
                this.alertService.error(error.message); 
                this.$gaService.exception(error.message)
            });
        else {
            if (message != this.lastErrorMessage || (now.getTime() - this.lastErrorTimeStamp.getTime()) > 1000){
                if(message == 'Full authentication is required to access this resource') {// don't show this error
                    this.$gaService.exception('Full authentication is required to access this resource');
                    return;
                }
                this.alertService.error(message || 'ERRORS.GENERAL');

            this.lastErrorTimeStamp = now;
            this.lastErrorMessage = message;
        }
    }
}
}
export enum HttpRequestType {
    Get, Post, Put, Delete, Patch
}