import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/core/loading.service';
import { RouteParams } from 'src/app/RouteParams';
import { CustomerOrder, CustomerOrderProduct } from 'src/app/routes/cart/cart.models';

@Component({
    selector: 'app-simple-table',
    templateUrl: './simple-table.component.html',
    styleUrls: ['./simple-table.component.scss']
})
export class SimpleTableComponent implements OnInit {
    @Input('dataSource') dataSource: any[];
    @Input('type') type: string;

    public showRebateColumn: boolean = false;
    public showSalePriceColumn: boolean = false;
    public showFinalPriceColumn: boolean = false;
    public showApprovedQuantityColumn: boolean = false;
    public RouteParams = RouteParams;

    constructor(public loadingService: LoadingService) { }

    ngOnInit(): void {
        let products: CustomerOrderProduct[]; 
        products = <CustomerOrderProduct[]>[...this.dataSource];
        products.map((p) => {
            // TODO treba li dodati kolonu discountPercentage ?
            if (p?.rebatePercentage)
                if (p?.rebatePercentage > 0)
                    this.showRebateColumn = true;
            if (p?.salePrice < p?.regularPrice)
                this.showSalePriceColumn = true;
            if (p?.salePrice != p?.regularPrice)
                this.showFinalPriceColumn = true;
            if(p.orderedQuantity != p.approvedQuantity)
                this.showApprovedQuantityColumn = true;
        });
    }

}
