import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouteParams } from 'src/app/RouteParams';
@Component({
    selector: 'product-category',
    templateUrl: './product-category.component.html',
    styleUrls: ['./product-category.component.scss']
})
export class ProductCategoryComponent implements OnInit {
    @Input('imageUrl') imageUrl: string;
    @Input('name') name: string;
    @Input('categoryUrl') categoryUrl: string;
    public RouteParams = RouteParams;
    public routerLink: string;
    public queryParams;

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void {
        this.routerLink = `./${RouteParams.shop}/${RouteParams.mainMerch}/${this.translateService.instant(this.name).replace(/ /g, '-').replace(/<br>/g, '').toLowerCase()}`;
        this.queryParams = { [RouteParams.merch]: this.categoryUrl };
    }
}