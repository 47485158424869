import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { InsideGuard, LoginAsPublicGuard, LogoutClientCustomer } from './guards';
import { RouteParams } from './RouteParams';
import { HomeComponent } from './routes/home/home.component';
import { LoginComponent } from './routes/login/login.component';
import { NotFoundComponent } from './routes/not-found/not-found.component';
import { RegistrationConfirmationComponent } from './routes/registration-confirmation/registration-confirmation.component';
import { SitemapComponent } from './routes/sitemap/sitemap.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: 'generated/sitemap',
        component: SitemapComponent,
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: 'registrations/confirm/:token',
        component: RegistrationConfirmationComponent,
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: 'forgot-password/:token/confirm',
        component: HomeComponent,
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: RouteParams.profile,
        loadChildren: () => import('../app/routes/profile/profile.module').then(m => m.ProfileModule),
        canActivate: [InsideGuard]
    },
    {
        path: RouteParams.shop,
        loadChildren: () => import('../app/routes/shop/shop.module').then(m => m.ShopModule),
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: RouteParams.register,
        loadChildren: () => import('../app/routes/registration/registration.module').then(m => m.RegistrationModule),
        canActivate: [LogoutClientCustomer, LoginAsPublicGuard]
    },
    {
        path: RouteParams.login,
        component: LoginComponent,
        canActivate: [LogoutClientCustomer, LoginAsPublicGuard]
    },
    {
        path: RouteParams.cart,
        loadChildren: () => import('../app/routes/cart/cart.module').then(m => m.CartModule),
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: RouteParams.news,
        loadChildren: () => import('../app/routes/news/news.module').then(m => m.NewsModule),
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: 'i',
        loadChildren: () => import('../app/routes/news/news.module').then(m => m.NewsModule),
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: RouteParams.footer,
        loadChildren: () => import('../app/routes/full-screen-footer/full-screen-footer.module').then(m => m.FullScreenFooterModule),
        canActivate: [LoginAsPublicGuard]
    },
    {
        path: 'error',
        component: NotFoundComponent
    },
    {
        path: 'oops',
        component: NotFoundComponent
    },
    // {
    //     path: '**',
    //     redirectTo: 'error'
    // }
];

@NgModule({
    imports: [
        RouterModule.forRoot(AppRoutes,
            {
                preloadingStrategy: PreloadAllModules,
                relativeLinkResolution: 'legacy'
            }
        )
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
