import { BaseModel } from 'src/app/shared/base-component/base.models';
// export class News {
//     title?: string;
//     subtitle?: string;
//     shortDescription?: string;
//     body?: string;
//     images?: string[];
//     active?: boolean;
//     type?: string;


// }
export class News extends BaseModel {
    title?: string;
    link?: string;
    newsParagraphs?: NewsParagraph[] = new Array<NewsParagraph>();
}

export class NewsParagraph extends BaseModel {
    paragraph?: string;
    imagePath?: string;
    priority?: number;
}
