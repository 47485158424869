<div (click)="openLink(popupInfo.url)" [ngClass]="{'clickable': popupInfo?.url}" class="popup-modal animate__slideInLeft animate__animated animate__faster" [ngStyle]="{ 'background-image': imagePathForScss }">
    <i (click)="closeModalAndSetAsViewed()" class="ti-close mt-1 pull-right clickable"></i>
    <h4 class="popup-title" *ngIf="popupInfo?.title?.length > 1"> {{ popupInfo.title }} </h4>
    <img *ngIf="popupInfo.icon" class="popup-icon" src="{{ bucketBaseUrl }}{{ popupInfo.icon }}">
    <div *ngIf="popupInfo.description" class="popup-description-container row">
        <div class="col-12 align-self-center">
            <p class="px-3 px-md-5 lead popup-description"> {{ popupInfo.description }} </p>
        </div>
    </div>
</div>
<div (click)="closeModalAndSetAsViewed()" class="close-overlay shortAnim"></div>