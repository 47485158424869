<div class="row mat-white-theme mx-3 mx-md-0">
    <div class="col-12 mx-auto">
    </div>
    <div class="col-12 mx-auto min-height-70 bg-white text-gray">
        <div class="registration-heading">
            <img alt="Amko" class="img-fluid" alt="AmkoWebShop logo" class="height-80 d-block mx-auto" src="../../../../assets/icons/amko_web_shop.png">
            <h1 class="text-center opacity80 mt-4 text-l">{{ 'REGISTRATIONS.LOGIN_MESSAGE' | translate }}</h1>
        </div>
        <div class="col-12 col-lg-6 mx-auto py-5 mb-5">
            <form (keydown.enter)="$event.preventDefault(); login()">
                <mat-form-field class="max-width">
                    <mat-label>{{ 'SHARED.EMAIL' | translate }}</mat-label>
                    <input matInput data-cy="login-email" autofocus [(ngModel)]="formData.username" [ngModelOptions]="{standalone: true}" [placeholder]="'SHARED.ENTER-HERE-EMAIL' | translate">
                </mat-form-field>
                <mat-form-field class="max-width">
                    <mat-label>{{ 'SHARED.PASSWORD' | translate }}</mat-label>
                    <input matInput data-cy="login-password" [(ngModel)]="formData.password" [ngModelOptions]="{standalone: true}" [placeholder]="'SHARED.ENTER-HERE-PASSWORD' | translate" [type]="passVisibility ? 'password' : 'text'">
                    <button (keydown.enter)="$event.preventDefault()" mat-icon-button matSuffix (click)="passVisibility = !passVisibility" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passVisibility">
                        <mat-icon>{{passVisibility ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
            </form>
            <!-- <span data-cy="passMatchError" *ngIf="passMatch" class="fs-14 text-danger">{{ 'REGISTRATIONS.NO_MATCH_PASS' | translate }}</span> -->
        </div>    
        <div class="row register-footer">
            <div class="col-12 text-sm text-center text-gray clickable">
                <a (click)="forgotPassword()">
                    {{ 'FORGOT_PASSWORD' | translate }}
                </a>
            </div>
            <div class="col-8 col-xl-4 mx-auto">
                <button [disabled]="formData.username.length < 1 && formData.password.length < 5" (click)='login()' data-cy="register" class="p-1 bg-main shortAnim text-bolder outline-none text-white my-3 max-width" mat-raised-button>
                    {{ 'REGISTRATIONS.LOGIN' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<!-- 

<div class="row p-2">
    <div class="col-12 pb-3 pt-4 mx-auto bg-main-gray-lighter">
        <i (click)="closeLoginFormEvent.emit()" class="ti-close d-block d-lg-none text-right p-1"></i>
        <div class="col-12 col-6 mx-auto">
            <form (keydown.enter)="$event.preventDefault(); login()">
                <mat-form-field class="max-width">
                    <mat-label>{{ 'SHARED.EMAIL' | translate }}</mat-label>
                    <input matInput data-cy="login-email" autofocus [(ngModel)]="formData.username" [ngModelOptions]="{standalone: true}" [placeholder]="'SHARED.ENTER-HERE-EMAIL' | translate">
                </mat-form-field>
                <mat-form-field class="max-width">
                    <mat-label>{{ 'SHARED.PASSWORD' | translate }}</mat-label>
                    <input matInput data-cy="login-password" [(ngModel)]="formData.password" [ngModelOptions]="{standalone: true}" [placeholder]="'SHARED.ENTER-HERE-PASSWORD' | translate" [type]="passVisibility ? 'password' : 'text'">
                    <button (keydown.enter)="$event.preventDefault()" mat-icon-button matSuffix (click)="passVisibility = !passVisibility" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passVisibility">
                        <mat-icon>{{passVisibility ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                  </mat-form-field>
            </form>
        </div>
        <div class="col-7 text-center mx-auto mb-2">
            <a (click)="forgotPassword()" data-cy="login-forgot-pw" class="text-gray text-sm text-gray-light-hoverable text-white-light clickable"> {{ 'FORGOT_PASSWORD' | translate }} ?</a>
            <button data-cy="login-button" [disabled]="formData.username.length < 1 && formData.password.length < 5" (click)='login()' class="py-1 mt-1 text-bold outline-none text-white max-width border" mat-button>{{ 'REGISTRATIONS.LOGIN' | translate}}</button>
        </div>
    </div>
</div> -->