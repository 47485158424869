<div class="order-preview-modal mat-white-theme border-radius-15">
    <i [title]="'SHARED.BACK' | translate" (click)="goBack()" class="fas fa-chevron-left p-1 clickable"></i>
    <div class="container-fluid korpica-modal-02">
        <div class="button-container z-index-first">
            <p class="d-md-inline fs-13 mb-0 mr-3 mt--3 text-gray text-right text-md-left" *ngIf="customerOrder?.paymentMethod?.type == [PaymentType.ON_LINE_OPA] && customerOrder?.status == [CustomerOrderStatus.NONE]">
                {{ 'OPA.PHONE_NUMBER' | translate }}: {{ customerOrder?.opaPhoneNumber }}
            </p>
            <button mat-raised-button (click)="closeModal()" class="btn">{{ 'MODAL.CLOSE' | translate }}</button>
            <button *ngIf="customerOrder?.status == [CustomerOrderStatus.NONE]" mat-raised-button [disabled]="loadingService.unfinishedRequests" (click)="submitCustomerOrder()" class="btn mx-3 bg-main text-white main-button">
                <ng-container *ngIf="customerOrder?.paymentMethod?.type != [PaymentType.ON_LINE] && customerOrder?.paymentMethod?.type != [PaymentType.ON_LINE_OPA]; else onlinePaymentTemplate">
                    {{ 'ORDER.SEND' | translate }}
                </ng-container>
                <ng-template #onlinePaymentTemplate>
                    {{ 'ORDER.PAY_AND_SEND' | translate }}
                </ng-template>
            </button>
            <button *ngIf="customerOrder?.status == [CustomerOrderStatus.PRE_SUBMITTED] && customerOrder?.paymentMethod?.type == [PaymentType.ON_LINE]" mat-raised-button [disabled]="loadingService.unfinishedRequests" (click)="afterSubmitting(customerOrder)" class="btn mx-3 bg-main text-white">
                {{ 'ORDER.REPAY' | translate }}
            </button>
        </div>
        <div class="row">
            <div class="col-12 mt-3 mt-lg-4">
                <h1 class="m-0 mb-3 mb-lg-4 mobile-sm-font text-center text-l">
                    {{ 'CART.ORDER' | translate }}
                    <ng-container *ngIf="customerOrder?.documentNumber">
                        {{'#' + customerOrder?.documentNumber}}
                    </ng-container>
                </h1>
                <h6 class="text-center lead text-danger" *ngIf="customerOrder?.status == 'CANCELED'">
                    {{ 'ORDER.CANCELED_INFO' | translate}} !
                </h6>
            </div>
            <div *ngIf="(customerOrder?.paymentMethod.type == [PaymentType.ON_LINE] || customerOrder?.paymentMethod.type == [PaymentType.ON_LINE_OPA]) && customerOrder?.status != [CustomerOrderStatus.NONE]" class="col-12">
                <div class="fs-20 text-bold mobile-sm-font text-center">
                    <span *ngIf="customerOrder?.payed">
                        <i class="ti-check"></i>
                        {{ 'SHARED.PAID' | translate }}
                    </span>
                    <span *ngIf="!customerOrder?.payed">
                        <i class="ti-close"></i>
                        {{ 'SHARED.UNPAID' | translate }}
                    </span>
                    <ng-container *ngFor="let item of customerOrder?.upcPayments; let last = last">
                        <span class="fs-13 d-block text-bolderr" *ngIf="last">
                            {{ 'SHARED.PAYMENT_MARK' | translate  }}
                            <span class="text-bolder">
                                {{ item.PurchaseDesc }}
                            </span>
                        </span>
                    </ng-container>
                </div>
            </div>
            <hr class="max-width bg-white-light">
        </div>
        <div class="row">
            <div class="col-lg-8 order-2 order-lg-1">
                <div class="row">
                    <div class="col-12 col-xl-6 px-lg-5 px-0">
                        <p class="lead">{{ 'ORDER.BUYER_DETAILS' | translate }}</p>
                        <p class="order-info"> 
                            <span class="text-bold">
                                {{ 'ORDER.SHIPPINGNAME' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.shippingFullName }}
                            </span> 
                        </p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'SHARED.ADDRESS' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.shippingAddress }}
                            </span>
                        </p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'SHARED.CITY' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.shippingMunicipality?.name }}, {{customerOrder?.shippingZipCode}}
                            </span>
                        </p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'SHARED.PHONE_NUMBER' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.shippingPhoneNumber }}
                            </span>
                        </p>
                    </div>
                    <div class="col-12 col-xl-6 px-lg-5 px-0">
                        <p class="lead">{{ 'ORDER.DELIVERY_PAYMENT' | translate }}</p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'COST_POLICY_TYPES.MEANS_OF_DELIVERY' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ 'DELIVERY_TYPES.' + customerOrder?.meansOfDelivery.type.toString().toUpperCase() | translate }} - 
                                {{ customerOrder?.meansOfDelivery.description }}
                            </span>
                        </p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'ORDER.ADDRESS_SELECTED' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.shippingAddress }}
                            </span>
                        </p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'ORDER.EXPECTED_SHIPMENT_TIME' | translate }}:
                            </span>
                            <span class="pull-right text-md mobile-xs-font">
                                {{ utilityService.formatDate(customerOrder?.distributionCalendar?.date) }}, 
                                {{ utilityService.formatTime(customerOrder?.distributionCalendar?.timeFrom) }} - 
                                {{ utilityService.formatTime(customerOrder?.distributionCalendar?.timeTo) }}
                                <!-- {{ utilityService.formatDateTime(customerOrder?.expectedShipmentTimeFrom) }} - {{ utilityService.formatDateTime(customerOrder?.expectedShipmentTimeTo) }} -->
                            </span>
                        </p>
                        <p class="order-info">
                            <span class="text-bold">
                                {{ 'ORDER.PAYMENT_METHOD' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ 'PAYMENT_TYPES.' + customerOrder?.paymentMethod.type.toUpperCase() | translate }} - 
                                {{ customerOrder?.paymentMethod.description }}
                            </span>
                        </p>
                    </div>
                    <div class="col-12 col-xl-6 mt-3 mt-xl-5 px-lg-5 px-0">
                        <p *ngIf="customerOrder?.usedPromotionalCode" class="order-info">
                            <span class="text-bold">
                                {{ 'ORDER.USED_PROMOTIONAL_CODE' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.usedPromotionalCode }}
                            </span>
                        </p>
                        <p *ngIf="customerOrder?.customerOrderGiftPackage" class="order-info">
                            <span class="text-bold">
                                {{ 'ORDER.GIFT_PACKAGE_USED' | translate }}
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.customerOrderGiftPackage.name }}
                                <span class="fs-10">
                                    ( {{ customerOrder?.customerOrderGiftPackage.description }} )
                                </span> 
                            </span>
                        </p>
                        <p *ngIf="customerOrder?.note" class="order-info">
                            <span class="text-bold">
                                {{ 'SHARED.NOTE' | translate }}:
                            </span>
                            <span class="pull-right">
                                {{ customerOrder?.note }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 order-1 order-lg-2 px-lg-5 px-0 bg-dirty-white border-radius-15 p-2">
                <p class="lead mt-2 mt-xl-3">{{ 'CART.PRICE_DETAILS' | translate }}</p>
                <p class="order-info">
                    <!-- regularna cijena iz kataloga -->
                    <span class="text-bold">
                        {{ 'ORDER.REGULAR_VALUE' | translate }}:
                    </span>
                    <span class="pull-right">
                        {{ customerOrder?.regularValue | number:'1.2-2'}} KM
                    </span>
                </p>
                <p class="order-info">
                    <!-- vrijednost robe koja ulazi u visinu narudjbe -->
                    <span class="text-bold">
                        {{ 'ORDER.DISCOUNT_VALUE' | translate }}:
                    </span>
                    <span class="pull-right">
                        {{ customerOrder?.regularValue - customerOrder?.saleValue | number:'1.2-2'}} KM
                    </span>
                </p>
                <p class="order-info">
                    <!-- vrijednost regularValue-popust od discounta ( nije od rebate-a ) -->
                    <span class="text-bold">
                        {{ 'ORDER.SALE_VALUE' | translate }}:
                    </span>
                    <span class="pull-right">
                        {{ customerOrder?.saleValue | number:'1.2-2'}} KM
                    </span>
                </p>
                <p class="order-info">
                    <!-- osnovni (dodatni) rabat -->
                    <span class="text-bold">
                        {{ 'ORDER.REBATE_VALUE_PROMO' | translate }}:
                    </span>
                    <span class="pull-right">
                        {{ customerOrder?.rebateValue | number:'1.2-2'}} KM
                    </span>
                </p>
                <p class="order-info">
                    <!-- suma svih troskova -->
                    <span class="text-bold">
                        {{ 'ORDER.INVOICE_VALUE' | translate }}:
                    </span>
                    <span class="pull-right">
                        {{ customerOrder?.invoiceValue | number:'1.2-2'}} KM
                    </span>
                </p>
                <ng-container *ngIf="customerOrder?.costs">
                    <p *ngFor="let cost of customerOrder?.costs" class="order-info">
                        <span class="text-bold">
                            {{ 'COST_POLICY_TYPES.'+ cost.costPolicy.type | translate }}:
                            <mat-icon [matTooltip]="'ORDER.COST_EXPLAIN' | translate: {description: cost.costPolicy?.description, valueFrom: cost.costPolicy?.orderValueFrom, valueTo: cost.costPolicy?.orderValueTo, currency: 'KM'}" class="clickable fs-19">info</mat-icon>
                        </span>
                        <span class="pull-right">
                            {{ cost.value | number:'1.2-2'}} KM
                        </span>
                    </p>
                </ng-container>
                <p class="order-info border-top font-weight-bold mt-4">
                    <!-- suma svih troskova -->
                    <span class="text-bold">
                        {{ 'ORDER.TOTAL_VALUE' | translate }}:
                    </span>
                    <span class="pull-right">
                        {{ customerOrder?.totalValue | number:'1.2-2'}} KM
                    </span>
                </p>
            </div>
        </div> 
        <div class="row">
            <div class="col-12">
                <hr class="max-width bg-white-light">
            </div>
            <div *ngIf="customerOrder?.customerOrderProducts" class="p-0 px-lg-2 col-12">
            <!-- <div class="p-0 px-lg-2" [ngClass]="!customerOrder?.costs ? 'col-12' : 'col-lg-8'"> -->
                <h6>{{ 'SHARED.PRODUCTS' | translate }}</h6>
                <app-simple-table [type]="'products'" [dataSource]="customerOrder?.customerOrderProducts"></app-simple-table>
            </div>
            <div *ngIf="customerOrder?.costs" class="col-12 mt-3 mt-lg-0 p-0 px-lg-2">
            <!-- <div *ngIf="customerOrder?.costs" class="col-lg-4 mt-3 mt-lg-0 p-0 px-lg-2"> -->
                <h6 >{{ 'SHARED.EXPENSES' | translate }}</h6>
                <app-simple-table [type]="'costs'" [dataSource]="customerOrder?.costs"></app-simple-table>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{'show': showOPAModal}" class="opa-modal animated-container">
    <div class="row">
        <div class="col-12">
            <h6>{{ OPAmodalTranslateKey | translate }}</h6>
        </div>
        <div class="col-12 mt-3">
            <div *ngIf="showOPALoader" class="circle-loader opa-modal-loader"></div>
        </div>
    </div>
</div>
<div [ngClass]="{'z-index-up': showOPAModal}" (click)="closeModal()" class="close-overlay-darker shortAnim"></div>