import { environment } from 'src/environments/environment';

export class Constants {
    public static readonly contextPath = '/wsh/api/v1';
    public static readonly amazonS3Bucket = 'https://eurobit-wsh.s3.eu-west-1.amazonaws.com' + (environment.production ? '/prod' : '/testing');
    public static readonly amkoCompanyId = 'ecb09dd0-fb70-46bd-8838-d49011285f90';
    public static readonly amkoBasketName = 'amko_basket';
    public static readonly publicUser = 'wshpublicclient';
    public static readonly publicPw = 's3cr3t';
    public static readonly newsLetterApi = 'https://www.amko.ba';
    public static readonly termsCookiesStorageKey = 'cookies_accepted';
}

export class SearchOperations {
    public static readonly EQUALITY = '::';
    public static readonly NEGATION = '!!';

    public static readonly GREATER_THAN = '>>';
    public static readonly GREATER_THAN_OR_EQUAL = '~:';
    public static readonly LESS_THAN = '<<';
    public static readonly LESS_THAN_OR_EQUAL = ':~';

    public static readonly LENGTH = '~;';
    public static readonly LIKE = '~~';
    public static readonly NOT_LIKE = '~!';
    public static readonly START_WITH = '~<';
    public static readonly END_WITH = '~>';

    public static readonly IN = '><';

    public static readonly SPLIT_JOIN_OPERATOR = '__';
    public static readonly SPLIT_SINGLE_OPERATION = ';;';

    public static readonly LEFT_PARENTHESES_READABLE = '((';
    public static readonly RIGHT_PARENTHESES_READABLE = '))';

    public static readonly KEY_AND = '-AND-';
    public static readonly KEY_OR = '-OR-';
    public static readonly KEY_NULL = 'NULL';
    public static readonly KEY_SEARCH = 'search';
}

export class CartTime {
    public static readonly waitingTime = 1000;
    public static readonly waitingTimeShort = 500;
}

