import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
    repeatPassword: string = '';
    passMatch: boolean = true;
    passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    formData: FormGroup = this.formBuilder.group({
        'password': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(60), Validators.pattern(this.passwordRegex)]]
    })

    constructor(private _mdr: MatDialogRef<ForgotPasswordComponent>, @Inject(MAT_DIALOG_DATA) data, private formBuilder: FormBuilder) { }

    closeDialog() {
        this._mdr.close(null);
    }

    confirmDialog() {
        this.passwordCheck();
        if (!this.passMatch) return;
        this._mdr.close(this.formData.controls['password'].value);
    }

    passwordCheck() {
        this.passMatch = this.formData.controls['password'].value == this.repeatPassword;
    }
}
