<div>
    <div class="items-wrapper p-3 px-1">
        <i (click)="closeHeaderCartItemsEvent.emit()" class="ti-close clickable p-1 text-right d-block"></i>
        <div class="row py-2 border-bottom" *ngFor="let cartProduct of cartProducts">
            <div class="col-3 p-0 text-center">
                <i class="fas fa-times remove-from-cart clickable mr-1" (click)="basketService.removeProduct(cartProduct?.id)"></i>
                <ng-container *ngIf="cartProduct?.product?.images; else noImgTemplate">
                    <img *ngIf="cartProduct?.product?.images[0]; else noImgTemplate" src="{{ bucketBaseUrl }}{{ cartProduct?.product.images[0].path }}" class="max-height-50" [alt]="cartProduct?.product?.name">
                </ng-container>
                <ng-template #noImgTemplate>
                    <img src="../../../../assets/img/no_photo.PNG" alt="Amko shop artikal bez slike" class="max-height-50" alt="">
                </ng-template>
            </div>
            <div class="col-6 m-auto fs-14">
                <div class="name text-bold two-text-line">{{cartProduct?.product?.name}}</div>
                <div class="text-gray fs-11">
                    <i class="fa fa-xs fa-minus clickable mx-1" (click)='updateQuantity(cartProduct, -cartProduct.increment)'></i>
                        {{ cartProduct?.quantity }} 
                    <i class="fa fa-xs fa-plus clickable mx-1" (click)='updateQuantity(cartProduct, cartProduct.increment)'></i>
                    {{ cartProduct.unitOfMeasurementCode }} x {{ cartProduct?.price | number:'1.2-2' }} KM
                </div>
            </div>
            <!-- TODO valuta -->
            <div class="col-3 text-main text-right text-bolder m-auto fs-14">
                {{ cartProduct?.quantity * cartProduct?.price | number:'1.2-2'}} KM
            </div>
        </div>
    </div>
    <div class="row p-3">
        <div class="col-6 text-left">
            <span class="fs-14 ml-1">{{cartProducts?.length}} {{ 'CART.PIECES' | translate }}</span>
        </div>
        <div class="col-6 text-right">
            <span class="text-main text-bolder mr-1">{{orderTotal | number: '1.2-2'}} KM</span>
        </div>
        <button [routerLink]="'/'+RouteParams.cart" class="col-10 mx-auto mt-3 outline-none text-bolder text-white bg-green" mat-raised-button>
            {{ 'CART.OPEN' | translate }}
        </button>
    </div>
</div>