import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { inOutAnimation } from '../animations';
import { TpcService } from './tpc.service';

@Component({
    selector: 'app-terms-policy-cookies',
    templateUrl: './terms-policy-cookies.component.html',
    styleUrls: ['./terms-policy-cookies.component.scss'],
    animations: [ inOutAnimation ]
})
export class TermsPolicyCookiesComponent implements OnInit {

    constructor(public tpcService: TpcService) { }

    ngOnInit(): void {
        if (localStorage.getItem(Constants.termsCookiesStorageKey)) 
            this.tpcService.showCookiesConsent = false;
        else
            setTimeout(() => {
                this.tpcService.showCookiesConsent = true;
            }, 10000);
    }

    public accept(): void {
        localStorage.setItem(Constants.termsCookiesStorageKey, 'true');
        this.tpcService.showCookiesConsent = false;
    }

}
