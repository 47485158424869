import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { Banner } from 'src/app/routes/home/home.models';
import { OfferedProduct } from 'src/app/routes/shop/shop.models';
declare let $: any;
@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit {
    public bucketBaseUrl = Constants.amazonS3Bucket;
    public marginWithScroll: string = 'translate(0,  0px)';

    @Input('animationType') animationType: string;
    @Input('carouselSlides') carouselSlides: Banner[] | number[];
    @Input('showControls') showControls: boolean = false;
    @Input('type') type: string;

    // for type == 'PRODUCTS_SLIDER'
    @Input('offeredProducts') offeredProducts: OfferedProduct[];
    @Input('productsInSlide') productsInSlide: number;

    constructor(@Inject(DOCUMENT) private document: Document, private router: Router) { }

    ngOnInit(): void {
    }

    public openLink(link: string) {
        if(!link) return;
        if (link.includes('?')) { // for queryparams handling
            // example "shop/discount"
            let mainlink = link.substring(0, link.indexOf('?'));

            // example ["merch=09.01.01.", "search=aquafresh"]
            let queryParams: string[] = link.replace(mainlink, '').replace('?', '').split('&');

            let queryParamsObject = {};
            queryParams.forEach(qp => {
                let split = qp.split('=');
                queryParamsObject[split[0]] = split[1];
            });
            this.router.navigate([mainlink], {
                queryParams: queryParamsObject,
                queryParamsHandling: 'merge',
            })
        }
        else if (link.includes('eurobit') || link.includes('amkoshop.ba'))
            this.router.navigateByUrl(link); // if route is in this project
        else if (link.includes('www') || link.includes('http'))
            this.document.location.href = link; // if external link
        else
            this.router.navigate([link]); // if route is in this project
    };

    next() {
        $('.showControlstrue').carousel('next');
    }

    prev() {
        $('.showControlstrue').carousel('prev');
    }
}
