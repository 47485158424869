import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/base-component/base.service';
import { Constants } from 'src/app/app.constants';
import { HttpRequestType } from 'src/app/core/request.service';
import { Address } from './profile.models';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseService {
    private addresses: string = "web_shop_addresses";

    getAddresses(onSuccess: Function) {
        this.getAll(`${Constants.contextPath}/addresses/list?sortBy=lastUsed&sortOrder=asc`, (data: Address[]) => {
            onSuccess(data);
        })
    }

    getAddressesFromLocal(onSuccess: Function){
        let addresses: Address[] = JSON.parse(localStorage.getItem(this.addresses));
        if(addresses)
            onSuccess(addresses);
    }

    getAddressById(id: string, onSuccess: Function){
        if(id.includes('STORAGE_ID')){
            let addresses:Address[] = JSON.parse(localStorage.getItem(this.addresses));
            if(addresses) {
                let index = addresses.findIndex(el => el.id === id);
                if(index != -1)
                    onSuccess(addresses[index]);
            }

        } else
        this.getById(`${Constants.contextPath}/addresses`, id, (address: Address)=> {
            onSuccess(address);
        })
    }

    createAddress(formData: string) {
        this.create(`${Constants.contextPath}/addresses`, formData, null, null);
    }

    changePassword(body: any, onSuccess: Function, onFail: Function) {
        this.requestService.createRequest(HttpRequestType.Patch, `${Constants.contextPath}/users/changepassword`, body, null, (response: any) => {
            onSuccess(response);
        }, (error: any) => {
            onFail(error);
        });
    }

    createAddressInStorage(formData: Address, onSuccess: Function){
        let addresses: Address[] = JSON.parse(localStorage.getItem(this.addresses));
        if(addresses) {
            addresses.unshift(formData);
            localStorage.removeItem(this.addresses);
            localStorage.setItem(this.addresses, JSON.stringify(addresses));
        }
        else
            localStorage.setItem(this.addresses, JSON.stringify([formData]));
        onSuccess(formData);
    }

    updateAddressInStorage(formData: Address, onSuccess: Function){
        let addresses:Address[] = JSON.parse(localStorage.getItem(this.addresses));
        let index = addresses.findIndex(el => el.id === formData.id);
        if(index != -1) {
            addresses.unshift(formData);
            localStorage.removeItem(formData.id);
        }
        localStorage.setItem(formData.id, JSON.stringify(addresses));
        onSuccess(formData);
    }
}
