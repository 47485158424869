
import { Injectable, } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { Constants } from '../app.constants';

export class Token {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    roles: string[];
    companyId: string;
    userType: string = 'CLIENT_PUBLIC';
    jti: string;
    clientType: string;
    token_type: string;
    userId: string;
    issued: Date;
}

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    private readonly endpoint: string = `wsh/oauth/token`;
    private readonly tokenName: string = 'token';
    private _loginHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic d3NoY2xpZW50OnMzY3IzdA==' });
    private _publicLoginHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic d3NocHVibGljY2xpZW50OnMzY3IzdA==' });
    private _authHeaders: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    private _anonymousHeaders: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    private readonly credentialsExpiryDateStorageName: string = 'last_shown_credentials_expiry_date_message';
    private readonly versionStorageName: string = 'wsh_appVersion';

    public showCredentialsExpiryDateMessage: boolean = false;
    public credentialsExpiryDaysLeft: number;
    public isTokenSet$ = new BehaviorSubject(false);

    public showLoginPopupSubject = new Subject();

    public onLangChange: (tokenLanguage: string) => void = null;

    constructor(private http: HttpClient, private router: Router, private loadingService: LoadingService) {
        if (this.isTokenInStorage) {
            this.token = JSON.parse(localStorage.getItem(this.tokenName));
            this.isTokenSet$.next(true);
            this._authHeaders = new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('Accept-Language', 'bs-BA')
                .set('Authorization', `Bearer ${this.token.access_token}`);
        }

        setInterval(() => {
            if (!this.isTokenInStorage && this.isTokenSet)
                this.removeAuth(false);
        }, 3000);
    }


    public get anonymousHeaders(): HttpHeaders {
        return this._anonymousHeaders;
    }

    public get authHeaders(): HttpHeaders {
        return this._authHeaders;
    }

    public get isTokenSet(): boolean {
        return this.token != null;
    }

    public get isPublicUser(): boolean {
        return !this.token ? true :( this.token.userType == "CLIENT_PUBLIC" ? true : false);
    }

    public get isTokenInStorage(): boolean {
        return !!localStorage.getItem(this.tokenName);
    }

    public get userType(): string {
        return this.token != null ? <string>this.token.userType : '';
    }

    public get userId(): string {
        return this.token != null ? this.token.userId : '';
    }

    public get companyId(): string {
        return this.token != null ? this.token.companyId : '';
    }

    public get roles(): string[] {
        return this.token != null ? this.token.roles : [];
    }

    public get refreshTokenValue(): string {
        return this.token != null ? this.token.refresh_token : null;
    }

    public token: Token;

    private setToken(token: Token) {
        this.token = undefined;
        this._authHeaders = undefined;
        token.issued = new Date();

        let userInfo: Token = JSON.parse(atob(token.access_token.split('.')[1]));

        token.roles = userInfo.roles;
        localStorage.removeItem(this.tokenName);
        localStorage.setItem(this.tokenName, JSON.stringify(token));

        this._authHeaders = new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('Accept-Language', 'bs-BA')
                .set('Authorization', `Bearer ${token.access_token}`);

        this.token = token;
        this.isTokenSet$.next(true);
    }

    public login(username: string, password: string, loginForPublic: boolean = false): Observable<any> {
        this.loadingService.showLoader();        
        let parameters: string = `username=${username}&password=${password}&grant_type=${loginForPublic ? 'client_credentials' : 'password' }`;

        return this.http
            .post(this.endpoint, parameters, { headers: loginForPublic ? this._publicLoginHeaders : this._loginHeaders })
            .pipe(
                map((response: any) =>  this.setToken(response) ),
                finalize(()=> this.loadingService.hideLoader() )
            )
    }

    public refreshTokenIfNeeded(): Observable<void> {
        if (this.token != null) {
            let expirationTime: Date = new Date();
            let currentTime: Date = new Date();

            expirationTime.setTime(new Date(this.token.issued).getTime() + (this.token.expires_in - 30) * 1000);

            if (currentTime.getTime() > expirationTime.getTime())
                return this.refreshToken();
        }

        return of(null).pipe(map(() => null));
    }

    public hasRole(role: string): boolean {
        return this.token != null && this.token.roles && this.token.roles.includes(role);
    }

    public hasAnyRole(...roles: string[]): boolean {
        return roles == null || (this.token != null && this.token.roles && roles.some(role => this.hasRole(role)));
    }

    public setLanguageHeaders(language: string): void {
        this._loginHeaders = this._loginHeaders.delete('Accept-Language');
        this._loginHeaders = this._loginHeaders.append('Accept-Language', language);
        this._anonymousHeaders = this._anonymousHeaders.delete('Accept-Language');
        this._anonymousHeaders = this._anonymousHeaders.append('Accept-Language', language);
        this._authHeaders = this._authHeaders.delete('Accept-Language');
        this._authHeaders = this._authHeaders.append('Accept-Language', language);
        // if(this.token)
        //     this.token.language = language;
    }

    /**
     * Deletes auth token from local storage.
     */
    public removeAuth(redirect: boolean = true): void {
        let currentVersion = localStorage.getItem(this.versionStorageName);
        let acceptedCookies = localStorage.getItem(Constants.termsCookiesStorageKey);
        localStorage.clear();
        this.token = undefined;
        this._authHeaders = undefined;
        this.isTokenSet$.next(false);
        if (currentVersion != null)
            localStorage.setItem(this.versionStorageName, currentVersion);
        if (acceptedCookies != null)
            localStorage.setItem(Constants.termsCookiesStorageKey, acceptedCookies);
        if(redirect)
            this.router.navigate(['']);
    }

    public refreshToken(): Observable<void> {
        if (!this.refreshTokenValue)
            this.removeAuth(false);
        if(this.isPublicUser){
            this.loadingService.showLoader();        
            let parameters: string = `username=&password=&grant_type=client_credentials`;

            return this.http
                .post(this.endpoint, parameters, { headers: this._publicLoginHeaders })
                .pipe(
                    map((response: any) =>  this.setToken(response) ),
                    finalize(()=> this.loadingService.hideLoader() )
                )
        }
        const parameters: string = `refresh_token=${this.refreshTokenValue}&grant_type=refresh_token`;
        return this.http
            .post(this.endpoint, parameters, { headers: this._loginHeaders })
            .pipe(
                map((response: any) => this.setToken(response)), 
                catchError((err, caught) => {
                    if(err.status == 401) {
                        if(err.error && err.error.error == 'invalid_token') {
                            this.removeAuth(false);
                            this.toogleLogin(true);
                        }
                    }
                    return EMPTY;
                })
            );
    }

    public setShowCredentialsExpiryDateMessage() {
        this.showCredentialsExpiryDateMessage = false;
        localStorage.removeItem(this.credentialsExpiryDateStorageName);
        localStorage.setItem(this.credentialsExpiryDateStorageName, new Date().toString());
    }

    public toogleLogin(show: boolean){
        this.showLoginPopupSubject.next(show);
    }
}
