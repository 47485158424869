import { min } from 'rxjs/operators';
import { RouteParams } from 'src/app/RouteParams';
import { BaseModelActive, BaseModel, DayOfWeek, Image } from 'src/app/shared/base-component/base.models';
import { ProductClassification, ProductMerchandise } from 'src/app/shared/shared.models';

export class OfferedProduct extends BaseModelActive {
    pointOfSale?: PointOfSale | string;
    product?: Product;
    regularPrice?: number;
    // salePrice?: number; stara verzija za saledetails
    discountPercentage?: number;
    affectsMinimumOrderValue?: boolean;
    affectsRebateBase?: boolean = false;
    badges?: Badge[] = [];
    // amountFrom?: number; stara verzija za saledetails
    quantityOnOrders?: number;
    additionalQuantity?: number = 0;
    quantityAvailable?: number;
    erpOfferUpdate?: boolean = false; // if true, moderator can change quantityOnStock
    quantityOnStock?: number;
    maxQuantityPerOrder?: number;
    specialTreatment?: boolean;
    minimalValue?: number;
    saleDetails?: SaleDetails[];

    //helper
    saleDetailsTooltip?: string;
}

export enum TypeForLoad { // tipovi loadanja grida proizvoda, trenutno su samo akcije i classic
    DISCOUNT = "discount",
    ALL = ""
}

export class ShopParams {
    pointOfSale?: string;
    saleType?: 'DISCOUNT' | 'ALL';
    search?: string;
    sortType?: 'ASC' | 'DESC';
    sortParam?: string;
    validTo?: Date | string;
    page?: number;
    pageSize?: number;
    offeredProduct?: string;
    searchMap?: SearchMap;
    badges?: string[];
}
export class SearchMap {
    name?: string;
    pointOfSaleName?: string;
    productMerchandiseGroupCode?: string;
    code?: string;
}

export class SaleDetails {
    salePrice?: number;
    discountPercentage?: number;
    amountFrom?: number;
    validTo?: Date;
}

export class Product extends BaseModelActive {
    code?: string;
    name?: string;
    description?: string;
    productComposition?: string;
    productAssortment?: string | ProductClassification;
    productBrand?: string | ProductClassification;
    productCategory?: string | ProductClassification;
    productGroup?: string | ProductClassification;
    productMerchandiseGroup?: string | ProductMerchandise;
    template?: string ;
    productTemplateAttributes?: ProductTemplateAttribute[] = [];
    eanCodes?: ProductEANCode[] = [];
    images?: Image[];
    unitOfMeasurement?: UnitOfMeasurement;
}
export class ProductTemplateAttribute extends BaseModel {
    templateAttribute?: string;
    value?: string;
}
export class UnitOfMeasurement extends BaseModel {
    code?: string;
    name?: string;
    description?: string;
    increment?: number;
}

export class ProductEANCode extends BaseModel {
    eanCode?: string;
}

export class Badge extends BaseModel {
    mnemonic?: string;
    name?: string;
    image?: string;
}

export class PointOfSale extends BaseModelActive {
    code?: string;
    name?: string;
    type?: PointOfSaleType | string;
    priceType?: PointOfSaleType | string;
    geoX?: number;
    geoY?: number;
    workingHoursFrom?: string;
    workingHoursTo?: string;
    quantityControl?: boolean = false;
    workingDays: PointOfSaleWorkingDay[] | string = [];
    minOrderGoodsValue?: number;
}

export enum PointOfSaleType { 
    WHOLESALE, RETAIL, WEB
}

export class PointOfSaleWorkingDay extends BaseModel {
    day: DayOfWeek | string
}

export class Filter {
    selectedCategoryCode: string;
    selectedBrandCode: string;
    selectedAssortmentsCode: string;
    selectedMerchCode: string;
    sortBy: string;
    sortOrder: string;
    page: number; 
    search: string; 
    pageSize: number;
    selectedMerchCodes?: string[] = [];
    selectedBadgeIds?: string[] = [];
    selectedBadgeNames?: string[] = []; // only for display at filterSummary
    getWithFilter: boolean;

    constructor(queryParams, pageSize: number, selectedMerchCodes?: string[]) {
        this.selectedCategoryCode = queryParams ? (queryParams.category ? queryParams.category: null) : null;
        this.selectedBrandCode = queryParams ? (queryParams.brand ? queryParams.brand: null) : null;
        this.selectedAssortmentsCode = queryParams ? (queryParams.assortment ? queryParams.assortment: null) : null;
        this.selectedMerchCode = queryParams ? (queryParams[RouteParams.merch] ? queryParams[RouteParams.merch]: null) : null;
        this.sortBy = queryParams ? (queryParams.s ? queryParams.s: null) : null;
        this.sortOrder = queryParams ? (queryParams.d ? queryParams.d: null) : null;        
        this.page = queryParams ? (queryParams.p ? queryParams.p: 0) : 0; // page
        this.search = queryParams ? (queryParams[RouteParams.search] ? queryParams[RouteParams.search]: null) : null;
        this.pageSize = pageSize;
        this.selectedMerchCodes = selectedMerchCodes || [] ;
        this.selectedBadgeIds = queryParams ? ( queryParams[RouteParams.badgesId] ? queryParams[RouteParams.badgesId].split(',') : null) : null;
        this.selectedBadgeNames = queryParams ? ( queryParams[RouteParams.badgeNames] ? queryParams[RouteParams.badgeNames].split(',') : null) : null;
        if(this.search || this.selectedAssortmentsCode || this.selectedBrandCode || this.selectedCategoryCode || this.selectedMerchCode || this.selectedMerchCodes.length > 0 || this.selectedBadgeIds?.length > 0)
            this.getWithFilter = true;
        else
            this.getWithFilter = false;
    }
}

export class FilterForSummary {
    showSummary: boolean;
    categoryName?: string;
    brandName?: string;
    assortmentsName?: string;
    merchName?: string;
    search?: string;
    badges?: string[];

    constructor(showSummary: boolean = false, merch?: string, brand?: string, search?: string, badges?: string[], category?: string, assortments?: string){
        this.showSummary = showSummary;
        this.categoryName = category;
        this.brandName = brand;
        this.assortmentsName = assortments;
        this.merchName = merch;
        this.search = search;
        this.badges = badges;
    }
}

export class Pager{
    empty?: boolean;
    firstPage?: boolean;
    lastPage?: boolean;
    curPage?: number;
    numberOfElements?: number;
    size?: number;
    totalElements?: number;
    totalPages?: number[];
    displayPages?: number[];
    show?: boolean;

    constructor(totalPages: number[], displayPages: number[], curPage: number, lastPage: boolean, firstPage: boolean, totalElements: number, show: boolean) {
        this.totalPages = totalPages;
        this.displayPages = displayPages;
        this.curPage = curPage;
        this.lastPage = lastPage;
        this.firstPage = firstPage;
        this.totalElements = totalElements;
        this.show = show;
    }
};

export class CompanyConfiguration {
    key: string | CompanyConfigurationKey;
    value: string;
}


export enum CompanyConfigurationKey {
    // PASS
    PASS_VALIDATOR, PASS_VALIDITY_PERIOD, PASS_LENGTH_ADMIN, PASS_LENGTH_USER, PASS_CONFIRMATION_LINK, PASS_PENDING_PASSWORD_PERIOD,
    
    
    // SMTP
    SMTP_HOST, SMTP_PORT, SMTP_USERNAME, SMTP_PASSWORD, SMTP_SSL, SMTP_FROM_NAME, SMTP_FROM_EMAIL,

    // HTTP
    HTTP_PROTOCOL, HTTP_BASE_FRONT_URL, HTTP_BASE_FRONT_URL_B2B, HTTP_BASE_FRONT_URL_ADMIN,
    //
    PUBLIC_USER_ID,
    //
    STOCK_QUANTITY_UPDATE, PRODUCT_INSERT, OFFERED_PRODUCT_SPECIAL_TREATMENT,

    //SHOP DISPLAY DELIVERY TIME
    DELIVERY_SHOW_TODAY, DELIVERY_FIRST_TIMESLOT_HOURS_STEP, DELIVERY_SHOW_DAYS,

    //GENERATING DISTRIBUTION CALENDARS CONFIGURATION
    GENERATING_CALENDARS_HOURS_STEP,

    //OPA
    OPA_URL, REQUEST_QR_PAYMENT_DURATION,

    //UPC - PAYMENT
    UPC_MERCHANT_ID, UPC_TERMINAL_ID, UPC_CURRENCY_NUMBER, UPC_LOCALE_CODE, UPC_SUCCESS_URL, UPC_FAIL_URL, UPC_PREAUTHORIZATION,

    // IMAGES
    ICON_LOGO_URL
}
