import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../app/core/auth.service';
import 'rxjs/add/operator/map'
import { Constants } from './app.constants';
import { AlertService } from './core/alert.service';

@Injectable({
    providedIn: 'root'
})
export class InsideGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private alertService: AlertService) { }

    canActivate() {
        if (this.authService.isPublicUser) {
            this.router.navigate(['']);
            this.alertService.success('SHARED.LOG_IN_TO_CONTINUE');
            setTimeout(() => {
                this.authService.toogleLogin(true);
            }, 1500);
            return false;
        }
        return true;
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoginAsPublicGuard implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate() {
        if (this.authService.isTokenInStorage){
            return true;
        }
        else {
            return this.authService.login(null, null, true).map(_ => {
                return true
            })
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class LogoutClientCustomer implements CanActivate {
    constructor(private authService: AuthService) { }

    canActivate() {
        if(!this.authService.isPublicUser) {
            this.authService.removeAuth();
        }
        return true;
    }
}