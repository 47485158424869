import { Component, OnInit } from "@angular/core";
import { Constants } from "src/app/app.constants";
import { AuthService } from "src/app/core/auth.service";

@Component({
    selector: 'app-sitemap',
    template: ``
})
export class SitemapComponent implements OnInit {
    private bucketBaseUrl = Constants.amazonS3Bucket;

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
        window.location.href = `${this.bucketBaseUrl}/${this.authService.companyId}/sitemap/sitemap.xml`;
    }
}
