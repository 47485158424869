import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProfileService } from 'src/app/routes/profile/profile.service';
import { Constants } from 'src/app/app.constants';
import { Address } from 'src/app/routes/profile/profile.models';
import { Municipality } from '../shared.models';
import { AlertService } from 'src/app/core/alert.service';
import { UtilityService } from 'src/app/core/utility.service';
import { ValueLabel, DayOfWeek } from '../base-component/base.models';
import { AuthService } from 'src/app/core/auth.service';

@Component({
    selector: 'app-new-address-modal',
    templateUrl: './new-address-modal.component.html',
    styleUrls: ['./new-address-modal.component.scss']
})
export class NewAddressModalComponent implements OnInit {
    @Output() closeAddressModalEvent = new EventEmitter();
    @Output() refreshAddressesEvent = new EventEmitter();
    @Input('addressId') addressId: string;

    public formData: FormGroup;
    public municipalities: Municipality[];
    // public daysOfWeek: ValueLabel[] = [];
    public initialiseForm: boolean = false;

    public filteredMunicipalities: Municipality[];
    public numberPattern = "^[0-9]*$";
    public nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðĐđ ,.'-]+$/u;
    public addressRegex = /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðĐđ ,.'-]+$/u;
    public phoneRegex = /^([+]?)*[0-9]*$/g;

    constructor(private formBuilder: FormBuilder,
        private profileService: ProfileService,
        private alertService: AlertService,
        private utilityService: UtilityService,
        public authService: AuthService) { }

    ngOnInit(): void {
        if (!this.addressId)
            this.setForm(new Address());
        else {
            this.profileService.getAddressById(this.addressId, (address: Address) => {
                this.setForm(address);
            })
        }
    }

    setForm(address: Address) {
        let muni = this.addressId ? <Municipality>address.municipality : null;
        this.formData = this.formBuilder.group({
            'municipality': [this.addressId ? (muni.id) : '', [Validators.required]],
            'name': [address.name, [Validators.required, Validators.maxLength(60), Validators.pattern(this.nameRegex)]],
            'street': [address.street, [Validators.required, Validators.pattern(this.addressRegex)]],
            'phoneNumber': [address.phoneNumber, [Validators.required]], //Validators.pattern(this.phoneRegex)
            'description': [address.description],
            'zipCode': [address.zipCode, [Validators.required, Validators.maxLength(5), Validators.pattern(this.numberPattern)]],
            // 'daysOfWeek': [address.daysOfWeek],
            'shippingEmail': this.authService.isPublicUser ? [address.shippingEmail, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]] : undefined,
            'version': [address.version],
            'id': [address.id]
        })
        // this.daysOfWeek = this.utilityService.prepareLookupItems(this.utilityService.enumToSelectArray(DayOfWeek, true), 'value', 'label', 'ENUMS.DAYS_OF_WEEK.');
        this.profileService.getAll(`${Constants.contextPath}/municipalities/eligible-for-users/list`, ((response: any) => { this.municipalities = response; this.filteredMunicipalities = this.municipalities; }))
        this.initialiseForm = true;
    }

    submit() {
        if (this.authService.isPublicUser)
            this.submitAsGuestInStorage();
        else
            this.submitAsRegisteredUser();
    }

    search(value: string) {
        this.filteredMunicipalities = this.municipalities.filter(option => option.name.toLowerCase().startsWith(value.toLowerCase()));
    }

    resetFilter() {
        this.filteredMunicipalities = this.municipalities;
    }

    private submitAsRegisteredUser() {
        if (!this.addressId) {
            delete this.formData.value.id;
            this.profileService.create(`${Constants.contextPath}/addresses`, this.formData.value, () => {
                this.closeAddressModalEvent.emit();
                this.alertService.success('PROFILE.ADDRESS_ADDED_SUCCESSFULLY');
                this.refreshAddressesEvent.emit();
            })
        }
        else {
            let address = { ...this.formData.value };
            this.profileService.update(`${Constants.contextPath}/addresses`, address, () => {
                this.closeAddressModalEvent.emit();
                this.alertService.success('PROFILE.ADDRESS_ADDED_SUCCESSFULLY');
                this.refreshAddressesEvent.emit();
            })
        }
    }

    private submitAsGuestInStorage() {
        if (!this.addressId) {
            this.formData.value.id = 'STORAGE_ID__' + this.utilityService.generateUUID();
            this.profileService.createAddressInStorage(this.formData.value, () => {
                this.closeAddressModalEvent.emit();
                this.alertService.success('PROFILE.ADDRESS_ADDED_SUCCESSFULLY');
                this.refreshAddressesEvent.emit();
            })
        }
        else
            this.profileService.updateAddressInStorage(this.formData.value, () => {
                this.closeAddressModalEvent.emit();
                this.alertService.success('PROFILE.ADDRESS_ADDED_SUCCESSFULLY');
                this.refreshAddressesEvent.emit();
            })
    }

    selectAll() {
        // this.formData.controls['daysOfWeek'].setValue(this.daysOfWeek.map(d => d.value));
    }
}
