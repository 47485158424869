import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule, MatSelect } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {MatDialogModule} from '@angular/material/dialog';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { getPaginatorTranslation } from '../core';

const MatDesign = [
    MatTooltipModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatDialogModule
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MatDesign
    ],
    exports: [
        MatDesign
    ],
    providers: [
      {provide: MAT_DATE_LOCALE, useValue: 'bs-BA'},
      {provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}},
      {provide: MatPaginatorIntl, useValue: getPaginatorTranslation()}
    ],
})
export class MatDesignModule { }
