import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService, HttpRequestType } from 'src/app/core/request.service';
import { AlertService } from 'src/app/core/alert.service';
import { Constants } from 'src/app/app.constants';
import { DialogService } from 'src/app/core/dialog.service';
import { AuthService } from 'src/app/core/auth.service';

@Component({
    template: `<div (click)="redirect()">{{ 'SHARED.HOME' | translate}}</div>`
})
export class RegistrationConfirmationComponent implements OnInit {
    private token: string = window.location.pathname.split('/').pop();
    private url: string = `${Constants.contextPath}/registrations/confirm/${this.token}`;

    constructor(public router: Router, private requestService: RequestService, private dialogService: DialogService, private alertService: AlertService, private authService: AuthService) { }

    ngOnInit(): void {
        this.confirmRegistrationAndRedirect();
    }

    public confirmRegistrationAndRedirect() {
        this.requestService.createRequest(HttpRequestType.Patch, this.url, null, null, () => {
            this.alertService.success('REGISTRATIONS.CONFIRM_SUCCESS_LOGIN');
            setTimeout(() => {
                this.authService.toogleLogin(true);
            }, 1500);
            this.redirect();
        },
        (error: any) => {
            this.alertService.error(error, true);
            this.redirect();
        });
    }

    public redirect() {
        this.router.navigate(['']);
    }

}
