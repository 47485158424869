import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AlertService } from '../core/alert.service';
import { ActivatedRoute } from '@angular/router';
import { CompanyConfigurationKey, OfferedProduct, Product } from '../routes/shop/shop.models';
import { CartLocal, CartProduct, CustomerOrder, CustomerOrderProduct } from '../routes/cart/cart.models';
import { CartService } from '../routes/cart/cart.service';
import { Constants } from '../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ShopService } from '../routes/shop/shop.service';

@Injectable({
    providedIn: 'root'
})
export class BasketService {
    basket: string = Constants.amkoBasketName;

    cart: CartLocal = {
        orderId: null,
        items: [],
        time: new Date()
    };

    orderId: number;

    cartSubject = new BehaviorSubject(new CartLocal());
    public configurationSpecialTreatment: boolean = false;

    constructor(
        private alertService: AlertService,
        private cartService: CartService,
        private translateService: TranslateService,
        private $gaService: GoogleAnalyticsService,
        private shopService: ShopService
        
    ) {
        this.getBasketAndCheckTime();
        this.getConfigurationForSpecialTreatment();
    };

    addOrUpdateProduct(offeredProduct: OfferedProduct, orderId: number = null, quantity: number) {
        
        let cartProduct: CartProduct =  this.offeredProductToCartProduct(offeredProduct, quantity);
        let index = this.cart.items.findIndex(el => el.id === cartProduct.id);
        if (index == -1){ // if dont exist
            cartProduct.quantity = this.checkMaxQuantityAndSetQuantity(offeredProduct.maxQuantityPerOrder, quantity, offeredProduct.specialTreatment);            
            this.cart.items.unshift(cartProduct);
            window.innerWidth < 767 ? this.alertService.success('CART.UPDATED') : ''; // notification only for mobile ( xs screens )
            this.$gaService.event('AddToCartClick', 'AddToCart', `Product: ${offeredProduct.product.name}`, cartProduct.quantity);
        }   
        else { // update item
            this.cart.items[index].quantity = this.checkMaxQuantityAndSetQuantity(offeredProduct.maxQuantityPerOrder, this.cart.items[index].quantity + quantity, offeredProduct.specialTreatment);
            this.cart.items[index].price = cartProduct.price;
            window.innerWidth < 767 ? this.alertService.success('CART.UPDATED') : ''; // notification only for mobile ( xs screens )
            this.$gaService.event('AddToCartClick', 'AddToCart', `Product: ${offeredProduct.product.name}`, cartProduct.quantity);
        }
        this.updateLocal(orderId);
        return this.cart.items;
    }

    private checkMaxQuantityAndSetQuantity(max: number, current: number, specialTreatment: boolean): number {
        if(this.configurationSpecialTreatment && specialTreatment && max < current) {
            this.alertService.error(this.translateService.instant('SHOP.MAX_ORDER_QUANTITY', {value: max}));
            return  parseFloat(max.toFixed(2));
        } else {
            return parseFloat(current.toFixed(2));
        };
    }

    updateProductWithId(productId: string, quantity: number){        
        // Update iz carta, kada se mijenjaju kolicine iz korpice
        let index = this.cart.items.findIndex(el => el.id === productId);
        if(index != -1){
            this.cart.items[index].quantity = this.checkMaxQuantityAndSetQuantity(this.cart.items[index].maxOrderQuantity, quantity, this.cart.items[index].specialTreatment);
            if(this.cart.items[index].quantity < this.cart.items[index].increment)
                this.removeProduct(productId)
            this.updateLocal();
        }
    }

    offeredProductToCartProduct(offeredProduct: OfferedProduct, quantity: number): CartProduct {
        // cijena se odredjuje ako ima sale price i ako je naruceno vise od amountFrom - ovo je samo za prikaz na headeru 
        return {
            id: offeredProduct.id,
            product: offeredProduct.product,
            offeredProduct: offeredProduct.id,
            quantity: quantity,
            price: parseFloat(this.setPriceBasedOnQuantity(offeredProduct, quantity)),
            maxOrderQuantity: offeredProduct.maxQuantityPerOrder,
            affectsMinimumOrderValue: offeredProduct.affectsMinimumOrderValue,
            unitOfMeasurementCode: offeredProduct.product.unitOfMeasurement.code,
            increment: offeredProduct.product.unitOfMeasurement.increment,
            specialTreatment: offeredProduct.specialTreatment
        }
    }

    private setPriceBasedOnQuantity(offeredProduct: OfferedProduct, quantity: number){
        let quantityInBasket = this.findProductQuantity(offeredProduct.id) + quantity;
        if(!offeredProduct.saleDetails || offeredProduct.saleDetails.length < 0)
            return offeredProduct.regularPrice.toFixed(2);
        else
            for (let s of offeredProduct.saleDetails)
                if(s.amountFrom <= quantityInBasket) // array is sorted in shopService
                    return s.salePrice.toFixed(2);
        return offeredProduct.regularPrice.toFixed(2);
    }

    private findProductQuantity(productId: string): number {
        let index = this.cart.items.findIndex(el => el.id === productId);
        if(index != -1)
            return this.cart.items[index].quantity;
        else
            return 0
    }


    removeProduct(id: string, orderId: number = null) {
        let index = this.cart.items.findIndex(el => el.id == id);
        if (index != -1) { // if exist in array
            this.cart.items.splice(index, 1);
            this.updateLocal(orderId);
            this.alertService.success('CART.DELETED');
        }
    }

    setItemsToLocal(orderId: number, products: CartProduct[]) {
        this.clearItems();
        this.cart.items = products;
        this.updateLocal(orderId);
    }

    getItemsLength(): number {
        return this.cart.items.length;
    }

    updateLocal(orderId: number = null) {
        this.cart = {
            orderId: orderId,
            items: this.cart.items.sort((x,y) => (<Product>x.product).name.length - (<Product>y.product).name.length),
            time: new Date()
        }
        localStorage.setItem(this.basket, JSON.stringify(this.cart));
        this.cartSubject.next(this.cart);
        this.updateCustomerOrderProducts(this.cart.items);
    }
    
    private updateCustomerOrderProducts(products: CartProduct[]) {
        let customerOrderProducts: CustomerOrderProduct[] = [];
        
        for(let cartProduct of products)
            customerOrderProducts.push(this.cartProductToCustomerOrderProduct(cartProduct));
        
        this.cartService.updateCustomerOrderProducts(customerOrderProducts);

    }

    private cartProductToCustomerOrderProduct(cartProduct: CartProduct): CustomerOrderProduct {
        return {
            offeredProduct: {
                active: true,
                id: <string>cartProduct.offeredProduct,
                product: <Product>cartProduct.product
            },
            orderedQuantity: cartProduct.quantity,
            salePrice : cartProduct.price,
            id: cartProduct.id
        }
    }

    clearItems() {
        this.cart = {
            orderId: null,
            items: [],
            time: new Date()
        }
        localStorage.removeItem(this.basket);
        this.cartSubject.next(this.cart);
        return this.cart;
    }

    setOrderId(orderId){
        this.orderId = orderId;
    }

    getBasketAndCheckTime(){
        var sevenDaysAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
        let localBasket = JSON.parse(localStorage.getItem(this.basket));
                    
        if (localBasket == null || new Date(sevenDaysAgo) > new Date(localBasket.time))
            this.clearItems();
        else {
            this.cart = localBasket;
            this.updateLocal(this.cart.orderId);
        }
    }

    public checkQuantityBasedOnIncrement(quantity: number, increment: number): number {
        return parseFloat((parseInt((quantity / increment).toFixed(0))*increment).toFixed(2));
    }
    public getConfigurationForSpecialTreatment(): Promise<void> {
        return new Promise(() => {
            this.shopService.getByCompanyConfigurationKey(CompanyConfigurationKey[CompanyConfigurationKey.OFFERED_PRODUCT_SPECIAL_TREATMENT])
                .then(res => this.configurationSpecialTreatment = Boolean(res.value));
        });
    }

}