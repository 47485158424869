<div class="table-responsive">
    <!-- <div *ngIf="loadingService.unfinishedRequests" class="circle-loader table-loader"></div>     -->
    <table class="table table-striped simple-table {{type}}">
        <thead>
            <tr *ngIf="type == 'products'">
                <th>{{ 'SHARED.PRODUCT' | translate }}</th>
                <th class="text-right">{{ 'SHARED.QUANTITY'+ (showApprovedQuantityColumn ? '_ORDERED' : '') | translate }}</th>
                <th *ngIf="showApprovedQuantityColumn" class="text-right">{{ 'SHARED.QUANTITY_APPROVED' | translate }}</th>
                <th class="text-right">{{ 'SHARED.PRICE' | translate }}</th>
                <th *ngIf="showSalePriceColumn" class="text-right">{{ 'SHARED.SALE_PRICE' | translate }}</th>
                <th *ngIf="showRebateColumn" class="text-right">{{ 'SHARED.REBATE' | translate }}</th>
                <th *ngIf="showFinalPriceColumn" class="text-right">{{ 'SHARED.FINAL_PRICE' | translate }}</th>
                <th class="text-right pr-1">{{ 'SHARED.FINAL_AMOUNT' | translate }}</th>
            </tr>
            <tr *ngIf="type == 'costs'">
                <th class="pl-1">{{ 'SHARED.TYPE' | translate }}</th>
                <th>{{ 'SHARED.NAME' | translate }}</th>
                <th class="text-right pr-1">{{ 'SHARED.PRICE' | translate }}</th>
            </tr>
        </thead>
        <tbody *ngIf="type == 'products'">
            <tr *ngFor="let customerOrderProduct of dataSource">
                <td class="three-text-line">
                    <a class="text-black-hoverable text-black" target="_blank" [routerLink]="['/'+RouteParams.shop+'/'+RouteParams.product, customerOrderProduct.offeredProduct.id ]">
                        {{ customerOrderProduct.offeredProduct.product?.name }}
                    </a>
                </td>
                <td class="text-right">{{ customerOrderProduct.orderedQuantity }}</td>
                <td *ngIf="showApprovedQuantityColumn" class="text-right">{{ customerOrderProduct.approvedQuantity }}</td>
                <td class="text-right">{{ customerOrderProduct.regularPrice | number:'1.2-2' }}</td>
                <td *ngIf="showSalePriceColumn" class="text-right">{{ customerOrderProduct.salePrice | number:'1.2-2' }}</td>
                <td *ngIf="showRebateColumn" class="text-right">{{ customerOrderProduct.rebatePercentage }} %</td>
                <td *ngIf="showFinalPriceColumn" class="text-right pr-1">{{ customerOrderProduct.invoicePrice | number:'1.2-2' }}</td>
                <td class="text-right pr-1">{{ customerOrderProduct.invoicePrice * customerOrderProduct.approvedQuantity | number:'1.2-2' }}</td>
            </tr>
            <tr>
                <td>
                    <span *ngIf="dataSource?.length > 0">
                        {{ 'SHARED.TOTAL' | translate }}: {{ dataSource?.length }}
                    </span>
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td *ngIf="showSalePriceColumn">&nbsp;</td>
                <td *ngIf="showRebateColumn">&nbsp;</td>
                <td *ngIf="showFinalPriceColumn">&nbsp;</td>
                <td *ngIf="showApprovedQuantityColumn">&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        </tbody>
        <tbody *ngIf="type == 'costs'">
            <tr *ngFor="let cost of dataSource">
                <td>{{ 'COST_POLICY_TYPES.'+ cost.costPolicy.type | translate }}</td>
                <td>
                    {{ cost.costPolicy.description }}
                    <p class="fs-10 text-gray m-0 d-none d-sm-block">
                        <!-- TODO valuta -->
                        {{ 'ORDER.COST_EXPLAIN' | translate: {description: cost.costPolicy?.description, valueFrom: cost.costPolicy?.orderValueFrom, valueTo: cost.costPolicy?.orderValueTo, currency: 'KM'} }}
                    </p>
                </td>
                <td class="text-right">{{ cost.value | number:'1.2-2'}}</td>
            </tr>
            <tr>
                <td>{{ 'SHARED.TOTAL' | translate }}: {{ dataSource?.length }}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
        </tbody>
    </table>
</div>