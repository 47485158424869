import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})

export class AlertService {
    constructor(private translateService: TranslateService, private toastr: ToastrService) { }

    public success(message: string, time: number = null): void {
        if (time == null) {
            if(message.length < 100)
                time = 3000;
            else
                time = message.length * 40;
        }
        this.toastr.success(this.translateService.instant(message), '', {
            timeOut: time
        });
    }
    
    // public error(message: any, json: boolean = false, time: number = null): void {
    //     console.log(message, json);
         
    //     let detail = (json ? JSON.parse(message._body).error_description : message);
    //     if (time == null) {
    //         if(detail.length < 60)
    //             time = 3000;
    //         else
    //             time = detail.length * 60;
    //     }
    //     this.toastr.error(this.translateService.instant(message), '', {
    //         timeOut: time
    //     });
    // }
    
    public error(message: any, json: boolean = false, time: number = null): void {
        let errorData = message;
        let detail = '';
        if(message && message.error && message.error.error_description)
            detail = message.error.error_description
        else
            detail = (json ? JSON.parse(message._body).error_description : message).toString();


        if (time == null) {
            time = 5000;
            // if(detail.length < 60)
            // else
            //     time = detail.length * 60;
        }

        this.toastr.error(this.translateService.instant(detail), '', {
            timeOut: time
        });
    }
}
