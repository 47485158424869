import { Injectable } from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA //TODO mozel se ovo izbristai
} from "@angular/material/dialog";
import { Router } from '@angular/router';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    matDialogRef: MatDialogRef<DialogComponent>;

    constructor(private matDialog: MatDialog, private router: Router) { }

    OpenModal(message: string, callback = null, onFail = null, modalType: string = 'CLASSIC') {
        this.matDialogRef = this.matDialog.open(DialogComponent, {
            data: {
                message: message,
                callback: callback,
                showConfirmBtn: callback ? true : false,
                modalType: modalType
            }
        });

        this.matDialogRef.afterClosed().subscribe(res => {
            if(callback && res)
                callback(res);
            else if(onFail && !res)
                onFail(res);
        });
    }

    openModalWithInput(message: string, inputLabel: string, callback = null, onFail = null){
        this.matDialogRef = this.matDialog.open(DialogComponent, {
            data: {
                message: message,
                callback: callback,
                inputLabel: inputLabel,
                showConfirmBtn: callback ? true : false,
            }
        });

        this.matDialogRef.afterClosed().subscribe(res => {
            if(callback && res)
                callback(res);
            else if(onFail && !res)
                onFail(res);
        });
    }
}
